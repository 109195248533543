export const localstore = {
    getToken: () => localStorage.getItem('kctoken'),
    getUserId: () => localStorage.getItem('userId'),
    getUsername: () => localStorage.getItem('userName'),
    getIbmId: () => localStorage.getItem('IbmId'),
    getFixedIbmId: () => localStorage.getItem('fixedIbmId'),
    getRefreshToken: () => localStorage.getItem('rctoken'),
    getPatientIbmId: () => localStorage.getItem('pId'),
}


