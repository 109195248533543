import React, { useEffect, useState } from 'react'
import { Box, Paper, Typography, Grid, Divider, Button, TextField, Select, Chip, IconButton, CircularProgress } from '@mui/material'
import { Close, EventAvailable, ExpandLess, ExpandMore, LocationOnOutlined, PersonAddAltOutlined } from '@mui/icons-material'
import timeIcon from '../../assets/images/field-time-outlined.svg';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import moment from 'moment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CustomTextField } from '../atoms/TextField';
import ContainedButton, { OutlinedButton } from '../atoms/commonbutton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { RiVerifiedBadgeFill } from "react-icons/ri";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import { QrCode2 } from '@mui/icons-material';
import { Commoncalender } from '../atoms/commoncalender';
import dayjs from 'dayjs';
import SearchBar from '../atoms/searchbar/SearchBar';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import URL from '../../services/api_url';
import editIcon from '../../assets/edit_24dp_FILL0_wght400_GRAD0_opsz24.svg';
import { v4 as uuidv4 } from 'uuid';
import SelectOptions, { MenuOptions } from '../atoms/selectoptions';
import { localstore } from '../localstore/localstore';
import { getPatientdatafromibm } from '../../redux/actions/actions';
import Confirmbooking from './confirmbooking';
import BranchUrl from '../../services/api_branch_url';

// import CustomAutoComplete from '../UserSignup/CustomAutoComplete';

const filter = createFilterOptions();

// const patientInfo = [
//   {name: [{ "text": "Akhil Kolli","family": "Kolli", "given": [ "Akhil"]},], gender: 'male', birthDate: "1992-10-12"},
//   {name: [{ "text": "test user","family": "vollmond", "given": [ "Test"]},], gender: 'female', birthDate: "1998-8-6"},
// ]

// const patientInfo = [
//   { title: 'Manikanta', gender: 'Male', birthDate: "11 Years" },
//   { title: 'Supriya', gender: 'Female', birthDate: "09 Years" },
//   { title: 'Tejasri', gender: 'male', birthDate: "28 Years" },
// ]

const SheduleBooking = (props) => {
  const { practitioner, userData } = props
  // console.log(practitioner, "practitiooooooo")
  const [value, setValue] = React.useState();
  const [symptom, setSymptom] = React.useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verifyOtp, setVerifyotp] = useState("");
  const [otpSuccess, setOtpSuccess] = useState(false)
  const [otpOpen, setOtpOpen] = useState(false)
  const [nextPatientPage, setNextPatientPage] = React.useState(false);
  const urls = BranchUrl()
  const [searchTerm, setSearchTerm] = useState('');
  const [searchedItems, setSearchedItems] = useState([]);
  const [sheduleSlot, setSheduleSlot] = useState([]);
  const selectedbranch = useSelector((state) => state.SelectedBranch.selectedbranch);

  const [selectedSlot, setSelectedSlot] = useState(null);
  const [alertmessage, setAlertmessage] = useState('')
  const [alertstatus, setAlertstatus] = useState('')
  const [patientDetails, setPatientDetails] = useState([])
  const [loading, setLoading] = useState(false)

  const [show, setShow] = useState(1)
  const ibmId = localstore.getIbmId()
  const usertoken = localstore.getToken()
  const randomId = sessionStorage.getItem('randomId');
  const { id } = useParams()

  const [patientInfo, setPatientInfo] = useState([
    { title: 'Manikanta', gender: 'Male', birthDate: "11 Years" },
  ]);

  const [dialogValue, setDialogValue] = React.useState({
    title: '',
    year: '',
  });

  const handleClose = () => {
    setDialogValue({
      title: '',
      year: '',
    });
    // setPatientOpen(false);
  };


  const handleSearch = () => {
    if (searchTerm.trim() !== '') {
      setSearchedItems((prevItems) => [...prevItems, searchTerm]);
      setSearchTerm('');
    }
  };

  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src =
  //     "https://www.google.com/recaptcha/api.js?render=6LfZoZEnAAAAAI-P-K64YET4zUQtJiZwVGCpY0m-";
  //   document.body.appendChild(script);
  // }, []);

  const handleRemoveItem = (index) => {
    setSearchedItems((prevItems) => prevItems.filter((_, i) => i !== index));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setValue({
      title: dialogValue.title,
      year: parseInt(dialogValue.year, 10),
    });
    handleClose();
  };

  const handleChangePhoneNumber = (e) => {
    setPhoneNumber(e.target.value?.replace(/\D/g, "")?.slice(0, 10))
  }

  const handleChangeOtp = (e) => {
    const inputValue = e.target.value.replace(/\D/g, '');
    setVerifyotp(inputValue.slice(0, 5));
  }

  const handleAppointment = (date) => {
    // console.log(date?.$d, moment(date?.$d).format());
    const selectdate = moment(date?.$d).format();
    setValue(date?.$d);

    // Ensure practitioner and practitioner.entry are defined and have at least one entry
    if (practitioner && practitioner.entry && practitioner.entry.length > 0) {
      const practitionerId = practitioner.entry[0]?.resource?.id;

      axios.get(`${urls.appointment}schedule/Slots/${id}/${practitionerId}/${selectdate}`, {
        headers: {
          'X-FHIR-TENANT-ID': 'parimitha',
          'X-FHIR-DSID': `fhirparimitha`,
          'realm': 'parimitha'
        }
      }).then((res) => {
        setSheduleSlot(res?.data);
        console.log(res);
      }).catch((err) => {
        console.error(err); // Handle the error appropriately
      });
    } else {
      console.error('Practitioner or practitioner entry is not defined or is empty');
    }
  };


  const handleSelectSlot = (slot) => {
    setSelectedSlot(slot);
  }

  const handleOnNextPage = () => {
    if (ibmId) {
      setShow(3)
    } else {
      setShow(2)
    }
  }
  const handleSendOtp = () => {

    const data = {
      "id": randomId,
      "phoneNumber": `+91${phoneNumber}`,
      "messagetype": "OTP",
      "apptype": "Paramitha Appointment Booking",
      "email": ""
    }
    // timeinterval();
    setLoading(true)
    window.grecaptcha.ready(_ => {
      window.grecaptcha
        .execute("6LfZoZEnAAAAAI-P-K64YET4zUQtJiZwVGCpY0m-", { action: "submit" })
        .then(token => {
          // console.log(token, "pythontokennnn")
          axios.post(`${URL.sendOtpAppointment}`, data, {
            headers: {
              'Usertoken': `Bearer ${token}`
            }
          }).then(res => {
            if (res.status === 200 || res.status === 201) {
              setAlertmessage('otp send successfully')
              setAlertstatus('success')
              setOtpOpen(!otpOpen)
            }
          }).catch((error) => {
            // console.log(error?.response?.data?.msg)
            // const errorMessage = error.response ? error.response.data.msg : "An error occurred while sending OTP.";

            // setAlertmessage(errorMessage)
            // setAlertstatus('error')
          }).finally(() => {
            setLoading(false)
          })
        })
    })
  }

  const handleClickOtp = async () => {
    const data = {
      "phoneNumber": `+91${phoneNumber}`,
      "otp": verifyOtp,
      "email": "",
      "realm": 'parimitha'

    }
    setLoading(true)
    await axios.post(`${URL.verifyOtpAppointment}`, data, {
      headers: {
        "Usertoken": `True`,
      }
    }).then(res => {
      if (res.status === 200 || res.status === 201) {
        setAlertmessage('otp verified successfully')
        setAlertstatus('success')
        setPatientDetails(res.data.entry)
        setShow(3)
        // setOtpSuccess((prev) => !prev)
      }

    }).catch((err) => {
      setAlertmessage(err.message)
      setAlertstatus('error')
    }).finally(() => {
      setLoading(false)
    })

  }



  // console.log(reasons, "selectedslot")
  // console.log(patientdata?.telecom?.[0]?.value, "patientvalue")
  // console.log(patientDetails, "userdata")
  // console.log(contains, "contains")



  return (
    <Box className=" py-3" style={{ height: '100%' }} >
      <Paper className='px-4 py-2'>

        <div className='flex items-center'>
          <ArrowBackIcon onClick={() => props?.backpage()} />
          <div className='text-center flex font-semibold text-xl w-full justify-center '>Schedule Consultation Appointment </div>
        </div>
        <Divider />
        <Grid container pt={2} spacing={2} justifyContent={"space-between"} p={1}>
          <Grid item >
            <div className='text-sm'>Pediatrics OP Consultation</div>
            <Typography sx={{ color: "#000000", fontSize: "14px", }} className="text-sm font-medium flex pt-1 items-center" >
              <LocationOnOutlined sx={{ fontSize: "18px", color: "#0F21B1", mr: "2px" }} />
              Available at <span className="text-sm pl-2" style={{ color: "#0F4BBE" }} > {selectedbranch?.name} </span>
            </Typography>
          </Grid>
          {show === 2 || show === 3 ?
            <>
              <Grid item >
                <div className='font-medium text-medium'>Appointment Date</div>
                <Typography className='flex items-center fntsz-15'> <EventAvailable sx={{ fontSize: '20px', color: "#4B4C52", mr: "5px" }} />
                  {moment(value).format('Do MMM YYYY')}
                </Typography>
              </Grid>
              <Grid item >

                <div className='font-medium text-medium'>Appointment Time</div>
                <div className='font-medium text-medium flex fntsz-14 gap-2 items-center'><img src={timeIcon} width={"18px"} height={18} />
                  {selectedSlot && moment(selectedSlot.start).format('hh:mm A')}  - {selectedSlot && moment(selectedSlot.end).format('hh:mm A')}
                  {/* <div onClick={() => setShow(1)} className='cursor-pointer'> */}
                  <img src={editIcon} alt="edit" />
                  {/* </div> */}
                </div>
              </Grid>
            </> : null
          }
          {/* {console.log(selectedSlot, 431)} */}
        </Grid>
        <Divider />
        {
          show === 1 ?
            (
              < Grid className='py-3'>
                <Grid container justifyContent={"space-between"} spacing={2} >
                  <Grid item xs={6}>
                    <div className='font-medium text-medium'>Select Date</div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateCalendar root={{ background: 'red' }}
                        minDate={moment()}
                        slotProps={{
                          root: { background: 'red' },

                          // 1. Change the layout of the month selector.
                          calendarHeader: {
                            sx: {
                              position: "relative",
                              '& .MuiPickersArrowSwitcher-root': {
                                width: 0
                              },
                              "& .MuiPickersCalendarHeader-labelContainer": {
                                margin: "auto"
                              },
                              "& .MuiIconButton-edgeEnd": {
                                position: "absolute",
                                left: 0, top: 0, bottom: 0
                              },
                              "& .MuiIconButton-edgeStart": {
                                position: "absolute",
                                right: 0, top: 0, bottom: 0
                              }
                            }
                          },
                          // 2. Change the arrow icons styles.
                          leftArrowIcon: {
                            sx: { color: "#606060", fontSize: "2rem" }
                          },
                          rightArrowIcon: {
                            sx: { color: "#606060", fontSize: "2rem" }
                          },
                        }}
                        sx={{ '.muidatecalendar-root': { margin: 0, background: 'red' } }}
                        value={value}
                        //  onChange={(newValue) => setValue(newValue.$d)} />
                        onChange={handleAppointment} />
                    </LocalizationProvider>
                  </Grid>
                  {/* {console.log(value, 41)} */}
                  {/* {value && */}
                  <Grid item xs={6}>
                    <div className='font-medium text-medium'>Select Slot</div>
                    <div style={{ color: '#606060', fontSize: '15px' }}>Available Slots for {moment(value).format('Do MMMM YYYY,dddd')} </div>
                    <div>
                      {/* {console.log(sheduleSlot)} */}
                      <div className="flex flex-wrap">
                        {sheduleSlot.length === 0 ? (
                          <div className='fntsz-14'>No slots available</div>
                        ) : (

                          sheduleSlot?.map((slot, index) => (
                            <div key={index} className="w-1/2 py-1">
                              <span
                                onClick={() => handleSelectSlot(slot)}
                                style={{ backgroundColor: selectedSlot === slot ? "blue" : "#EAF5FD" }}
                                className={`text-sm rounded-xl px-2 py-1 cursor-pointer fntsz-14 ${selectedSlot === slot ? 'bg-blue-500 text-white' : 'bg-teal-100 text-black '}`}
                              >
                                {moment(slot?.start).format('hh:mm A')} - {moment(slot?.end).format('hh:mm A')}
                              </span>
                            </div>
                          )))}
                      </div>
                    </div>
                  </Grid>
                  {/* } */}
                </Grid>
                {/* {value && */}
                <Grid container justifyContent={"flex-end"}>
                  <Button variant="contained" className='text-lg' sx={{ color: '#fff', background: '#205072', textTransform: 'capitalize ' }} disabled={!selectedSlot} onClick={handleOnNextPage}>Next</Button>
                </Grid>
                {/* } */}
              </Grid>
            )
            : show === 2 ?
              (
                <Grid >
                  {/* {!otpSuccess && */}
                  <Grid container pb={6} >
                    <Grid item xs={12} md={5}>
                      <Box sx={{ mt: 4 }}>
                        <Typography sx={{ fontSize: 16, fontWeight: 500 }} >Register with Phone Number</Typography>
                      </Box>
                      <Box sx={{ mt: 1 }}>
                        <CustomTextField
                          sx={{ ".MuiInputBase-root": { paddingRight: "0px !important" } }}
                          placeholder="Enter Your Phone Number"
                          type={'text'}
                          borderColor="#707070"
                          borderRadius="7px"
                          width="100%"
                          padding="0px"
                          // borderColor="#707070"

                          icon={phoneNumber.length === 10 ?
                            <ArrowForwardIcon sx={{ backgroundColor: "#1B5775", fontSize: '40px', color: "white", borderRadius: "0px 7px 7px 0px", }}
                              onClick={handleSendOtp} /> : null}
                          value={phoneNumber}
                          onChange={handleChangePhoneNumber}
                        />
                      </Box>
                      {otpOpen &&
                        <>
                          <Box sx={{ mt: 3 }}>
                            <Typography sx={{ fontSize: { xs: 14, sm: 16, md: 16 }, fontWeight: 500 }}>Verify OTP</Typography>
                          </Box>
                          <Box sx={{ mt: 1 }}>
                            <CustomTextField placeholder="Enter OTP Here" type={'text'} borderColor="#59D8FA" borderRadius="7px" value={verifyOtp} onChange={handleChangeOtp} />
                            <div className='flex align-center justify-between mt-0.5'>
                              <Typography className='fntsz-13'>60 sec</Typography>

                              <Typography className='fntsz-13 ' textTransform='capitalize' variant='button'> Resend OTP
                              </Typography>
                            </div>
                          </Box>
                          <Box sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
                            <div><Button color="error" sx={{ textTransform: "capitalize" }} >Cancel</Button></div>
                            <div>
                              <OutlinedButton
                                width="140px"
                                height="32px"
                                borderColor="#1B5775"
                                color="#1B5775"
                                borderRadius="7px"
                                text="Verify OTP"
                                onClick={handleClickOtp} // onClick event handler
                                disabled={!verifyOtp}
                                startIcon={null}
                                endIcon={loading ? <CircularProgress size={20} color="inherit" /> : null} />
                            </div>
                          </Box>
                        </>
                      }
                    </Grid>
                  </Grid>
                  {/* } */}
                </Grid>
              )
              : show === 3 ?
                (
                  <Confirmbooking phoneNumber={phoneNumber} selectedSlot={selectedSlot} practitioner={practitioner} userData={userData} id={id} patientDetails={patientDetails} />
                ) : null
        }
      </Paper >
    </Box >
  )
}

export default SheduleBooking