import React, {useEffect, useState} from 'react';
import {Box, Grid, TextField, Checkbox, CardMedia, Paper, Autocomplete} from "@mui/material";
import {ServicesTab} from '../../shared/constants';
import SearchIcon from '@mui/icons-material/Search';
import {useNavigate} from 'react-router-dom'
import {useSelector} from 'react-redux';
import axios from 'axios';
import URL from '../../services/api_url';

const Condition = () => {
  const navigate = useNavigate()
  const [conditionInfo, setConditionInfo] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [specailityInfo, setSpecailityInfo] = useState([])
  const [checkedItems, setCheckedItems] = useState({});
  const selectBranchInfo = useSelector((state) => state.SelectedBranch.selectedbranch);

  useEffect(() => {
    axios.get(URL.getallDiseases).then((res) => {
      console.log(res)
      let info = res?.data.sort((a, b) => a.speciality.localeCompare(b.speciality));
      const conditionData = {};
      info.forEach(item => {
        const firstLetter = item.speciality[0].toUpperCase();
        if (!conditionData[firstLetter]) {
          conditionData[firstLetter] = [item];
        } else {
          conditionData[firstLetter].push(item);
        }
      });
      setConditionInfo(conditionData)
    }).catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    axios.get(URL.getSpeciality).then((res) => {
      setSpecailityInfo(res?.data || [])
    }).catch((err) => console.log(err))
  }, [])


  // Function to handle checkbox change
  const handleCheckboxChange = async (event, item) => {
    const {id, speciality} = item;
    const isChecked = event.target.checked;

    // Update the checked state for the current checkbox
    setCheckedItems(prevState => ({
      ...prevState,
      [id]: isChecked
    }));
    let checkedData = {...checkedItems,[id]: isChecked}

    const checkedSpecialities = specailityInfo
      .filter(spec => checkedData[spec.id])
      .map(spec => spec.speciality);

    console.log(checkedSpecialities, checkedSpecialities?.join(','))
    const checkValues = checkedSpecialities?.join(',')

    axios.get(`${URL.getDiseasesBySpecialityname}${checkValues ? `/${checkValues}` : ''}`).then((res) => {
      let info = res?.data.sort((a, b) => a.speciality.localeCompare(b.speciality));
      const conditionData = {};
      info.forEach(item => {
        const firstLetter = item.speciality[0].toUpperCase();
        if (!conditionData[firstLetter]) {
          conditionData[firstLetter] = [item];
        } else {
          conditionData[firstLetter].push(item);
        }
      });
      setConditionInfo(conditionData)
    }).catch((err) => console.log(err))
  };

  const handleSearch = ((value) => {
    console.log(value)
    setSearchValue(value)
  })

  return (
    <Grid className='mx-2 md:mx-10 py-5 '>
      <Grid className='text-4xl font-semibold' style={{color:'#E54290'}}>Conditions & Treatments </Grid>
      <Grid container className='flex' spacing={3} >
        <Grid item xs={9} >
          <Box className="flex gap-5">
            <Grid item xs={6} md={6}>
            
              <TextField fullWidth value={searchValue} label="Search Conditions or Symptoms" 
              variant="standard"  onChange={(event)=>handleSearch(event?.target?.value)} />
            </Grid>
            
          </Box>
          <Grid>
            {/* {console.log(conditionInfo, 102, conditionInfo?.length)} */}
            {Object.entries(conditionInfo).map(([letter, items]) => (
              <div key={letter}>
                <h2 className='mt-1'>{letter}</h2>
                <ul className='grid gap-3 ml-3'>
                  {items.map(item => (
                    <li key={item.id} className='border border-slate-300  px-4 pt-2 pb-3 cursor-pointer rounded-lg' onClick={() => navigate(selectBranchInfo?.name ? `/${selectBranchInfo?.name}/condition/${item?.activeId}/${item?.disease}` : `/condition/${item?.activeId}/${item?.disease}`)}>
                      <div className='text-lg' style={{color: '#1D5775'}}>{item?.disease}</div>
                      {/* <div className='text-xs pt-1 font-normal' style={{color: '#707070'}}>{item.desc}</div> */}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </Grid>

        </Grid>
        <Grid item xs={3}>
          <Grid className='font-semibold  text-xl'>Select Speciality</Grid>
          <Paper elevation={2} className='items-center cursor-pointer px-2 py-2 mt-2' >
            {specailityInfo.map((item) => {
              return (
                <Grid key={item?.id} className="cursor-pointer flex items-center" >
                  <Checkbox
                    onChange={(event) => handleCheckboxChange(event, item)}
                    checked={checkedItems[item.id] || false} // Check if checkbox is checked
                  />
                  {/* <Typography variant="h5"  className={styles.popoverTitle} >{location.name} </Typography> */}
                  <div className='text-small font-medium'>{item?.speciality} </div>
                </Grid>
              );
            })}
          </Paper>

        </Grid>

      </Grid>

    </Grid>
  )
}

export default Condition