import { API } from "../../services/ApiService";

export const getToken = (token) => {
    return {
        type: "SET_TOKEN",
        payload: token,
    };
};


export const getBranchLists = (mainOrgId) => {
    return (dispatch) => {
        API.get(`branch_list_/Organization?partof=${mainOrgId} `).then(
            res => {
                // console.log(res, "12345678909")
                dispatch({
                    type: 'BRANCHLISTS',
                    payload: res.data,
                });
            })
            .catch(err => {
                dispatch({
                    type: 'BRANCHLISTS',
                    payload: [],
                });
            });
    };
};