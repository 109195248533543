import React, { useState } from "react";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import LikedIcon from "../../../assets/images/liked-icon.svg";
import ShareImg from "../../../assets/images/share-icon.svg";
import CommentIcon from "../../../assets/images/comment-icon.svg";
import likeIcon from "../../../assets/images/like-icon.svg";

function HealthcomActions({ item }) {
    const [like, setLike] = useState(false);
    console.log(item, "itemsssss")

    return (
        <div>
            <Box
                sx={{
                    display: "flex",
                    pl: 1,
                    pr: 1,
                    // mt: 2,
                    justifyContent: "space-between",
                    height: 5,
                }}
            >
                {item.likeCount > 0 ? (
                    <Typography
                        sx={{
                            fontSize: { xs: "7px", sm: "11px", md: "11px" },
                            // margin: "5px",
                        }}
                    >
                        {item.likeCount} {item.likeCount === 1 ? "person" : "people"}{" "}
                        {"liked"}
                    </Typography>
                ) : null}
                {item.shareCount > 0 ? (
                    <Typography
                        sx={{
                            fontSize: { xs: "7px", sm: "11px", md: "11px" },
                        }}
                    >
                        {item.shareCount} {item.shareCount === 1 ? "person" : "people"}{" "}
                        Answered
                    </Typography>
                ) : null}
            </Box>

            <Divider sx={{ mt: "13px" }} />
            <Box
                sx={{ display: "flex", justifyContent: "space-between", p: "2px 10px" }}
            >
                <Box >
                    {item.liked || like === true ? (
                        <IconButton aria-label="unlike">
                            <img src={LikedIcon} alt="Liked" />
                        </IconButton>
                    ) : (
                        <IconButton aria-label="like">
                            <img
                                src={likeIcon}
                                width={18}
                                height={18}
                                alt="like"
                            />
                        </IconButton>
                    )}
                    <Typography variant="caption" sx={{ cursor: "pointer" }}>
                        {" "}
                        Like
                    </Typography>
                </Box>
                <Box >
                    <IconButton aria-label="comment">
                        <img
                            src={CommentIcon}
                            width={18}
                            height={18}
                            alt="comment"
                        />
                    </IconButton>
                    <Typography variant="caption" sx={{ cursor: "pointer" }}>
                        Ask Doctor
                    </Typography>
                </Box>

            </Box>
        </div >
    );
}

export default HealthcomActions;
