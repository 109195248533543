import React, { useEffect } from "react";
import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import teleIcon from "../../assets/images/referral.svg";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { ShareOutlined } from "@mui/icons-material";
import followIcon from "../../assets/images/Follow.svg";
// import MedicalService from './medicalservice';
import { API_FHIR } from "../../services/ApiService";
import "./condition.css";
import axios from "axios";
import LoadingComponent from "../../shared/LoadingComponent";
import URL from "../../services/api_url";
import { OutlinedButton } from "../atoms/commonbutton";
import { Helmet } from "react-helmet-async";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            py: 3,
            overflowY: "scroll",
            height: "calc(100% - 480px)",
            paddingLeft: "1px",
            paddingRight: "1px",
          }}
          className="cndtab"
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const ConditionDetails = () => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [userData, setUserData] = React.useState({});
  const [diseseInfo, setDiseseInfo] = React.useState([]);
  const [diseseData, setDiseseData] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const { id, name } = useParams();

  useEffect(() => {
    let subtitle;
    switch (value) {
      case 0:
        subtitle = "overview";
        break;
      case 1:
        subtitle = "symptomscauses";
        break;
      case 2:
        subtitle = "diagnosis";
        break;
      case 3:
        subtitle = "treatment";
        break;
      // more cases as needed
      default:
        subtitle = "overview";
      // code block if no case matches
    }
    if (id && subtitle) {
      setLoader(true);
      axios
        .get(`${URL.getActivePatientEducation}/${id}-${subtitle}-12300000-en`)
        .then((res) => {
          setDiseseData(res?.data?.data || []);
          setLoader(false);
        })
        .catch((err) => console.log(err));
    }
  }, [value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  return (
    <Box>
      <Helmet>
        <title>Condition Details</title>
      </Helmet>
      <Grid container className="conditionBg"></Grid>
      <Grid className="px-2 md:px-10 py-5">
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Box style={{ marginTop: -70 }}>
              <Paper className="p-2">
                <Grid className="text-2xl pl-5">{name}</Grid>
                <div className="flex gap-5 items-center justify-end">
                  <OutlinedButton
                    text="Subscribe"
                    borderRadius="10px"
                    borderColor="#205072"
                    color="#205072"
                    width="110px"
                    height="30px"
                    fontSize={{ xs: "14px", lg: "16px" }}
                  />
                  <OutlinedButton
                    text="Refer"
                    borderRadius="10px"
                    borderColor="#205072"
                    color="#205072"
                    width="110px"
                    height="30px"
                    fontSize={{ xs: "14px", lg: "16px" }}
                    startIcon={<ShareOutlined sx={{ fontSize: 12 }} />}
                  />
                </div>
                <hr style={{ marginTop: "10px" }} />
                <Box sx={{ width: "100%" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    sx={{ padding: 0, ".MuiTabs-indicator": { bottom: 8 } }}
                    textColor="inherit"
                    aria-label="full width tabs example"
                  >
                    <Tab
                      label="Overview"
                      {...a11yProps(0)}
                      sx={{
                        textTransform: "capitalize",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                        fontSize:'18px'
                      }}
                    />
                    <Tab
                      label="Symptoms & Causes"
                      {...a11yProps(1)}
                      sx={{
                        textTransform: "capitalize",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                        fontSize:'18px'
                      }}
                    />
                    <Tab
                      label="Diagnosis"
                      {...a11yProps(2)}
                      sx={{
                        textTransform: "capitalize",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                        fontSize:'18px'
                      }}
                    />
                    <Tab
                      label="Treatment"
                      {...a11yProps(3)}
                      sx={{
                        textTransform: "capitalize",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                        fontSize:'18px'
                      }}
                    />
                  </Tabs>
                </Box>
              </Paper>
              <Box
                style={{
                  overflowY: "scroll",
                  maxHeight: "calc(100vh - 310px)",
                  padding: "0px 10px",
                }}
              >
                <TabPanel value={value} index={0}>
                  <Box>
                    {loader ? (
                      <LoadingComponent />
                    ) : (
                      diseseData?.map((item, index) => {
                        return (
                          <Box
                            key={item?.id}
                            className={index === 0 ? "" : "pt-4"}
                          >
                            <div
                              style={{ color: "#8700FA" }}
                              className="capitalize"
                            >
                              {item?.child_subtitle}
                            </div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item?.body,
                              }}
                            />
                          </Box>
                        );
                      })
                    )}
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  {loader ? (
                    <LoadingComponent />
                  ) : (
                    diseseData?.map((item, index) => {
                      return (
                        <Box
                          key={item?.id}
                          className={index === 0 ? "" : "pt-4"}
                        >
                          <div
                            style={{ color: "#8700FA" }}
                            className="capitalize"
                          >
                            {item?.child_subtitle}
                          </div>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item?.body,
                            }}
                          />
                        </Box>
                      );
                    })
                  )}
                </TabPanel>
                <TabPanel value={value} index={2}>
                  {loader ? (
                    <LoadingComponent />
                  ) : (
                    diseseData?.map((item, index) => {
                      return (
                        <Box
                          key={item?.id}
                          className={index === 0 ? "" : "pt-4"}
                        >
                          <div
                            style={{ color: "#8700FA" }}
                            className="capitalize"
                          >
                            {item?.child_subtitle}
                          </div>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item?.body,
                            }}
                          />
                        </Box>
                      );
                    })
                  )}
                </TabPanel>
                <TabPanel value={value} index={3}>
                  {loader ? (
                    <LoadingComponent />
                  ) : (
                    diseseData?.map((item, index) => {
                      return (
                        <Box
                          key={item?.id}
                          className={index === 0 ? "" : "pt-4"}
                        >
                          <div
                            style={{ color: "#8700FA" }}
                            className="capitalize"
                          >
                            {item?.child_subtitle}
                          </div>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item?.body,
                            }}
                          />
                        </Box>
                      );
                    })
                  )}
                </TabPanel>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper className="p-2">
              <div className="text-[16px] font-medium">
                Don't ignore your Health Problem
              </div>
              <div className="text-[13px] font-normal">
                Find Available doctors to Consult Today
              </div>
              <Grid container justifyContent={"center"} spacing={2} pt={3}>
                <Grid item>
                  <Button
                    variant="outlined"
                    startIcon={
                      <img src={teleIcon} width={16} height={16} alt="tele" />
                    }
                    sx={{
                      height: "30px",
                      fontSize: { xs: "12px", lg: "14px" },
                      justifyContent: "space-between",
                      textTransform: "capitalize",
                      borderRadius: "10px",
                      borderColor: "#205072",
                      color: "#fff",
                      backgroundColor: "#205072",
                      "&:hover": { borderColor: "#205072" },
                    }}
                  >
                    Request Appointment
                  </Button>
                </Grid>
              </Grid>
            </Paper>
            <Paper sx={{ px: 2, py: 1, mt: 4 }}>
              <Grid className="flex gap-3 items-center text-base">
                {" "}
                <span
                  style={{ color: "#205072" }}
                  className="text-[14px] font-semibold"
                >
                  Book Appointment
                </span>{" "}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ConditionDetails;
