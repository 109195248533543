import React, { useEffect, useState } from 'react'
import CommonDialog from '../atoms/commonModal'
import { Box, Paper, Typography, Grid, Divider, Button, TextField, Select, Chip, IconButton, CircularProgress, DialogContent, DialogActions, DialogTitle, CardMedia, Tooltip } from '@mui/material'
import { Close, EventAvailable, ExpandLess, ExpandMore, LocationOnOutlined, PersonAddAltOutlined } from '@mui/icons-material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import moment from 'moment';
import { CustomTextField } from '../atoms/TextField';
import ContainedButton, { OutlinedButton } from '../atoms/commonbutton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import maleDoctorImg from '../../assets/images/MaleDoctor-no-photo.svg';
import FemaleDoctorImg from '../../assets/images/FemaleDoctor-No Photo.svg';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import URL from '../../services/api_url';
import { localstore } from '../localstore/localstore';
import Confirmbooking from './confirmbooking';
import ErrorStatusMsg from '../atoms/ErrorStatusMsg';
import dayjs from 'dayjs';
import StethIcon from '../../assets/images/stethoscope.svg';
import { getAppointmentSlots } from '../../redux/actions/actions';
import { useDispatch } from 'react-redux';
import editIcon from '../../assets/edit_24dp_FILL0_wght400_GRAD0_opsz24.svg';
import timeIcon from '../../assets/images/field-time-outlined.svg';
import BranchUrl from '../../services/api_branch_url';
import ReCAPTCHA from 'react-google-recaptcha';


function AppoinmentPopup({ open, setOpen, selectedbranch, userData, userRole }) {
    // console.log(userRole, "userRoleeeee")
    // console.log(userData, "userData")
    const selectedBranchId = selectedbranch?.id ? selectedbranch?.id : `${process.env.REACT_APP_PARAMITHA_ID}`

    const [show, setShow] = useState(1)
    const [value, setValue] = React.useState();
    const [symptom, setSymptom] = React.useState(null);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [verifyOtp, setVerifyotp] = useState("");
    const [otpSuccess, setOtpSuccess] = useState(false)
    const [otpOpen, setOtpOpen] = useState(false)
    const [nextPatientPage, setNextPatientPage] = React.useState(false);
    const dispatch = useDispatch()
    const urls = BranchUrl()
    const [searchTerm, setSearchTerm] = useState('');
    const [searchedItems, setSearchedItems] = useState([]);
    const [sheduleSlot, setSheduleSlot] = useState([]);
    const [captchaToken, setCaptchToken] = useState(null)
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [alertmessage, setAlertmessage] = useState('')
    const [alertstatus, setAlertstatus] = useState('')
    const [patientDetails, setPatientDetails] = useState([])
    const [loading, setLoading] = useState(false)
    const ibmId = localstore.getIbmId()
    const usertoken = localstore.getToken()
    const randomId = sessionStorage.getItem('randomId');
    const { id } = useParams()
    const [countdown, setCountdown] = useState(60);

    const todate = moment().format()

    const [patientInfo, setPatientInfo] = useState([
        { title: 'Manikanta', gender: 'Male', birthDate: "11 Years" },
    ]);

    const [dialogValue, setDialogValue] = React.useState({
        title: '',
        year: '',
    });

    const initialSlot = 13

    const [visibleSlots, setVisibleSlots] = useState(initialSlot);

    const loadMoreSlots = () => {
        setVisibleSlots(prev => prev + 4); // Load 5 more slots
    };

    // const handleClose = () => {
    //     setDialogValue({
    //         title: '',
    //         year: '',
    //     });
    //     // setPatientOpen(false);
    // };

    useEffect(() => {
        let timer;
        if (otpOpen && countdown > 0) {
            timer = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);
        }
        return () => clearTimeout(timer);
    }, [otpOpen, countdown]);

    const handleSearch = () => {
        if (searchTerm.trim() !== '') {
            setSearchedItems((prevItems) => [...prevItems, searchTerm]);
            setSearchTerm('');
        }
    };



    const handleRemoveItem = (index) => {
        setSearchedItems((prevItems) => prevItems.filter((_, i) => i !== index));
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        setValue({
            title: dialogValue.title,
            year: parseInt(dialogValue.year, 10),
        });
        handleClose();
    };

    const handleChangePhoneNumber = (e) => {
        setPhoneNumber(e.target.value?.replace(/\D/g, "")?.slice(0, 10))
    }

    const handleChangeOtp = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setVerifyotp(inputValue.slice(0, 5));
    }
    // const practitionerId = practitionerrole.entry[0]?.resource?.id;
    // console.log(practitionerId, "praction")
    const practitionerRoleId = userRole?.resource?.id;
    const practitionerId = userData?.id

    const params = {
        'X-FHIR-TENANT-ID': 'parimitha',
        'X-FHIR-DSID': 'fhirparimitha',
        'realm': 'parimitha'
    }
    useEffect(() => {
        if (practitionerId && practitionerRoleId) {
            const getSlots = async () => {
                setLoading(true);
                const todate = value ? moment(value).format() : moment().format(); // Check if a default date is provided

                try {
                    const slots = await getAppointmentSlots(urls, practitionerId, practitionerRoleId, todate, params);
                    setSheduleSlot(slots);
                } catch (error) {
                    console.error(error); // Handle the error appropriately
                } finally {
                    setLoading(false);
                }
            };
            getSlots();
        }
    }, [value, practitionerId, practitionerRoleId]);

    const handleAppointment = async (date) => {
        // console.log(date?.$d, moment(date?.$d).format());
        const selectdate = moment(date?.$d).format();
        setValue(date?.$d);

        setLoading(true);
        try {
            const res = await axios.get(`${urls.appointment}schedule/Slots/${practitionerId}/${practitionerRoleId}/${selectdate}`, {
                headers: params
            });
            setSheduleSlot(res?.data || []);
            console.log(res);
        } catch (err) {
            console.error(err); // Handle the error appropriately
        } finally {
            setLoading(false);
        }
    };



    const handleSelectSlot = (slot) => {
        setSelectedSlot(slot);

    }

    const handleOnNextPage = () => {
        if (ibmId) {
            setShow(3)
        } else {
            setShow(2)
        }
    }

    const handleRecaptchaChange = (value) => {
        setCaptchToken(value)
        setAlertmessage('')
    }
    const handleSendOtp = () => {

        const data = {
            "id": randomId,
            "phoneNumber": `+91${phoneNumber}`,
            "messagetype": "OTP",
            "apptype": "Paramitha Appointment Booking",
            "email": ""
        }
        // timeinterval();
        if (captchaToken) {


            setLoading(true)

            axios.post(`${URL.sendOtpAppointment}`, data, {
                headers: {
                    'Usertoken': `Bearer ${captchaToken}`
                }
            }).then(res => {
                if (res.status === 200 || res.status === 201) {
                    setAlertmessage('OTP send successfully')
                    setAlertstatus('success')
                    setOtpOpen(true)
                    setCountdown(60); // Reset countdown when OTP is sent

                }
            }).catch((error) => {
                console.log(error?.response?.data?.msg)
                const errorMessage = error.response ? error.response.data.msg : "An error occurred while sending OTP.";

                setAlertmessage(errorMessage)
                setAlertstatus('error')
            }).finally(() => {
                setLoading(false)
            })

        } else {
            setAlertmessage('Please verify the reCAPTCHA!')

            setAlertstatus('error')
        }
    }

    const handleClickOtp = async () => {
        const data = {
            "phoneNumber": `+91${phoneNumber}`,
            "otp": verifyOtp,
            "email": "",
            "realm": 'parimitha',
            "organizationId": selectedBranchId
        }
        setLoading(true)
        await axios.post(`${URL.verifyOtpAppointment}`, data, {
            headers: {
                "Usertoken": `True`,
            }
        }).then(res => {
            if (res.status === 200 || res.status === 201) {
                setAlertmessage('OTP verified successfully')
                setAlertstatus('success')
                setPatientDetails(res.data.entry)
                setShow(3)
                // setOtpSuccess((prev) => !prev)
            }

        }).catch((err) => {
            setAlertmessage(err.message)
            setAlertstatus('error')
        }).finally(() => {
            setLoading(false)
        })

    }




    const handleClose = () => {
        setOpen(false)
    }

    const handleBackCalender = () => {
        setPhoneNumber('')
        setOtpOpen(false)
        setVerifyotp('')
        setLoading(false)
        setAlertmessage('')
        setAlertstatus('')
        setShow(1)
    }
    const handleCancel = () => {
        setPhoneNumber('');
        setOtpOpen(false);
        setVerifyotp('');
        setLoading(false);
        setAlertmessage('');
        setAlertstatus('');
        setValue(dayjs());
        setSheduleSlot([]);
        setSelectedSlot('');
        setCaptchToken('')
        // setVisibleSlots(0);

        setVisibleSlots(initialSlot)
        setShow(1); // Navi
        handleClose()
    }
    const currentTime = new Date();
    const convertToIST = (date) => {
        return new Date(date).toLocaleString('en-IN', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZone: 'Asia/Kolkata'
        });
    };
    return (
        <div>
            <CommonDialog maxWidth={show === 3 ? 'md' : 'md'} open={open}
            //  onClose={show === 1 ? () => setOpen(false) : () => { }}

            >
                <DialogTitle sx={{ p: '5px' }}>
                    <div className='text-center text-lg md:text-2xl font-medium'>Schedule Consultation Appointment</div>
                    <div className='text-xs text-center font-normal text-[#E54290]'>@ {selectedbranch?.name || 'Paramitha'} </div>
                </DialogTitle>
                <Divider />
                {/* <Grid > */}

                <div className='flex items-center justify-between px-3 py-1'>
                    <Grid container alignItems={'center'}>
                        <Grid item xs={12} md={3.5} lg={4}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <CardMedia
                                    style={{ height: 55, width: 55, border: '3px solid #fff', borderRadius: '12px', padding: "3px", background: '#C6C6C6' }}
                                    className="shadow-2xl"
                                    component="img"
                                    alt="gdoctor img"
                                    width={55}
                                    height="55px"
                                    image={userData?.gender === "female" ? FemaleDoctorImg : maleDoctorImg} />
                                <div>
                                    <Typography sx={{ color: "#000000", fontSize: "16px", fontWeight: 500, }} >
                                        {userData?.name?.[0]?.text}
                                    </Typography>

                                    <Typography sx={{ color: "#816565", fontSize: "14px", display: "flex", alignItems: "center", gap: "2px", }} >
                                        <span>
                                            {userRole?.resource?.specialty?.[0]?.coding?.[0]?.display ?
                                                <img
                                                    src={StethIcon}
                                                    width={20}
                                                    height={20}
                                                    alt="stethoscope"
                                                    sx={{ fontSize: "12px", mr: "5px" }}
                                                /> : null}
                                        </span>
                                        {userRole?.resource?.specialty?.[0]?.coding?.[0]?.display}
                                        {/* General Peadtrics, Neonatolgy */}
                                    </Typography>
                                </div>
                            </Box>
                        </Grid>

                        <Grid xs={12} md={show === 1 ? 4.5 : 2.5} ></Grid>
                        {show === 2 || show === 3 ?
                            <Grid item xs={12} md={4.5} lg={5.5}>
                                <div className='flex items-center  gap-6 mt-1 md:mt-0'>
                                    <div>
                                        <div className='font-medium text-medium text-xs lg:text-sm'>Appointment Date</div>
                                        <Typography className='flex items-center fntsz-13'> <EventAvailable sx={{ fontSize: '18px', color: "#4B4C52", mr: "5px" }} />
                                            {moment(value).format('Do MMM YYYY')}
                                        </Typography>
                                    </div>
                                    <div>
                                        <div className='font-medium text-medium text-xs  lg:text-sm'>Appointment Time</div>
                                        <div className='font-medium text-medium flex fntsz-13 gap-2 items-center'><img src={timeIcon} width={"18px"} height={18} />
                                            {selectedSlot && moment(selectedSlot.start).format('hh:mm A')}  - {selectedSlot && moment(selectedSlot.end).format('hh:mm A')}
                                            <div onClick={handleBackCalender} className='cursor-pointer'>
                                                <img src={editIcon} alt="edit" width={18} height={18} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid >
                            : null
                        }
                        {
                            show === 1 &&
                            <Grid xs={12} md={4} lg={3.5}>
                                <div>
                                    <div className='text-sm pr-1'>Pediatrics OP Consultation</div>
                                    <div className='flex pt-1 items-center'>
                                        <div className='flex'>
                                            <LocationOnOutlined sx={{ fontSize: "18px", color: "#0F21B1", mr: "2px" }} />
                                            <div className='text-xs lg:text-sm font-medium'>Available at</div>
                                            <span className="text-xs lg:text-sm pl-2" style={{ color: "#0F4BBE" }} > {selectedbranch?.name || 'Paramitha'} </span>
                                        </div>
                                    </div>
                                    <Typography sx={{ color: "#000000" }} className="text-xs lg:text-sm font-medium flex pt-1 items-center" >
                                    </Typography>
                                </div>
                            </Grid>
                        }
                        {/* </div> */}
                        {/* {console.log(selectedSlot, 431)} */}
                        {/* </Grid> */}
                    </Grid>
                </div>
                <Divider />
                {
                    show === 1 ?
                        (
                            <>
                                <DialogContent sx={{ p: '5px 20px' }}>
                                    <Grid container columnSpacing={4} >
                                        <Grid item xs={12} sm={12} md={6}>
                                            <div className='font-medium text-medium'>Select Date</div>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DateCalendar root={{ background: 'red' }}
                                                    minDate={dayjs()}
                                                    slotProps={{
                                                        root: { background: 'red' },

                                                        calendarHeader: {
                                                            sx: {
                                                                position: "relative",
                                                                '& .MuiPickersArrowSwitcher-root': {
                                                                    width: 0
                                                                },
                                                                "& .MuiPickersCalendarHeader-labelContainer": {
                                                                    margin: "auto"
                                                                },
                                                                "& .MuiIconButton-edgeEnd": {
                                                                    position: "absolute",
                                                                    left: 0, top: 0, bottom: 0
                                                                },
                                                                "& .MuiIconButton-edgeStart": {
                                                                    position: "absolute",
                                                                    right: 0, top: 0, bottom: 0
                                                                }
                                                            }
                                                        },
                                                        leftArrowIcon: {
                                                            sx: { color: "#606060", fontSize: "2rem" }
                                                        },
                                                        rightArrowIcon: {
                                                            sx: { color: "#606060", fontSize: "2rem" }
                                                        },
                                                    }}
                                                    sx={{ '.muidatecalendar-root': { margin: 0, background: 'red' } }}
                                                    value={dayjs(value)}
                                                    onChange={handleAppointment} />
                                            </LocalizationProvider>
                                        </Grid>
                                        {/* {console.log(value, 41)} */}
                                        {/* {value && */}
                                        <Grid item xs={12} sm={12} md={6}>
                                            <div className='font-medium text-medium'>Select Slot</div>
                                            <div style={{ color: '#606060', fontSize: '15px' }}>Available Slots for {moment(value).format('Do MMMM YYYY,dddd')} </div>

                                            <Box sx={{
                                                overflowY: 'scroll', maxHeight: 'calc(100vh - 400px)', '&::-webkit-scrollbar': { display: 'none' }
                                            }}>
                                                {loading ? (
                                                    <div className="flex justify-center items-center mt-10">
                                                        <CircularProgress />
                                                    </div>
                                                ) : (
                                                    <div className="flex flex-wrap">
                                                        {sheduleSlot.length === 0 ? (
                                                            <div className='fntsz-14'>No slots available</div>
                                                        ) : (
                                                            // sheduleSlot.slice(0, visibleSlots).map((slot, index) => {
                                                            sheduleSlot

                                                                .map((slot, index) => {
                                                                    const slotStart = slot?.start;
                                                                    const slotEnd = slot?.end;
                                                                    const isPast = new Date(slotEnd) < new Date(currentTime);
                                                                    const isSelected = selectedSlot === slot;
                                                                    const isBusyTentative = slot.status === "busy-tentative";
                                                                    // const startTime = `${(new Date(slotStart).getUTCHours() % 12 || 12) < 10 ? "0" : ""}${new Date(slotStart).getUTCHours() % 12 || 12}:${new Date(slotStart).getUTCMinutes() < 10 ? "0" : ""}${new Date(slotStart).getUTCMinutes()}${new Date(slotStart).getUTCHours() < 12 ? "AM" : "PM"}`
                                                                    // const endTime = `${(new Date(slotEnd).getUTCHours() % 12 || 12) < 10 ? "0" : ""}${new Date(slotEnd).getUTCHours() % 12 || 12}:${new Date(slotEnd).getUTCMinutes() < 10 ? "0" : ""}${new Date(slotEnd).getUTCMinutes()}${new Date(slotEnd).getUTCHours() < 12 ? "AM" : "PM"}`
                                                                    // const startTime = `${(new Date(slotStart).getUTCHours() % 12 || 12) < 10 ? "0" : ""}${new Date(slotStart).getUTCHours() % 12 || 12}:${new Date(slotStart).getUTCMinutes() < 10 ? "0" : ""}${new Date(slotStart).getUTCMinutes()} ${new Date(slotStart).getUTCHours() < 12 ? "AM" : "PM"}`;
                                                                    // const endTime = `${(new Date(slotEnd).getUTCHours() % 12 || 12) < 10 ? "0" : ""}${new Date(slotEnd).getUTCHours() % 12 || 12}:${new Date(slotEnd).getUTCMinutes() < 10 ? "0" : ""}${new Date(slotEnd).getUTCMinutes()} ${new Date(slotEnd).getUTCHours() < 12 ? "AM" : "PM"}`;
                                                                    const startTime = convertToIST(slotStart);
                                                                    const endTime = convertToIST(slotEnd);


                                                                    return (
                                                                        <div key={index} className="w-1/2 py-2">
                                                                            <Tooltip title={isBusyTentative ? "Busy" : ""} arrow>
                                                                                <button
                                                                                    onClick={() => {
                                                                                        if (!isBusyTentative && !isPast) {
                                                                                            handleSelectSlot(slot);
                                                                                        }
                                                                                    }}
                                                                                    style={{
                                                                                        backgroundColor: isBusyTentative ? "#707070" : (isPast ? "#D3D3D3" : (isSelected ? "Blue" : "#EAF5FD")),
                                                                                        cursor: isBusyTentative || isPast ? "not-allowed" : "pointer",
                                                                                        color: isBusyTentative ? "#000000" : (isSelected ? "#FFFFFF" : "#000000"),
                                                                                        width: 150,

                                                                                    }}
                                                                                    className="text-sm rounded-xl px-2 py-1 text-xs"
                                                                                >
                                                                                    <div>{`${startTime} - ${endTime}`}</div>
                                                                                    {/* {moment(slot?.start).format('hh:mm A')} - {moment(slot?.end).format('hh:mm A')} */}

                                                                                </button>
                                                                            </Tooltip>
                                                                        </div>
                                                                    )
                                                                })
                                                        )}
                                                        {/* {visibleSlots < sheduleSlot.length && (
                                                            // <div className="text-center py-2">
                                                            <div
                                                                onClick={loadMoreSlots}
                                                                className="text-sm rounded-xl px-2 py-3 text-center cursor-pointer"
                                                            >
                                                                Load More....
                                                            </div>
                                                            // </div>
                                                        )} */}
                                                    </div>
                                                )}
                                            </Box>
                                        </Grid>

                                    </Grid>
                                    <Grid container justifyContent={"flex-end"}>
                                    </Grid>
                                </DialogContent>
                                <Divider />
                                <DialogActions>
                                    <Grid container justifyContent={"space-between"} px={3}>
                                        <Button
                                            color='error' sx={{ textTransform: 'capitalize' }} onClick={() => {
                                                setValue(dayjs());
                                                setSheduleSlot([]);
                                                setSelectedSlot('');
                                                setVisibleSlots(initialSlot)
                                                handleClose()
                                            }} >Cancel</Button>
                                        <Button variant="contained" className='text-lg' sx={{ color: '#fff', background: '#205072', textTransform: 'capitalize ' }} disabled={!selectedSlot} onClick={handleOnNextPage}>Next</Button>

                                    </Grid>
                                </DialogActions>
                            </>
                        )
                        : show === 2 ?
                            (
                                <>
                                    <DialogContent sx={{ p: '20px 40px' }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Box >
                                                    <Typography sx={{ fontSize: 16, fontWeight: 500 }} >Register with Phone Number</Typography>
                                                </Box>
                                                <Box sx={{ mt: 1 }}>
                                                    <CustomTextField
                                                        sx={{ ".MuiInputBase-root": { paddingRight: "0px !important" } }}
                                                        placeholder="Enter Your Phone Number"
                                                        type={'text'}
                                                        borderColor="#707070"
                                                        borderRadius="7px"
                                                        width="100%"
                                                        padding="0px"
                                                        // borderColor="#707070"

                                                        icon={!otpOpen && phoneNumber.length === 10 ?
                                                            <ArrowForwardIcon sx={{ backgroundColor: "#1B5775", fontSize: '40px', color: "white", borderRadius: "0px 7px 7px 0px", }}
                                                                onClick={handleSendOtp} /> : null}
                                                        value={phoneNumber}
                                                        onChange={handleChangePhoneNumber}
                                                    />
                                                </Box>
                                                {!otpOpen &&
                                                    <Box sx={{ mt: 4, pl: "0px", transform: 'scale(0.8)', transformOrigin: '0 0' }}>

                                                        <ReCAPTCHA
                                                            sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`}
                                                            onChange={handleRecaptchaChange}
                                                            size="normal"
                                                        // style={{ transform: 'scale(0.9)' }}
                                                        />
                                                    </Box>
                                                }

                                                {otpOpen &&
                                                    <>
                                                        <Box sx={{ mt: 3 }}>
                                                            <Typography sx={{ fontSize: { xs: 14, sm: 16, md: 16 }, fontWeight: 500 }}>Verify OTP</Typography>
                                                        </Box>
                                                        <Box sx={{ mt: 1 }}>
                                                            <CustomTextField placeholder="Enter OTP Here" type={'text'} borderColor="#59D8FA" borderRadius="7px" value={verifyOtp} onChange={handleChangeOtp} />
                                                            <div className='flex align-center justify-between mt-1'>
                                                                <Typography sx={{ fontSize: '13px', fontWeight: 500 }}>{countdown > 0 ? `${countdown} sec` : "Time's up"}</Typography>
                                                                <Typography component='button' sx={{ borderRadius: '4px', fontSize: '13px', fontWeight: 500 }} onClick={handleSendOtp} disabled={countdown > 0}>Resend OTP</Typography>
                                                            </div>
                                                        </Box>

                                                        <Box sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
                                                            <div></div>
                                                            <div>
                                                                <OutlinedButton
                                                                    width="140px"
                                                                    height="32px"
                                                                    borderColor="#1B5775"
                                                                    color="#1B5775"
                                                                    borderRadius="7px"
                                                                    text="Verify OTP"
                                                                    onClick={handleClickOtp} // onClick event handler
                                                                    disabled={!verifyOtp}
                                                                    startIcon={null}
                                                                    endIcon={loading ? <CircularProgress size={20} color="inherit" /> : null} />
                                                            </div>
                                                        </Box>
                                                    </>
                                                }
                                            </Grid>
                                            <Grid item md={1} lg={2}></Grid>
                                            <Grid item md={5} lg={4} mt={2} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                                                <div>
                                                    <div className='text-sm pr-1'>Pediatrics OP Consultation</div>
                                                    <Typography sx={{ color: "#000000", fontSize: "14px", }} className="text-sm font-medium flex pt-1 items-center" >
                                                        <LocationOnOutlined sx={{ fontSize: "18px", color: "#0F21B1", mr: "2px" }} />
                                                        Available at <span className="text-sm pl-2" style={{ color: "#0F4BBE" }} > {selectedbranch?.name || 'Paramitha'} </span>
                                                    </Typography>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />

                                    </DialogContent>
                                    <Divider />
                                    <DialogActions>
                                        <Grid container justifyContent={"space-between"} px={3}>
                                            <Button
                                                color='error' sx={{ textTransform: 'capitalize' }} onClick={handleCancel} >Cancel</Button>
                                        </Grid>
                                    </DialogActions>

                                </>
                            )
                            : show === 3 ?
                                (
                                    <Confirmbooking phoneNumber={phoneNumber} selectedSlot={selectedSlot} userData={userData} userRole={userRole} id={id} patientDetails={patientDetails} handleCancel={handleCancel} handleClose={handleClose} setShow={setShow} value={value} handleBackCalender={handleBackCalender} />
                                ) : null
                }

            </CommonDialog>

        </div >
    )
}

export default AppoinmentPopup;