import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import CustomSlick from "./CustomSlick";
import { useNavigate } from "react-router-dom";

const options = ["Google Business"];
const serviceOptions = ['Kompally', 'Kothapet', 'Chintal', 'Chandanagar', 'Medipally', 'Bachupally'];

const TestimonialPage = () => {
  const [value, setValue] = useState('Kompally');
  const [inputValue, setInputValue] = useState("Kompally");

  const [serviceValue, setServiceValue] = useState("Google Business");
  const [inputServiceValue, setInputServiceValue] = useState("");
  const navigate = useNavigate();

  return (
    <div>
      <div style={{ paddingTop: "15px" }}>
        <Typography
          level="h1"
          fontSize={32}
          sx={{
            textAlign: "center",
            fontWeight: 500,
            color: '#E54290'
            // textTransform: "uppercase",
          }}
        >
          Why Paramitha Hospitals?
        </Typography>
        <Typography
          level="h2"
          fontSize={{ xs: 16, md: 20 }}
          sx={{
            textAlign: "center",
            opacity: "1",
            textTransform: "capitalize",
            lineHeight: '31px'
          }}
        >
          Our Patients Stories, are testimonials for our Quality of Care
        </Typography>
      </div>
      {/* <Grid
        container
        gap={3}
        paddingTop={2}
        style={{ padding: "8px 15%" }}
      >
        <Grid item xs={12} sm={6} md={4}>
          <Box>
            <Autocomplete
              value={serviceValue}
              onChange={(event, newValue) => {
                setServiceValue(newValue);
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              id="controllable-states-demo"
              options={options}
              sx={{
                width: 300,
                "& .MuiAutocomplete-inputRoot": {
                  height: 33,
                  backgroundColor: "#fff",
                },
                ".MuiOutlinedInput-root": { padding: "0px" },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Service Category"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <IconButton>
                        <FmdGoodIcon sx={{ fontSize: "15px" }} />
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box>
            <Autocomplete
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              inputValue={inputServiceValue}
              onInputChange={(event, newInputValue) => {
                setInputServiceValue(newInputValue);
              }}
              id="controllable-states-demo"
              options={serviceOptions}
              sx={{
                width: 300,
                "& .MuiAutocomplete-inputRoot": {
                  height: 33,
                  backgroundColor: "#fff",
                },
                ".MuiOutlinedInput-root": { padding: "0px" },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="All Facilities"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <IconButton>
                        <FmdGoodIcon sx={{ fontSize: "15px" }} />
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
          </Box>
        </Grid>
      </Grid> */}
      <div
        style={{
          paddingTop: "30px",
          padding: "30px 5%"
        }}
      >
        <CustomSlick value={value} />
      </div>
      <Grid container justifyContent={"center"} paddingTop={3} paddingBottom={4}>
        <Button variant='outlined'
          sx={{
            borderColor: "#E54290", borderRadius: "12px", color: "#E54290", padding: "5px 10px", textTransform: 'capitalize', mt: "8px", '&:hover': {
              borderColor: "#E54290", bgcolor: '#E54290', color: '#FFFFFF'
            }
          }}
          onClick={() => navigate(`/${value}/testimonial`)}
          className="cursor-pointer"
        >
          See all Reviews
        </Button>
      </Grid>
    </div>
  );
};

export default TestimonialPage;
