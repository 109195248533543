import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Card, Grid, Paper, Typography } from '@mui/material';
import axios from 'axios';
import URL from '../../services/api_url';
import { useSelector } from 'react-redux';
import healthserviceicon from '../../assets/healthcareprograms/Health Department 2.svg'
import { useNavigate } from 'react-router-dom';
import BranchUrl from '../../services/api_branch_url';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

const truncateText = (text, maxLength) => {
    if (text && text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
    }
    return text;
};
function ServiceOverview({ deptData }) {
    const navigate = useNavigate()
    const baseurl = BranchUrl()
    const selectBranchInfo = useSelector((state) => state.SelectedBranch.selectedbranch);
    // console.log(deptData, "depta")
    const [editAboutText, setEditAboutText] = useState('');
    const [about, setAbout] = useState(false);
    const [hover, setHover] = useState(false);

    useEffect(() => {
        if (deptData?.extraDetails) {
            if (!about) {
                const filteredData = deptData?.extraDetails?.length > 400
                    ? deptData?.extraDetails?.slice(0, 400)
                    : deptData?.extraDetails;
                setEditAboutText(filteredData);
            } else {
                setEditAboutText(deptData?.extraDetails);
            }
        }
    }, [deptData, about]);

    const handleToggleAbout = () => {
        setAbout(prevAbout => !prevAbout);
    };

    const headers = {
        'X-FHIR-TENANT-ID': 'parimitha',
        'X-FHIR-DSID': `fhirparimitha`,
        'realm': 'parimitha',
        'Cache-Control': 'no-cache',
    }
    function AreaOfLocation({ locationId, headers }) {
        const [serviceData, setServiceData] = useState(null);

        useEffect(() => {
            if (locationId) {
                axios.get(`${baseurl.fhir_tenant_orgId}Location/${locationId}`, { headers })
                    .then((res) => {
                        setServiceData(res?.data);
                    })
                    .catch((err) => console.log(err));
            }
        }, [locationId, headers]);

        const handleSelectService = (title, id) => {
            let name = title.replace(/\//g, '')
            navigate(selectBranchInfo?.name ? `/${selectBranchInfo?.name}/healthcaredept/${name}/${id}` : `/healthcaredept/${name}/${id}`)
            window.location.reload();
        }

        return (
            <Paper elevation={2} className='flex items-center cursor-pointer px-2 py-2' onClick={() => handleSelectService(serviceData?.name, serviceData?.id)}>
                <img
                    src={serviceData?.photo?.url || healthserviceicon}
                    alt=""
                    width={35}
                    height={35}
                    style={{ marginRight: "8px", height: '35px' }}
                />
                <Box className="truncate">
                    <Grid className='text-base font-semibold capitalize' sx={{ color: "#1D5775" }} >
                        {serviceData?.name}
                    </Grid>
                    <Grid className="truncate text-sm" sx={{ color: "#525C79" }}  >
                        {serviceData?.comment}
                    </Grid>
                </Box>
            </Paper>
        );
    }

    return (
        <div>
            <div className='text-lg pb-1 font-semibold '>Description </div>
            <div>
                <Typography my={1}>
                    <div
                        dangerouslySetInnerHTML={{ __html: editAboutText }}
                        style={{ whiteSpace: 'pre-wrap' }}
                    />
                </Typography>

                <Card>
                    {deptData?.extraDetails?.length > 400 && (
                        <div
                            style={{ padding: '8px', cursor: 'pointer', fontWeight: 400, background: hover ? "#FDF3F7" : "", color: hover ? "#E44190" : '#E44190' }}
                            onClick={handleToggleAbout}
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                        >
                            {about ? (
                                <div className='flex justify-center items-center'>
                                    <div> Show less </div>
                                    <KeyboardArrowUp />
                                </div>
                            ) : (
                                <div className='flex justify-center items-center'>
                                    <div> Show More </div>
                                    <KeyboardArrowDown />
                                </div>
                            )}
                        </div>
                    )}
                </Card>
            </div>
            <div className='text-sm font-medium mt-2'>Key Characteristics</div>
            <div className='text-sm flex'>
                {deptData?.characteristic?.map((item, index) => {
                    return (
                        <Grid key={index} sx={{ p: "10px" }}>
                            <div className='text-sm' style={{ color: '#A46FD9' }}>#{item?.coding?.[0]?.display}</div>
                        </Grid>
                    )
                })}
            </div>
            <div className='text-lg font-semibold py-4'>Areas of Care </div>
            <div className='flex gap-1 flex-wrap items-center'>
                <Grid container spacing={2}>
                    {deptData?.location?.map((item, index) => (
                        <Grid item xs={12} md={6} lg={4}>
                            <div key={index} className='text-sm font-medium' >
                                <AreaOfLocation locationId={item?.reference.replace("Location/", "")} headers={headers} />
                            </div>

                        </Grid>
                    ))}

                </Grid>
            </div>
        </div >
    )
}

export default ServiceOverview;
