import {
  Box,
  Grid,
  Typography,
  Autocomplete,
  Paper,
  Button,
  TextField,
  IconButton,
  Card,
  CardMedia,
  Link,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, alpha } from "@mui/material/styles";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import doctorimg from "../../../assets/images/doctorimg.jpg";
import stethoscopeimg from "../../../assets/images/stethoscope.svg";
import Telemedicineimg from "../../../assets/images/Telemedicine.svg";

import FmdGoodIcon from "@mui/icons-material/FmdGood";
import {
  ArrowForward,
  EventAvailable,
  EventAvailableOutlined,
  GTranslateOutlined,
  LocationOnOutlined,
} from "@mui/icons-material";
import { DoctorsTab, locationJson } from "../../../shared/constants";
import axios from "axios";
import URL from "../../../services/api_url";
import { DoctorCard } from "../../branchhome/DoctorCard";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import BranchUrl from "../../../services/api_branch_url";
// import { DoctorsTab } from "../../../../utils/constants";

const options = ["Option 1", "Option 2", "Option 3"];
const serviceOptions = ["Option 3", "Option 2", "Option 1"];
const SpecialistOptions = ["Option 2", "Option 3", "Option 1"];

const DoctorTab = ({ branch }) => {

  const [inputServiceValue, setInputServiceValue] = React.useState("");

  const [selectSpeciality, setSelectSpeciality] = useState(null);
  const [inputSpeciality, setInputSpeciality] = useState("");
  const [practitionerRole, setPractitionerRole] = useState([])

  const theme = useTheme();
  const navigate = useNavigate()
  const baseurl = BranchUrl()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const branchId = branch?.id ? branch?.id : `${process.env.REACT_APP_PARAMITHA_ID}`

  useEffect(() => {
    if (branch?.tenant) {
      console.log('first, 71')
      axios.get(`${process.env.REACT_APP_API_URL_v4}parimitha/${branchId}/PractitionerRole`, {
        headers: {
          'X-FHIR-TENANT-ID': 'parimitha',
          'Realm': 'parimitha',
          'X-FHIR-DSID': `fhirparimitha`
        }
      }).then((res) => {
        setPractitionerRole(res?.data?.entry || [])
      }).catch((err) => console.log(err))
    }
  }, [branch])

  return (
    <Box
      sx={{
        p: {
          xs: "0px",
          sm: "10px",
          md: '0px'
          // "&.MuiBox-root css-19kzrtu": { p: "0px" }
        },
        mt: 2
      }}
    >
      <div className="mt-2">
        <Grid container spacing={2} p={{ xs: "0px 0px", md: "20px 0px" }}>

          {practitionerRole?.length > 0 ?
            practitionerRole?.slice(0, 4)?.map((val, index) => {
              return (
                <Grid item xs={12} sm={6} key={index} md={6} lg={6} alignItems={'flex-start'} sx={{
                  p: {
                    xs: "10px 0px", md: "10px"
                  }
                }
                } >
                  <DoctorCard val={val} />
                </Grid>
              );
            }) : <Grid item xs={12} sm={12} md={12} lg={12} alignItems={'flex-start'} sx={{ p: "10px", textAlign: 'center', mt: 1, height: '150px' }}>
              <Typography variant="h6" sx={{ color: "#525C79" }}>
                No Doctors available
              </Typography></Grid>

          }
        </Grid>
      </div>
      {
        practitionerRole?.length >= 4 &&
        <div style={{ display: "flex", justifyContent: "center", marginTop: "10px", }}>
          <Button variant="outlined"
            onClick={() => navigate('/doctors')}
            sx={{
              borderColor: "#E54290", textTransform: "capitalize", color: '#E54290', width: 255, fontSize: "18px", borderRadius: "10px", mt: 2, height: "54px",
              '&:hover': { backgroundColor: '#E54290', borderColor: '#E54290', color: 'white' },
            }}
            endIcon={<ArrowForward />}
          >
            <Typography sx={{ fontSize: { xs: '14px', md: "18px", fontWeight: 500 } }}>  Find All Doctors</Typography>
          </Button>

        </div>
      }

      {/* <Grid item xs={12} sm={0} md={1.5} sx={{ p: "10px" }}></Grid> */}
    </Box >
  );
};

export default DoctorTab;
