import React, { useState } from 'react';
import { Box, CircularProgress, Grid, Paper, Typography, useMediaQuery } from '@mui/material'
import { CustomTextField } from '../atoms/TextField'
import ContainedButton, { OutlinedButton } from '../atoms/commonbutton'
import { Visibility, VisibilityOff } from '@mui/icons-material';
import * as yup from 'yup';
import { Formik } from 'formik';
import CustomAutoComplete from '../atoms/CustomAutoComplete';
import { useDispatch, useSelector } from 'react-redux';
import { locationJson } from '../../shared/constants';
import MedxpertIcon from '../../assets/medxlove.svg';
import AbhaIcon from '../../assets/abhalogo.svg';
import URL from '../../services/api_url';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import LoginBgImg from '../../assets/LOGIN PAGE IMAGE.svg'
import { useTheme } from '@mui/styles';
import ErrorStatusMsg from '../atoms/ErrorStatusMsg';
import { branchInfo } from '../../redux/slices/branchSlice';
import { selectedBranchInfo } from '../../redux/slices/SelectedBranchSlice';
import ForgotUserName from './forgotUserName';
import ForgotPassword from './forgotPassword';

const Loginpage = ({ IncreaseStep }) => {
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate()
    const theme = useTheme();
    const ismobile = useMediaQuery('(min-width:900px)');
    const selectedbranch = useSelector((state) => state.SelectedBranch.selectedbranch)
    const accesstoken = useSelector((state) => state.login.accesstoken)
    const [alertmessage, setAlertmessage] = useState('')
    const [alertstatus, setAlertstatus] = useState('')
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const branchOptions = locationJson
    const [forgotuserOpen, setForgotuserOpen] = useState('')
    const [forgotpassOpen, setForgotpassOpen] = useState('')

    const signUpValidationSchema = yup.object().shape({
        username: yup.string().required('Username is required'),
        password: yup
            .string()
            .matches(/\w*[a-z]\w*/, 'Password must have a small letter')
            .matches(/\w*[A-Z]\w*/, 'Password must have a capital letter')
            .matches(/\d/, 'Password must have a number')
            .matches(/[!@#$%^&*()\-_"=+{}; :,<.>]/, 'Password must have a special character')
            .min(8, ({ min }) => `Password must be at least ${min} characters`)
            .required('Password is required'),

        // selectBranch: yup.object().nullable().required("This Field is required"),

    })

    const handleClickShowPassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword)
    }
    const forgotPassword = () => {

        window.location.href = `${process.env.REACT_APP_KEYCLOAK}/realms/parimitha/login-actions/reset-credentials?client_id=fhirFE`
    }

    return (
        <div style={{ backgroundColor: "#EC7AB3", }}>
            <Grid container>
                <Grid item xs={12} sm={12} md={7}>
                    <Box sx={{
                        padding: { xs: "20px", sm: "20px 40px", md: "20px 40px" },
                        // backgroundColor: "#1B5775", // Assuming you want a background color for the left section
                    }}>
                        <Typography sx={{ fontSize: { xs: 17, sm: 17, md: 20 }, color: "white", textTransform: "capitalize" }}>
                            Manage Your Appointments, Prescriptions <br />Medical Records, Test Reports & More
                        </Typography>
                        {ismobile && (
                            <Box mt={2}>
                                <img src={LoginBgImg} alt="login" style={{ width: '100%' }} />
                            </Box>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                    <Box sx={{
                        padding: { xs: "20px 10px", sm: "20px 40px", md: "20px 40px" },
                    }}>
                        <Paper elevation={3} sx={{ padding: { xs: "20px 20px", sm: '20px 20px', md: '20px 20px', lg: '20px 40px' } }}>
                            <Formik
                                validationSchema={signUpValidationSchema}
                                initialValues={{
                                    // username: "", password: "", selectBranch: selectedbranch || null
                                    username: "", password: "", selectBranch: selectedbranch || null
                                }}
                                enableReinitialize={true}
                                onSubmit={async (values) => {
                                    const data = {
                                        'username': values.username,
                                        'password': values.password
                                    }
                                    setLoading(true)
                                    try {
                                        const res = await axios.post(`${URL.paramitha}/login/parimitha`, data, {
                                            headers: {
                                                'realm': `${process.env.REACT_APP_MAIN_TENANT}`
                                            }
                                        })
                                        if (res.status === 200 || res.status === 201) {
                                            const token = res.data.access_token;
                                            const refreshtoken = res.data.refresh_token
                                            const user = jwtDecode(token);
                                            const userid = user.sub
                                            const ibmid = user.ibmId
                                            const roles = user.realm_access?.roles || [];
                                            const hasPatientRole = roles.includes("patient");
                                            localStorage.setItem('kctoken', token)
                                            localStorage.setItem('rctoken', refreshtoken)

                                            if (hasPatientRole && ibmid) {
                                                let username = user.preferred_username;

                                                let patientName = JSON.stringify(user.name);
                                                localStorage.setItem('patientName', patientName)
                                                localStorage.setItem('userName', username)
                                                localStorage.setItem('IbmId', ibmid)
                                                localStorage.setItem('userId', userid)
                                                localStorage.setItem('fixedIbmId', ibmid);
                                                localStorage.setItem('pId', ibmid)

                                                setAlertstatus('success');
                                                setAlertmessage('Successfully Logged In!');
                                                navigate('/')

                                            } else if (hasPatientRole === undefined || hasPatientRole === null) {
                                                alert("Does Not Exist Your Account")

                                            } else {
                                                navigate(selectedbranch?.name ? `/${selectedbranch?.name}/newuser` : '/newuser')
                                            }
                                        } else if (res.status === 401) {
                                            setAlertstatus(res.data)
                                            setAlertmessage('error')
                                        }
                                    } catch (error) {
                                        setAlertmessage(error.response.data)
                                        setAlertstatus('error')
                                    } finally {
                                        setLoading(false)
                                    }
                                }}
                            >
                                {({
                                    handleSubmit,
                                    isValid,
                                    values,
                                    errors,
                                    handleChange,
                                    handleBlur,
                                    touched,
                                    isSubmitting,
                                    setFieldValue
                                }) => (
                                    <>
                                        <Box>
                                            <Typography sx={{ fontSize: { xs: 17, sm: 17, md: 18 }, textTransform: "capitalize", color: "#155775", fontWeight: 600 }}>
                                                Login to Paramitha Hospitals
                                            </Typography>

                                            {/* <Box mt={2}>
                                                <Box sx={{ mb: "5px" }}>
                                                    <label>Select branch</label>
                                                </Box>
                                                <CustomAutoComplete
                                                    borderRadius='7px'
                                                    borderColor='#59D8FA'
                                                    id="selectBranch"
                                                    placeholder='Select Branch'
                                                    options={branchOptions}
                                                    value={values.selectBranch}
                                                    getOptionLabel={option => option.name}
                                                    onChange={(event, value) => {
                                                        handleChange({
                                                            target: { name: "selectBranch", value }
                                                        });
                                                        localStorage.setItem("brName", value ? value.name : '');
                                                        dispatch(branchInfo(value ? value.name : ''));
                                                        localStorage.setItem("selectedbranch", JSON.stringify(value));
                                                        dispatch(selectedBranchInfo(value))
                                                        navigate(value?.name ? `/${value?.name}/login` : '/login')
                                                    }}
                                                    renderInputProps={{
                                                        error: touched.selectBranch && Boolean(errors.selectBranch),
                                                        helperText: touched.selectBranch && errors.selectBranch
                                                    }}
                                                    sx={{
                                                        width: "100%",
                                                        "& .MuiOutlinedInput-root": { padding: "0px" }
                                                    }}
                                                    error={touched.selectBranch && Boolean(errors.selectBranch)}
                                                />
                                                {errors.selectBranch && touched.selectBranch && (
                                                    <Typography sx={{ color: '#D32F2F', fontSize: '14px' }}>
                                                        {touched.selectBranch && errors.selectBranch}
                                                    </Typography>
                                                )}
                                            </Box> */}

                                            <Box mt={2}>
                                                <Box sx={{ mb: "5px" }}>
                                                    <label>User Name</label>
                                                </Box>
                                                <CustomTextField
                                                    placeholder="Enter your User Name here"
                                                    type={'text'}
                                                    borderColor="#59D8FA"
                                                    borderRadius="7px"
                                                    value={values.username}
                                                    onChange={handleChange('username')}
                                                    handleBlur={handleBlur('username')}
                                                    text="@ Paramitha"
                                                    error={touched.username && Boolean(errors.username)}
                                                />
                                                {errors.username && touched.username && (
                                                    <Typography sx={{ color: '#D32F2F', fontSize: '14px' }}>
                                                        {touched.username && errors.username}
                                                    </Typography>
                                                )}
                                            </Box>
                                            <Box sx={{ mt: "5px", display: 'flex', justifyContent: 'space-between' }}>
                                                <Typography sx={{ fontSize: '14px', cursor: 'pointer', "&.hover": { textDecoration: "underline" }, color: "#2700FF" }}
                                                    // onClick={() => setForgotuserOpen(true)}
                                                    onClick={() => navigate(selectedbranch?.name ? `/${selectedbranch?.name}/forgotuser` : '/forgotuser')}
                                                >
                                                    Forgot your User Name?
                                                </Typography>
                                            </Box>
                                            <Box mt={2}>
                                                <Box sx={{ mb: "5px" }}>
                                                    <label>Password</label>
                                                </Box>
                                                <CustomTextField
                                                    placeholder="Enter your Password"
                                                    type={showPassword ? 'text' : 'password'}
                                                    borderColor="#59D8FA"
                                                    icon={showPassword ? <Visibility /> : <VisibilityOff />}
                                                    value={values.password}
                                                    borderRadius="7px"
                                                    padding="0px 15px"
                                                    onChange={(e) => {
                                                        handleChange('password')(e)
                                                        setAlertmessage('')
                                                    }}
                                                    handleBlur={handleBlur('password')}
                                                    onClick={handleClickShowPassword}
                                                    error={touched.password && Boolean(errors.password)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            handleSubmit();
                                                        }
                                                    }}
                                                />
                                                {errors.password && touched.password && (
                                                    <Typography sx={{ color: '#D32F2F', fontSize: '14px' }}>
                                                        {touched.password && errors.password}
                                                    </Typography>
                                                )}
                                            </Box>
                                            <Box sx={{ mt: "5px", display: 'flex', justifyContent: 'space-between' }}>
                                                <Typography sx={{ fontSize: '14px', cursor: 'pointer', "&.hover": { textDecoration: "underline" }, color: "#2700FF" }}
                                                    onClick={() => setForgotpassOpen(true)}
                                                >
                                                    Forgot Password?
                                                </Typography>
                                            </Box>
                                            <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                                            <Box sx={{ display: "flex", justifyContent: "center", mt: 2, mb: 2 }}>
                                                <ContainedButton
                                                    width="120px"
                                                    height="35px"
                                                    bgcolor="#1B5775"
                                                    borderRadius="7px"
                                                    text="Login"
                                                    onClick={handleSubmit}
                                                    startIcon={null}
                                                    endIcon={loading ? <CircularProgress size={20} color='inherit' /> : null}
                                                />
                                            </Box>
                                        </Box>
                                    </>
                                )}
                            </Formik>

                            <hr />
                            <Box sx={{ mt: 2 }}>
                                <Grid container sx={{ border: "1px solid black" }}>
                                    <Grid item xs={2} sm={1} md={2}>
                                        <img src={AbhaIcon} width={34} height={30} alt="hospital"
                                            style={{ border: "3px solid #fff", margin: "5px", marginLeft: "12px" }}
                                        />
                                    </Grid>
                                    <Grid item xs={10} sm={11} md={10} textAlign={'center'}>
                                        <Box sx={{ backgroundColor: "#95BE33", color: "white", padding: "8px", fontSize: { xs: 14, sm: 14, md: 14, lg: 16 } }}>Continue with Ayushman Bharat</Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={{ mt: 2 }}>

                                <Grid container sx={{ border: "1px solid black" }}>
                                    <Grid item xs={2} sm={1} md={2}>
                                        <img src={MedxpertIcon} width={34} height={30} alt="hospital"
                                            style={{ border: "3px solid #fff", margin: "5px", marginLeft: "12px" }}
                                        />
                                    </Grid>
                                    <Grid item xs={10} sm={11} md={10} textAlign={'center'}>
                                        <Box sx={{ backgroundColor: "#1B5775", color: "white", padding: "8px", fontSize: { xs: 14, sm: 14, md: 14, lg: 16 } }}>Continue with MedXperts</Box>
                                    </Grid>
                                </Grid>

                            </Box>
                            <hr />
                            <Box sx={{ mt: 2, mb: 1, }}>
                                <Typography sx={{ fontSize: '14px', fontWeight: 700, color: "#000000" }}
                                // onClick={() => navigate("/forgotpassword")}
                                >New to Paramitha Hospital ?</Typography>
                                <Box sx={{ mb: 2, }}>
                                    <Typography sx={{ fontSize: '12px', color: "#000000" }}
                                    // onClick={() => navigate("/forgotpassword")}
                                    >Is this the first time you are visiting our Hospital or Website ? If yes,<br /> Click Register Now to Signup</Typography>

                                </Box>
                                <Box sx={{ display: "flex" }}>

                                    <div>
                                        <OutlinedButton
                                            width="140px"
                                            height="35px"
                                            borderColor="#1B5775"
                                            color="#1B5775"
                                            text="Register Now"
                                            borderRadius="7px"
                                            onClick={() => navigate(selectedbranch?.name ? `/${selectedbranch?.name}/signup` : '/signup')}
                                            // onClick={handleSubmit} // onClick event handler
                                            // disabled={!isValid || !values.fullName || !values.username || !values.phonenum || !values.createpassword || !values.confirmPassword || !values.EmailId || !values.selectBranch}
                                            startIcon={null}
                                            endIcon={null}
                                        />
                                    </div>
                                </Box>

                            </Box>
                        </Paper>
                    </Box>
                </Grid>

            </Grid >
            <ForgotUserName open={forgotuserOpen} setForgotuserOpen={setForgotuserOpen} selectedbranch={selectedbranch} />
            <ForgotPassword open={forgotpassOpen} setForgotpassOpen={setForgotpassOpen} selectedbranch={selectedbranch} />

        </div >
    )
}

export default Loginpage