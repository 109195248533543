import { Button, Grid, Paper, Stack, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react'
import ContainedButton from '../atoms/commonbutton';
import RequestIcon from '../../assets/images/referral.svg'
import { FaInstagram, FaLinkedin, FaXTwitter } from 'react-icons/fa6';
import { IoLogoYoutube } from 'react-icons/io';
import { BiSolidPhone } from 'react-icons/bi';
import { CiMail } from 'react-icons/ci';


function DepartContacts(props) {
    const { timings, setTimings, activeDay, setActiveDay, deptData, appointmentBooking, WorkingDays } = props
    const customStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };
    return (
        <div>
            <div className='font-semibold text-lg '>Contact</div>

            <Grid container>
                <Grid item xs={12} md={6}>
                    <Paper elevation={3} className='p-2 mt-1'>
                        <div className='text-[16px] font-medium'>Get services from Out-Patient Department ?</div>
                        <div className='text-[14px] font-medium pt-3'>Register & Book Appointment to start your Wellness journey</div>

                        <Grid container>
                            <Grid item md={12}>
                                <div className='font-medium mt-1 text-sm'>Availability</div>
                                {/* <div className="work-hours">
                                            {
                                                timings?.day && timings?.today ? (
                                                    <p>
                                                        {" "}
                                                        {timings?.day == "mon"
                                                            ? "Monday" + "(Today)"
                                                            : timings?.day == "tue"
                                                                ? "Tuesday" + "(Today)"
                                                                : timings?.day == "wed"
                                                                    ? "Wednesday" + "(Today)"
                                                                    : timings?.day == "thu"
                                                                        ? "Thursday" + "(Today)"
                                                                        : timings?.day == "fri"
                                                                            ? "Friday" + "(Today)"
                                                                            : timings?.day == "sat"
                                                                                ? "Saturday" + "(Today)"
                                                                                : timings?.day == "sun"
                                                                                    ? "Sunday" + "(Today)"
                                                                                    : ""}{" "}
                                                    </p>
                                                ) :
                                                    timings?.day && !timings?.today ? (
                                                        <p>
                                                            {" "}
                                                            {timings?.day == "mon"
                                                                ? "Monday"
                                                                : timings?.day == "tue"
                                                                    ? "Tuesday"
                                                                    : timings?.day == "wed"
                                                                        ? "Wednesday"
                                                                        : timings?.day == "thu"
                                                                            ? "Thursday"
                                                                            : timings?.day == "fri"
                                                                                ? "Friday"
                                                                                : timings?.day == "sat"
                                                                                    ? "Saturday"
                                                                                    : timings?.day == "sun"
                                                                                        ? "Sunday"
                                                                                        : ""}{" "}
                                                        </p>
                                                    )
                                                        : ""
                                            }

                                        </div> */}
                            </Grid>
                            <Grid item md={12}>

                                <div style={{ display: "flex", gap: "5px", margin: "12px 0px", flexWrap: 'wrap' }}>
                                    {WorkingDays?.map((item, i) => {
                                        return (
                                            <Button
                                                key={i}
                                                variant="contained"
                                                onClick={() => {
                                                    setTimings(item);
                                                    setActiveDay(i);
                                                }}
                                                sx={{
                                                    minWidth: "50px",
                                                    minHeight: '30px',
                                                    border: '1px solid #E54290',
                                                    background:
                                                        activeDay === i && activeDay !== null
                                                            ? "#480223"
                                                            : item.today && activeDay === null
                                                                ? "#480223"
                                                                : !item.allDay && !item.hasOwnProperty("workingHours")
                                                                    ? "#fff"
                                                                    : "#fff",
                                                    padding: "0px 7px",
                                                }}
                                            >
                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        color: activeDay === i && activeDay !== null
                                                            ? "#fff"
                                                            : item.today && activeDay === null
                                                                ? "#fff"
                                                                : !item.allDay && !item.hasOwnProperty("workingHours")
                                                                    ? "#E54290"
                                                                    : "#E54290",
                                                        textTransform: "capitalize",
                                                    }}
                                                    style={customStyles}
                                                >
                                                    {item.day}
                                                </Typography>
                                            </Button>
                                        );
                                    })}
                                </div>

                                {timings && timings?.allDay ? (
                                    <Typography variant="body2" className="timing">
                                        All Day
                                    </Typography>
                                ) : timings?.allDay == false ? (
                                    <Typography variant="body2" className="timing">
                                        No Slot avalable
                                        {console.log(timings, "666666666")}
                                    </Typography>
                                ) : (
                                    timings?.workingHours &&
                                    timings?.workingHours?.map((item, ind) => {
                                        console.log(item, "");
                                        return (
                                            <Typography
                                                key={ind}
                                                mb={2}
                                                sx={{ marginBottom: "5px !important" }}
                                                variant="body2"
                                                className="timing"
                                            >
                                                {moment(item?.openingTime, "hh:mm").format("HH:mm A")}
                                                &nbsp;&nbsp;-&nbsp;&nbsp;
                                                {moment(item?.closingTime, "hh:mm").format("HH:mm A")}
                                            </Typography>
                                        );
                                    })
                                )}
                            </Grid>
                            {/* <Grid container>
                                        <Grid item md={4}>
                                            <div className='font-medium text-sm'>Availability Exceptions</div>
                                        </Grid>
                                        <Grid item md={8}>
                                            <div className='text-sm'>Sunday and Public Holidays</div>
                                        </Grid>
                                    </Grid> */}
                        </Grid>
                        <Grid container justifyContent={"center"} spacing={2} pt={3}>
                            <Grid item>
                                <ContainedButton
                                    variant="outlined"
                                    onClick={() => appointmentBooking()}
                                    startIcon={<img src={RequestIcon} width={'18px'} />}
                                    text="Request Appointment"
                                    // height="30px"
                                    fontSize={{ xs: "14px", sm: "14px", md: "14px", lg: "14px", }}
                                    borderRadius="10px"
                                    bgcolor="#E44190"
                                    color="#205072"

                                />
                            </Grid>

                        </Grid>
                    </Paper>
                    <Paper elevation={3} sx={{ px: 2, py: 1, mt: 2, mb: { xs: 3, sm: 3, md: 3, lg: 3 } }}>
                        <Grid container>
                            <Grid item xs={4} sm={2} md={5} mt={'10px'}>
                                <div className='flex gap-3  items-center' >
                                    <BiSolidPhone style={{ border: '1px solid #205072', padding: '1px', fontSize: '16px', borderRadius: '50%' }} />
                                    <span>Phone</span>
                                </div>
                            </Grid>
                            <Grid item xs={8} sm={10} md={7} mt={'10px'}>
                                {/* <ContactInfo contactDetails={deptData?.telecom?.[0]?.value} system={'phone'} /> */}
                                <div>{deptData?.telecom?.[0]?.value}</div>
                            </Grid>
                            <Grid item xs={4} sm={2} md={5} mt={'10px'}>
                                <div className='flex gap-3  items-center' >
                                    <CiMail style={{ border: '1px solid #205072', padding: '1px', fontSize: '16px', borderRadius: '50%' }} />
                                    <span>Email &nbsp;</span>
                                </div>
                            </Grid>
                            <Grid item xs={8} sm={10} md={7} mt={'10px'}>
                                <div>{deptData?.telecom?.[1]?.value}</div>
                            </Grid>
                            <Grid container alignItems={"center"} pt={1}>
                                <Grid item xs={4} sm={2} md={5} alignItems={"center"}>
                                    <span className='flex gap-3 items-center ml-4' >
                                        <div></div>
                                        <span>Follow &nbsp;</span>
                                    </span>
                                </Grid>
                                <Grid item xs={8} sm={10} md={7}>
                                    <Stack direction="row" spacing={2}>
                                        <FaXTwitter style={{ height: 24, width: 24, color: '#E44190' }} />
                                        <FaInstagram style={{ height: 24, width: 24, color: '#E44190' }} />
                                        <IoLogoYoutube style={{ height: 24, width: 24, color: '#E44190' }} />
                                        <FaLinkedin style={{ height: 24, width: 24, color: '#E44190' }} />

                                    </Stack>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}

export default DepartContacts