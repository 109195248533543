import { Box, Typography } from "@mui/material";
import React, { useState } from "react";

const Video = ({ docFeed }) => {
  const videoUrl = docFeed.sourceLink.replace(/,\s*$/, "");
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    // setExpanded(true);
  };
  return (
    <Box>
      <Typography sx={{ p: "0px 10px", fontSize: 14, fontWeight: 500 }}>

        {docFeed?.description ? (
          <>
            {expanded
              ? docFeed.description
              : `${docFeed.description.slice(0, 75)}`}
            {docFeed.description.length > 80 ? (
              <Typography
                component={"span"}
                onClick={handleExpandClick}
                sx={{ cursor: "pointer", fontSize: 14, ml: 1 }}
              >
                {expanded ? "...see less" : "...see more"}{" "}
              </Typography>
            ) : (
              ""
            )}
          </>
        ) : (
          <Box sx={{ height: { sm: 63, md: 42 } }}></Box>
        )}
      </Typography>
      <video
        controls
        width="100%"
      // style={{ height: 200 }}
      // sx={{ height: { xs: "500px", sm: "500px" } }}
      >
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </Box>
  );
};

export default Video;
