import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box, Paper, Typography, Grid, Button, Card, CardActions, CardContent, CardHeader, CardMedia, CircularProgress, Divider, IconButton, } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ArrowForward, KeyboardArrowLeft, KeyboardArrowRight, LensOutlined, LensRounded } from '@mui/icons-material';
import { locationJson } from '../../../shared/constants';
import axios from 'axios';
import URL from '../../../services/api_url';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { PostData } from './PostData';
import { Link } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import LatestUpdates from './LatestUpdates';



const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2,
        slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
    },
};

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
        carouselState: { currentSlide },
    } = rest;
    return (
        <div
            className="unique-button-group-container"
            style={{ position: "absolute", top: "0", right: "0" }}
        >
            <IconButton
                aria-label="arrow-left"
                className={`carousel-arrow-button ${currentSlide === 0 ? "disable" : ""
                    }`}
                onClick={() => previous()}
            >
                <KeyboardArrowLeft fontSize="inherit" />
            </IconButton>
            <IconButton
                aria-label="arrow-right"
                className="carousel-arrow-button"
                onClick={() => next()}
            >
                <KeyboardArrowRight fontSize="inherit" />
            </IconButton>
        </div>
    );
};

const CustomDot = ({ onMove, index, onClick, active }) => {
    return (
        <li
            style={{
                cursor: "pointer",
                color: active ? "#134265" : "#fff",
                marginTop: "10px",
            }}
            onClick={() => onClick()}
        >
            {active ? (
                <LensRounded style={{ fontSize: "14px" }} />
            ) : (
                <LensOutlined style={{ fontSize: "14px", color: "#000" }} />
            )}
        </li>
    );
};
const useStyles = makeStyles((theme) => ({
    tabsContainer: {
        borderRadius: '8px 8px 0 0',
    },
    tab: {
        minHeight: '40px',
        textTransform: 'capitalize !important',
        fontSize: '18px !important',
        '&.Mui-selected': {
            color: '#E54290 !important',
            fontWeight: 'bold',
            backgroundColor: '#FFFFFF',
            borderRadius: "20px",
            fontSize: '18px',
            lineHeight: '21px',
            fontWeight: 'normal'

        },
    },
    card: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 20px',
        gap: '10px',
        backgroundColor: '#FDF3F7',
        height: '84px',
        border: '0.5px solid #E54290',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'box-shadow 0.3s ease, border 0.3s ease',
        '&:hover': {
            border: 'none',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
        },
    },
}));


const HealthCompFeed = () => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [keyState, setKeyState] = React.useState("");
    const [posts, setPosts] = React.useState([]);
    const [loading, setLoading] = useState(false);
    const [load, setLoad] = useState(false);
    const limit = 25;

    useEffect(() => {
        postAPIS();
    }, []);


    const postAPIS = async (key) => {
        setLoading(true);

        try {
            let val = process.env.REACT_APP_BRANCH_PAGEID;
            const response = await axios.get(
                `${URL.feed}feed/getpostbypageid/${val}?limit=25&offset=0`
            );
            setPosts(response?.data?.results || []);
        } catch (error) {
            console.error("Error fetching posts:", error);
            // Handle error as needed
        } finally {
            setLoading(false);
        }
    };



    return (
        <div>
            <Typography
                level="h1"

                sx={{
                    textAlign: "center",
                    fontWeight: 500,
                    color: "#E54290",
                    fontSize: { xs: 24, md: 30 }
                }}
            >
                Health Information & Education
            </Typography>
            <Typography
                level="h2"
                sx={{
                    textAlign: "center",
                    //   fontWeight: "bold",
                    color: "#000000",
                    opacity: "1",
                    textTransform: "capitalize",
                    fontSize: { xs: 16, md: 18 },
                    fontWeight: 'lighter'
                }}
            >
                Advices & opinions from experts, find new events, articles & more
            </Typography>
            <Box sx={{ bgcolor: '#FDF3F7', p: '20px 0px', mt: 2 }}>

                <Box sx={{ p: { xs: "0px 10px ", sm: "0px", md: "0px" } }}>
                    <Box
                        sx={{
                            p: {
                                xs: "5px",
                                sm: "5px 20px",
                                md: "5px 80px",
                            },
                            mt: 1,
                        }}
                    >
                        <Card
                            sx={{
                                p: {
                                    xs: "0px",
                                    sm: "10px 10px",
                                    md: "5px 10px",
                                },
                            }}
                        >
                            <CardContent>
                                <div style={{ position: "relative" }}>
                                    <div style={{ display: "flex" }}>
                                        <Typography
                                            sx={{
                                                color: "#000",
                                                fontSize: 18,
                                                fontWeight: 500,
                                            }}
                                        >
                                            Timeline
                                        </Typography>
                                    </div>

                                    {loading ? (
                                        <CircularProgress sx={{ display: "block", mx: "auto", mt: 3 }} />
                                    ) : (

                                        posts && posts?.length > 0 ? (
                                            <Carousel
                                                swipeable={false}
                                                draggable={false}
                                                showDots={true}
                                                responsive={responsive}
                                                ssr={true}
                                                keyBoardControl={true}
                                                containerClass="carousel-container-3"
                                                removeArrowOnDeviceType={["tablet", "mobile"]}
                                                customButtonGroup={<ButtonGroup />}
                                                renderButtonGroupOutside={true}
                                                customDot={<CustomDot />}
                                                arrows={false}
                                            >
                                                {posts?.map((post, index) => (
                                                    <PostData feedData={post} key={index} />
                                                ))}
                                            </Carousel>
                                        ) : (
                                            <Typography sx={{ textAlign: "center", mt: 3 }}>
                                                No posts available.
                                            </Typography>
                                        )
                                    )}
                                </div>
                            </CardContent>
                        </Card>
                    </Box>
                </Box>
            </Box>
            <div className='mt-2'>
                <Link to="/homeposts">
                    <CardActions>
                        <Button variant="contained"
                            sx={{
                                backgroundColor: "transparent",
                                color: "#E54290",
                                boxShadow: "none",
                                textTransform: "capitalize",
                                marginX: "auto",
                                border: "1px solid #E54290",
                                alignItems: "center",
                                justifyContent: "center",
                                "&:hover": {
                                    backgroundColor: "transparent",
                                },
                            }}
                        >
                            Health Information
                            <ArrowForward />
                        </Button>
                    </CardActions>
                </Link>
            </div>
        </div>
    );
};

export default HealthCompFeed;

