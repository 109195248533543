import { Provider } from 'react-redux';
import RoutesPage from './routes/routes';
import store from './redux/store';
import './App.css';
import { BrowserRouter, useLocation } from 'react-router-dom';
import RenderOnAnonymous from './services/RenderOnAnonymous';
import RenderOnAuthenticated from './services/RenderOnAuthenticated';
import PrivateRoutesPage from './routes/PrivateRoutes';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import Chat from './components/chat/chat';
import SpeedDialing from './components/chat/speedDial';

// import {ThemeProvider} from '@emotion/react';
function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}
function App() {

  const theme = createTheme({
    typography: {
      fontFamily: 'Poppins',
    },
    components: {
      MuiCssBaseline: {
        // styleOverrides: `
        //   @font-face {
        //     font-family: 'Inter';
        //     font-style: normal;
        //     font-display: swap;
        //   }
        // `,
        styleOverrides: `
        @font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Regular.woff2') format('woff2'), /* adjust path and format as needed */
       url('./fonts/Poppins-Regular.woff') format('woff');
  font-weight: 400; /* Adjust weight if different variant */
  font-style: normal;
}`
      },
    },
  });

  return (
    <>
      <Provider store={store}>
        <HelmetProvider>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <ScrollToTop />

              <RenderOnAnonymous>
                <SpeedDialing />
                {/* <Chat /> */}
                {/* <FloatingWhatsApp phoneNumber='+918328067271' accountName="Paramitha Hospitals" /> */}
                <RoutesPage />
              </RenderOnAnonymous>
              <RenderOnAuthenticated>
                <PrivateRoutesPage />
              </RenderOnAuthenticated>
            </BrowserRouter>
          </ThemeProvider>
        </HelmetProvider>
      </Provider >
    </>
  );
}

export default App;
