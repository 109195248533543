
import React, { useCallback, useEffect, useState } from 'react'
import CommonDialog from '../atoms/commonModal'
import { Autocomplete, Box, Button, Card, Checkbox, Chip, CircularProgress, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Paper, Popper, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material'
import EmergencyImg from '../../assets/images/ambulance-icon.svg';
import { CustomTextField } from '../atoms/TextField';
import { MyLocation, Search } from '@mui/icons-material';
import ContainedButton, { OutlinedButton } from '../atoms/commonbutton';
import Location from './location';
import { useScroll } from 'react-spring';
import { IoIosCall } from 'react-icons/io';
import URL from '../../services/api_url';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import dayjs from 'dayjs';
import { Commoncalender } from '../atoms/commoncalender';
import * as Yup from 'yup';
import { localstore } from '../localstore/localstore';
import SelectOptions, { MenuOptions } from '../atoms/selectoptions';
import { getPatientInfo, getpatientDetails, getpatientDetailsMobile } from '../../redux/actions/actions';
import ErrorStatusMsg from '../atoms/ErrorStatusMsg';
import { useNavigate } from 'react-router-dom';
import BranchUrl from '../../services/api_branch_url';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import { debounce } from 'lodash';


function UrgentcareServices({ selectedbranch, phoneNumber, setPhoneNumber, handleClose, setShow, setVerifyOpen, handleCancel }) {
    const patientlists = useSelector((state) => state.paramitha.patientLists)
    const patientInformation = useSelector((state) => state.paramitha.patientInformation)
    const patientDetails = useSelector(state => state.paramitha.patientDetails)

    // console.log('phoneNumber', phoneNumber)
    // console.log('patientInformation', patientInformation)
    const totalentry = patientlists?.total
    const patients = patientlists?.entry
    const usertoken = localstore.getToken()
    const ibmId = localstore.getIbmId()
    const urls = BranchUrl()
    const profileOptions = ["My Self", "Other"];
    const options = patients && patients.length > 0 ? patients : profileOptions;
    // const profileOptions = ["My Self", "Other"];
    // const options = patients && patients.length > 0 ? ["Create New Patient", ...patients] : profileOptions;

    const [isNewPatient, setIsNewPatient] = useState(true);
    const [requestedPeriod, setRequestPeriod] = useState('')
    const [searchTerm, setSearchTerm] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const dispatch = useDispatch()
    const [searchResults, setSearchResults] = useState([]);
    const navigate = useNavigate()

    const [patientInfo, setPatientInfo] = useState('')
    const [gender, setGender] = useState('')
    const [birth, setBirth] = useState('')
    const [patientName, setPatientName] = useState('')
    const [patientId, setPatientId] = useState(null)
    // console.log(patientId, "patientId")
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({
        appointmentFor: '',
        patientName: '',
        gender: '',
        birth: '',
        phoneNumber: '',
        searchTerm: '',
        requestedPeriod: ''
    });
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [count, setCount] = useState(10); // Initial count set to 10
    const [scrollNum, setScrollNum] = useState(0)
    const selectedBranchId = selectedbranch?.id ? selectedbranch?.id : `${process.env.REACT_APP_PARAMITHA_ID}`

    const handlePatientDetails = (event, newValue) => {
        setPatientInfo(newValue);
        if (newValue && typeof newValue !== 'string') {
            dispatch(getPatientInfo(urls, newValue?.resource?.id, usertoken, ibmId));
        }
    };
    // const handlePatientDetails = (event, newValue) => {
    //     setPatientInfo(newValue);
    //     if (newValue) {
    //         dispatch(getPatientInfo(urls, newValue?.resource?.id, usertoken, ibmId))
    //     }
    // };


    const fetchSearchResults = async (value, currentPage, currentCount) => {
        if (value) {
            try {
                setLoading(true);
                const res = await axios.get(`${URL.relationship}ValueSet/$expand`, {
                    params: {
                        url: 'http://hl7.org/fhir/ValueSet/procedure-reason',
                        filter: value,
                        page: currentPage,
                        count: currentCount,
                    },
                });
                const newResults = res?.data?.expansion?.contains || [];
                setSearchResults(prevResults => {
                    const existingCodes = new Set(prevResults.map(result => result.code));
                    const filteredNewResults = newResults.filter(result => !existingCodes.has(result.code));
                    return [...prevResults, ...filteredNewResults];
                });
                setScrollNum(res?.data?.expansion?.total);
                setHasMore(newResults.length > 0); // If less than requested items, no more data
                setPage(prevPage => prevPage + 1);
                setCount(prevCount => prevCount + 10); // Increase count by 10 for each new page
            } catch (error) {
                console.log(error);
                setSearchResults([]); // Clear search results or handle error state

            } finally {
                setLoading(false);
            }
        }
    };

    const debouncedFetchSearchResults = useCallback(debounce((value, currentPage, currentCount) => {
        fetchSearchResults(value, currentPage, currentCount);
    }, 1000), []);

    const handleInputChange = (event, value) => {
        setSearchTerm(value);
        setPage(1); // Reset page number on new search
        setCount(10);
        debouncedFetchSearchResults(value, 1, 10);
        setSearchResults([]);
    };

    const handleSelectOption = (event, option) => {
        if (option && option.display) {
            setSelectedOptions((prevOptions) => [...prevOptions, option]);
        }
    };
    const handleDeleteChip = (option) => {
        setSelectedOptions(selectedOptions.filter(item => item !== option));
    }
    const handleChangeRequest = (e) => {
        const selectedValue = e.target.value;
        setRequestPeriod(selectedValue)
        let start, end;

        // Determine start and end times based on the selected value
        switch (selectedValue) {
            case 'Today-Morning':
                start = new Date().toISOString().split('T')[0] + 'T06:00:00Z';
                end = new Date().toISOString().split('T')[0] + 'T12:00:00Z';
                break;
            case 'Today-AfterNoon':
                start = new Date().toISOString().split('T')[0] + 'T12:00:00Z';
                end = new Date().toISOString().split('T')[0] + 'T18:00:00Z';
                break;
            case 'Today-Night':
                start = new Date().toISOString().split('T')[0] + 'T18:00:00Z';
                end = new Date().toISOString().split('T')[0] + 'T24:00:00Z';
                break;
            case 'Tomorrow-Morning':
                start = new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString().split('T')[0] + 'T06:00:00Z';
                end = new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString().split('T')[0] + 'T12:00:00Z';
                break;
            case 'Tomorrow-AfterNoon':
                start = new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString().split('T')[0] + 'T12:00:00Z';
                end = new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString().split('T')[0] + 'T18:00:00Z';
                break;
            case 'Tomorrow-Night':
                start = new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString().split('T')[0] + 'T18:00:00Z';
                end = new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString().split('T')[0] + 'T24:00:00Z';
                break;
            default:
                start = null;
                end = null;
        }

        // Set the selected period and clear any errors
        setStartDate(start);
        setEndDate(end);
        setErrors({ ...errors, requestedPeriod: '' });
    }

    useEffect(() => {
        if (phoneNumber) {
            dispatch(getpatientDetailsMobile(urls, phoneNumber, usertoken, ibmId))
        }
    }, [phoneNumber])

    useEffect(() => {
        if (patientInformation) {
            setPatientName(patientInformation?.name?.[0]?.text)
            setGender(patientInformation?.gender)
            setBirth(patientInformation?.birthDate)
            setPatientId(patientInformation?.id)
        }
    }, [patientInformation])
    const handleCloseData = () => {
        setPatientName('')
        setGender('')
        setBirth('')
        setSearchResults([])
        setSelectedOptions([])
        setRequestPeriod('')
        setSearchTerm('')
        setErrors({})
        handleCancel()
    }


    const handleSubmitdata = async () => {
        const newErrors = { ...errors };

        // Perform validation
        if (selectedOptions.length === 0) {
            newErrors.appointmentFor = 'Please select an appointment type.';
        } else {
            newErrors.appointmentFor = '';
        }
        if (!patientName) {
            newErrors.patientName = 'Please enter patient name.';
        } else {
            newErrors.patientName = '';
        }
        if (!gender) {
            newErrors.gender = 'Please select gender.';
        } else {
            newErrors.gender = '';
        }
        if (!birth) {
            newErrors.birth = 'Please select date of birth.';
        } else {
            newErrors.birth = '';
        }
        if (!requestedPeriod) {
            newErrors.requestedPeriod = 'Please select date and time for consultation.';
        } else {
            newErrors.requestedPeriod = '';
        }

        setErrors(newErrors);

        // If there are any errors, return without proceeding with form submission
        if (Object.values(newErrors).some(error => error !== '')) {
            return;
        }

        try {
            if (!patientlists?.entry || typeof patientInfo === 'string') {
                let patientData = {
                    "resourceType": "Patient",
                    "active": true,
                    "birthDate": moment(birth).format('YYYY-MM-DD'),
                    "gender": gender,
                    "name": patientName ? [
                        {
                            "use": "official",
                            "text": patientName,
                            "given": [patientName]
                        }
                    ] : [],
                    managingOrganization: {
                        reference: "Organization/" + selectedbranch?.id
                    },
                    "telecom": [
                        {
                            "use": "mobile",
                            "value": phoneNumber,
                            "system": "phone"
                        }
                    ]
                };
                let res;
                if (!ibmId && !usertoken) {
                    res = await axios.post(`${URL.paramitha_v4}parimitha/Create/Patient`, patientData, {
                        headers: {
                            'X-FHIR-TENANT-ID': 'parimitha',
                            'X-FHIR-DSID': `fhirparimitha`,
                            'realm': 'parimitha'
                        }


                    })
                    const pId = res?.data;
                    if (res.status === 200 || res.status === 201) {
                        handleSendRequest(pId)
                    }

                } else {
                    res = await axios.post(`${urls.person_tenant_orgId}Patient`, patientData, {
                        headers: {
                            'X-FHIR-TENANT-ID': 'parimitha',
                            'X-FHIR-DSID': `fhirparimitha`,
                            'realm': 'parimitha',
                            'Authorization': `Bearer ${usertoken}`
                        }
                    });
                    const pId = res.data
                    // const location = res.headers['location'];
                    // const pId = location.split('/').slice(-3, -2)[0];
                    if (res.status === 200 || res.status === 201) {

                        handleSendRequest(pId)
                    }
                }
            } else {
                handleSendRequest(patientInformation.id);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleSendRequest = async (pId) => {
        const data = {
            "resourceType": "ServiceRequest",
            "status": "active",
            "intent": "proposal",
            "subject": {
                "reference": `Patient/${pId}`
            },
            "category": [
                {
                    "coding": [
                        {
                            "system": "http://hl7.org/fhir/ValueSet/servicerequest-category",
                            "code": "409971007",
                            "display": "Emergency medical services"
                        }
                    ]
                }
            ],
            "code": {
                "coding": [
                    {
                        "system": "http://hl7.org/fhir/ValueSet/procedure-code",
                        "code": "134403003",
                        "display": "Urgent referral"
                    }
                ]
            },
            "performer": [
                {
                    "reference": `Organization/${selectedBranchId}`
                }
            ],
            "priority": "routine",
            "reasonCode": selectedOptions.map((option) => {
                return {
                    "coding": [
                        {
                            "system": option.system,
                            "code": option.code,
                            "display": option.display
                        }
                    ]
                };
            }),
            "occurrencePeriod": {
                "start": startDate,
                "end": endDate
            }
        };

        if (!ibmId && !usertoken) {

            try {
                await axios.post(`${urls.fhir_tenant_orgId}ServiceRequest`, data, {
                    headers: {
                        'X-FHIR-TENANT-ID': 'parimitha',
                        'X-FHIR-DSID': `fhirparimitha`,
                        'realm': 'parimitha',
                    }
                });

                alert('Appointment Request Sent Successfully');
                handleCloseData();
                navigate('/');
            } catch (error) {
                console.log(error);
            }
        } else {
            try {
                await axios.post(`${urls.fhir_tenant_orgId}ServiceRequest`, data, {
                    headers: {
                        'X-FHIR-TENANT-ID': 'parimitha',
                        'X-FHIR-DSID': `fhirparimitha`,
                        'realm': 'parimitha',
                        'Authorization': `Bearer ${usertoken}`
                    }
                });

                alert('Appointment Request Sent Successfully');
                handleCloseData();
                navigate('/');
            } catch (error) {
                console.log(error);
            }
        }
    };

    return (
        <>
            <DialogTitle sx={{ p: { xs: '0px 25px', md: "10px 40px 0px 40px" }, }}>
                <div className='text-center text:xl md:text-2xl font-medium'>Urgent Care Appointments</div>
                <div className='text-xs text-center font-medium text-[#E44190]'>
                    @ {selectedbranch?.name || 'Paramitha Hospitals'} </div>
                <hr style={{ margin: '10px 0px' }} />
            </DialogTitle>
            < DialogContent
                sx={{ p: { xs: '0px 25px', md: "10px 40px 0px 40px" } }}>
                <Grid container columnSpacing={10}  >
                    <Grid item xs={12} sm={12} md={6} >
                        <Box sx={{ mb: 1 }}>
                            <label>Appointment for</label>
                        </Box>

                        <Autocomplete
                            id="state-select"
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#59D8FA',
                                    },
                                },
                                ".MuiInputBase-root": {
                                    borderRadius: '7px',
                                },
                            }}
                            options={options}
                            size='small'
                            autoHighlight
                            // value={state}
                            onChange={handlePatientDetails}
                            fullWidth
                            getOptionLabel={(option) => {
                                if (typeof option === 'string') return option;
                                return option?.resource?.name?.[0]?.text || '';
                            }}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{ textTransform: 'capitalize' }} {...props}>
                                    {typeof option === 'string' ? option : option?.resource?.name?.[0]?.text || ''}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder='Appointment for'
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password',
                                    }}
                                />
                            )}
                        // PaperProps={{
                        //     disablePortal: true,
                        //     style: { zIndex: 999999 },
                        // }}
                        // PopperComponent={(props) => (
                        //     <Popper {...props} style={{ zIndex: 999999, width: '30%' }} />
                        // )}
                        />
                        {errors?.profileOptions && <div className="error">{errors.profileOptions}</div>}

                    </Grid>
                    <Grid item xs={12} sm={12} md={6} >
                        <div className=" flex items-center mt-1"></div>
                        <div className="flex items-center  md:mt-5 ">
                            <Checkbox checked={isNewPatient} />
                            <div className="text-sm">New to Paramitha Hospital, {selectedbranch?.name}</div>
                        </div>
                    </Grid>

                </Grid>
                <Grid container columnSpacing={10} rowSpacing={2} sx={{ paddingTop: '15px' }}>
                    <Grid item xs={12} sm={12} md={6} >
                        <div className='text-sm py-1'>Patient Name</div>
                        <CustomTextField placeholder="Patient Name" type='text' borderColor="#59D8FA" borderRadius="7px" value={patientName} onChange={(e) => {
                            // setErrors('')
                            setPatientName(e.target.value)
                            setErrors({ ...errors, patientName: '' })
                        }
                        }
                        />
                        {errors.patientName && <Typography sx={{ fontSize: '13px', color: '#D32F2F' }}>{errors.patientName}</Typography>}

                    </Grid>
                    <Grid item xs={12} sm={12} md={6} >
                        <div className='text-sm '> Select Gender</div>
                        <RadioGroup
                            value={gender}
                            name="gender"
                            onChange={(e) => {
                                setGender(e.target.value)
                                setErrors({ ...errors, gender: '' })
                            }}
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                        >
                            <FormControlLabel value="female" control={<Radio />} label="Female" />
                            <FormControlLabel value="male" control={<Radio />} label="Male" />
                            <FormControlLabel value="other" control={<Radio />} label="Other" />
                        </RadioGroup>

                        {errors.gender && <Typography sx={{ fontSize: '13px', color: '#D32F2F' }}>{errors.gender}</Typography>}


                    </Grid>

                </Grid>

                <Grid container columnSpacing={10} rowSpacing={2} sx={{ paddingTop: '15px' }}>
                    <Grid item xs={12} sm={12} md={6} >
                        <div className='text-sm '>Date of Birth</div>
                        {/* <TextField
                                type="date"
                                id="dateInput"
                                name="dob"
                                // value={values?.dob ? dayjs(values.dob).format('YYYY-MM-DD') : ''}
                                value={birth}
                                onChange={(e) => {
                                    setBirth(e.target.value)
                                    setErrors({ ...errors, birth: '' })
                                }
                                }
                                sx={{
                                    '.MuiOutlinedInput-input': {
                                        padding: '7px'
                                    }
                                }}
                                style={{
                                    border: "1px solid #c4c4c4",
                                    borderRadius: "7px",
                                    width: '100%',
                                    // padding: '7px',
                                    // zIndex: 9999999
                                }}
                            // Add other props and styles as needed
                            /> */}
                        <Commoncalender width="100%" value={dayjs(birth)} maxDate={dayjs()}

                            onChange={(e) => {
                                const selectedDate = e?.target?.value || e
                                setBirth(selectedDate);

                                setErrors({ ...errors, birth: '' })
                            }} />

                        {errors.birth && <Typography sx={{ fontSize: '13px', color: '#D32F2F' }}>{errors.birth}</Typography>}

                    </Grid>
                    <Grid item xs={12} sm={12} md={6} >
                        <div className='text-sm py-1'>Tell us Symptoms (Reason for Urgent Care)</div>
                        <Autocomplete
                            id="state-select"
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#59D8FA',
                                    },
                                },
                                ".MuiInputBase-root": {
                                    borderRadius: '7px',
                                },
                            }}
                            options={searchResults}
                            size='small'
                            autoHighlight
                            // value={searchTerm}
                            onChange={(event, value) => handleSelectOption(event, value)}
                            onInputChange={(event, value) => handleInputChange(event, value)} // Ensure correct parameter passing
                            fullWidth
                            getOptionLabel={(option) => option?.display || ''} // Ensure option.display is accessed correctly
                            renderOption={(props, option) => (
                                <Box component="li" sx={{ textTransform: 'capitalize' }} {...props}>
                                    {option.display}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder='Reason for urgent-care'
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password',
                                    }}
                                />
                            )}
                        />


                        <div className='mt-1 flex flex-wrap gap-1' >
                            {selectedOptions?.map(option => (
                                <Chip
                                    key={option?.code}
                                    label={option?.display}
                                    onDelete={() => handleDeleteChip(option)}
                                />
                            ))}
                        </div>

                        {/* <div>
                            <CustomTextField
                                placeholder="Search your Symptoms"
                                type='text'
                                borderColor="#59D8FA"
                                borderRadius="7px"
                                icon={<Search sx={{ color: '#0434BF' }} />}
                                onChange={handleChangeSearch}
                                value={searchTerm}
                            />
                            <div className='p-1' >

                                {searchResults.length > 0 &&
                                    <Card sx={{ p: "12px" }}>
                                        < InfiniteScroll
                                            hasMore={hasMore}
                                            next={fetchNextData}
                                            dataLength={searchResults.length}
                                            height={searchResults.length ? 222 : 'auto'}
                                            loader={<div>loading...</div>}
                                            style={{
                                                overflowY: 'scroll',
                                                '&::-webkit-scrollbar': {
                                                    display: 'none',
                                                }
                                            }}
                                        >
                                            {searchResults.map(option => (
                                                <div className='text-sm mt-1 cursor-pointer' key={option.code} onClick={() => handleSelectOption(option)}>
                                                    {option.display}
                                                </div>
                                            ))}
                                        </InfiniteScroll>
                                    </Card>
                                }
                            </div>
                            <div className='mt-1 flex flex-wrap gap-1' >
                                {selectedOptions.map(option => (
                                    <Chip
                                        key={option.code}
                                        label={option.display}
                                        onDelete={() => handleDeleteChip(option)}
                                    />
                                ))}
                            </div>
                            {errors.searchTerm && <Typography sx={{ fontSize: '13px', color: '#D32F2F' }}>{errors.searchTerm}</Typography>}

                        </div> */}


                        {/* <div className='mt-1'>
                                {contains.map((option, index) => (
                                    <Chip sx={{ mt: 1, ml: 1 }}
                                        key={index}
                                        label={option.display}
                                        onDelete={() => handleDelete(index)}
                                    />
                                ))}
                            </div> */}

                    </Grid>

                </Grid>

                <Grid container columnSpacing={10} rowSpacing={2} sx={{ paddingTop: '15px' }}>
                    <Grid item xs={12} sm={12} md={6}>
                        <div >
                            <div className="py-1 fntsz-14">Phone Number/Contact for Emergency</div>
                            <TextField
                                fullWidth
                                size="small"
                                type={'text'}
                                value={phoneNumber}
                                name="mobile"
                                // error={errors.mobile}
                                // onChange={()=>se}
                                placeholder="Input your 10 digit Phone number"
                                sx={{
                                    width: '100%',
                                    ".MuiInputBase-root": {
                                        borderRadius: '7px',
                                        // backgroundColor: 'whitesmoke',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#59D8FA',
                                        },
                                    },
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            +91 |
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </div>

                    </Grid>

                    <Grid item xs={12} sm={12} md={6} >


                        <div className='text-sm '>Date & Time Choice for consultation</div>
                        <Select
                            displayEmpty
                            fullWidth
                            sx={{ '.MuiSelect-select': { padding: '10px' }, mt: "5px", borderRadius: '7px' }}
                            name="requestedPeriod"
                            value={requestedPeriod}
                            onChange={handleChangeRequest}
                            placeholder='Select - Today, Tomorrow'
                            renderValue={(selected) => {
                                if (!selected) {
                                    return <span style={{ color: '#636363' }} className='text-sm'>Select - Today, Tomorrow</span>;
                                }
                                return selected;
                            }}
                            MenuProps={{ style: { zIndex: 999999 } }}
                        >
                            <MenuItem value={'Today-Morning'}>Today - Morning</MenuItem>
                            <MenuItem value={'Today-AfterNoon'}>Today - AfterNoon</MenuItem>
                            <MenuItem value={'Today-Night'}>Today - Night</MenuItem>
                            <MenuItem value={'Tomorrow-Morning'}>Tomorrow - Morning</MenuItem>
                            <MenuItem value={'Tomorrow-AfterNoon'}>Tomorrow - AfterNoon</MenuItem>
                            <MenuItem value={'Tomorrow-Night'}>Tomorrow - Night</MenuItem>
                        </Select>
                        {errors.requestedPeriod && <Typography sx={{ fontSize: '13px', color: '#D32F2F' }}>{errors.requestedPeriod}</Typography>}
                    </Grid>
                </Grid>
                <Box mt={3}>
                    {/* <hr /> */}
                </Box>
                {/* <Grid container justifyContent={"space-between"} px={3} mt={1}>
                                            <Button onClick={() => handleClose()} color='error' sx={{ textTransform: 'capitalize' }} >Cancel</Button>
                                            <Button type="submit" variant="contained" sx={{ width: "150px", textTransform: 'capitalize', bgcolor: '#1B5775', "&:hover": { bgcolor: '#1B5775' }, height: "33px", borderRadius: '7px' }}  >
                                                Send Request
                                            </Button>
                                        </Grid> */}
            </DialogContent>
            <Divider />
            <DialogActions>
                <Grid container justifyContent={"space-between"} px={3}>
                    <Button onClick={() => {
                        if (ibmId) {
                            handleCloseData()
                        } else {
                            handleCloseData()
                            setShow(1)
                        }
                    }
                    } color='error' sx={{ textTransform: 'capitalize' }} >Cancel</Button>
                    <Button type="submit" variant="contained" sx={{ width: "150px", textTransform: 'capitalize', bgcolor: '#1B5775', "&:hover": { bgcolor: '#1B5775' }, height: "33px", borderRadius: '7px' }} disabled={!requestedPeriod} onClick={handleSubmitdata} >
                        Send Request
                    </Button>
                </Grid>
            </DialogActions>
        </>
    )
}

export default UrgentcareServices;