import { useContext, useEffect, useState } from "react";
import { BsCheck } from "react-icons/bs";
import { BsCheckAll } from "react-icons/bs";


export function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export function Message(props) {
    // const { user } = useContext(AuthContext);
    const [getDate, setGetDate] = useState([])
    const [message, setMessage] = useState();
    const [user, setUser] = useState([]);

    const visitorId = localStorage.getItem('visitorId')
    const participantId = localStorage.getItem('participantId')

    useEffect(() => {
        setMessage(props.message)
        setGetDate(props.index)
        setUser(props.userData)
        // }
    }, [message])



    function formatMessageTimestamp(timestamp) {
        const date = new Date(timestamp);
        // console.log(date.getTime())
        // return date.toLocaleTimeString().slice(0, 4);
        return date.toTimeString().slice(0, 5);
    }
    function formatMessageDate(timestamp) {
        // const date = new Date(timestamp);
        // var formatted = date.format("dd/mm/yyyy hh:MM:ss");
        const formatedDate = new Date(timestamp).toLocaleString(
            "en-US",
            {
                month: "short",
                day: "2-digit",
                year: "numeric",
            }
        );
        // console.log(formatedDate)
        return formatedDate
    }

    // console.log(user!.userid , message?.to_user.userid )

    // { console.log(message) }
    return (
        <div>
            {/* <div className="text-center">{getDate}</div> */}
            <div
                className={classNames(
                    "mt-1 mb-1 flex text-ellipsis ",
                    participantId == message?.sender ? "justify-end" : "justify-start"
                )}
            >

                <div
                    className={classNames(
                        "relative max-w-xl rounded-lg px-2 py-1 text-gray-700 shadow ",
                        participantId == message?.sender ? "bg-black text-white" : "bg-white"
                    )}
                >
                    <div className="flex items-end">
                        <span className="block" style={{ wordBreak: 'break-word', whiteSpace: "pre-wrap" }}>{message?.content.trim()}</span>
                        <span
                            className="ml-2"
                            style={{
                                fontSize: "0.6rem",
                                lineHeight: "1rem"
                            }}
                        >
                            {formatMessageTimestamp(message?.timestamp)}
                        </span>
                        {/* <span className="justify-end pl-1 flex">
              {user!.userid === message?.from_user.userid ? message?.read === true ? <BsCheckAll style={{ fontSize: "20px", color: "#20b5e6" }} /> : <BsCheck style={{ fontSize: "20px" }} /> : ""}
            </span> */}
                    </div>
                </div>
            </div>

        </div>
    );
}