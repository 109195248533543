import React, { useEffect, useState } from 'react'
import CommonDialog from '../atoms/commonModal'
import { Autocomplete, Box, Button, Container, Avatar, DialogActions, DialogContent, DialogTitle, Divider, Grid, MenuItem, TextField, Typography, Checkbox, FormControlLabel } from '@mui/material';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import FormikCommoncalender, { Commoncalender } from '../atoms/commoncalender';
import { CustomTextField } from '../atoms/TextField';
import SelectOptions from '../atoms/selectoptions';
import { genderOptions } from '../../shared/constants';
import dayjs from 'dayjs';
import { relation } from '../atoms/RelationData';
import { ArrowForward, Logout, Person, Verified } from '@mui/icons-material';
import axios from 'axios';
import { getPatientdatafromibm, getPatientIdDetails, getRelationship, getSwitchProfiles } from '../../redux/actions/actions';
import { useDispatch, useSelector } from 'react-redux';
import { localstore } from '../localstore/localstore';
import Patientdata, { Ibmpatient } from '../atoms/patientdata';
import moment from 'moment';
import URL from '../../services/api_url';
import BranchUrl from '../../services/api_branch_url';
import ContainedButton, { OutlinedButton } from '../atoms/commonbutton';
import ReCAPTCHA from 'react-google-recaptcha';
import ErrorStatusMsg from '../atoms/ErrorStatusMsg';
import { debounce } from 'lodash';
import StringAvatar from '../atoms/stringAvatar';


function AddProfile({ open, setProfileOpen, selectedbranch, patientDetails }) {

    const dispatch = useDispatch()
    const ibmId = localstore.getIbmId()
    const fixedIbmId = localstore.getFixedIbmId()
    const usertoken = localstore.getToken()
    const [userRes, setUserRes] = useState(0)
    const [show, setShow] = useState(1)
    const [captchaToken, setCaptchToken] = useState('')
    const [otp, setOtp] = useState('')
    const [birthDate, setBirthDate] = useState('')
    const [patientId, setPatientId] = useState('')
    const [alertmessage, setAlertmessage] = useState('')
    const [alertstatus, setAlertstatus] = useState('')
    const [uploadpersonal, setUploadperonal] = useState(false)
    const [showOtp, setShowOtp] = useState(false)
    const relationship = useSelector(state => state.paramitha.relationship);
    const [name, setName] = useState("")
    const [filename, setFilename] = useState("")
    const [uploadprofessional, setUploadprofessional] = useState(false)
    const urls = BranchUrl()
    const [profileData, setProfileData] = useState([])
    const [filesize, setFilesize] = useState(0)
    const [filetype, setFiletype] = useState("")
    const [image, setImage] = useState("")
    const [phone, setPhone] = useState('')
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState({ open: false, msg: '' })

    const [checkedItems, setCheckedItems] = useState({});
    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);

    const signUpValidationSchema = yup.object().shape({
        firstName: yup.string().required('First Name is required'),
        lastName: yup.string().required('Last Name is required'),
        // relatedPerson: yup.string().required("Select Related Person is required"),
        gender: yup.string().required("Gender is required"),
        birthDate: yup.date().required('Date of Birth is required'),
        phone: yup.string()
            .required('Phone Number is required')
            .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits'),
    })

    const Authorization = {
        'X-FHIR-TENANT-ID': 'parimitha',
        'X-FHIR-DSID': `fhirparimitha`,
        'Authorization': `Bearer ${usertoken}`,
        'realm': 'parimitha'
    }


    // useEffect(() => {
    //     dispatch(getRelationship())
    // }, [])

    const handleChangePhone = (e, setFieldTouched, setFieldValue, setFieldError) => {
        const { value } = e.target;
        const numericValue = value.replace(/\D/g, ''); // Remove non-numeric characters
        if (numericValue.length <= 10) {
            setFieldValue('phone', numericValue);
            setFieldTouched('phone', true, false);
            setPhone(numericValue);

            if (numericValue) {
                debouncedCheckPhone(numericValue, setFieldError);
            } else {
                setFieldError('phone', '');
            }
        }
    };

    const debouncedCheckPhone = debounce((value, setFieldError) => {

        axios.get(`${urls.person_tenant_orgId}Patient?phone=${value}&active=true`, {
            headers: Authorization
        })
            .then(res => {
                setUserRes(res.data.total)
            })
            .catch(error => {
                if (error.response.status === 500) {
                    setFieldError('EmailId', "Internal Server Error");
                } else {
                    setFieldError('EmailId', error.response.data);
                }
            });
    }, 500); //

    const handleChangeOtp = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setOtp(inputValue.slice(0, 6));
    }


    const handleSendOtp = async () => {
        const data = {
            "messagetype": "OTP",
            "apptype": "Paramitha",
            "phoneNumber": `+91${phone}`,
            "email": "",
            'realm': 'parimitha'
        }

        if (captchaToken) {
            setLoading(true)
            axios.post(`${URL.sendOtp}`, data, {
                headers: {
                    "Authorization": `Bearer ${captchaToken}`,
                    "Usertoken": `${usertoken}`
                }
            }).then(res => {
                if (res.status === 200 || res.status === 201) {
                    setAlertmessage(res.data.msg)
                    setAlertstatus('success')
                    setShowOtp(true)
                    // setShow(2)
                }


            }).catch((error) => {
                console.log(error?.response?.data?.msg)
                const errorMessage = error.response ? error.response.data.msg : "An error occurred while sending OTP.";

                setAlertmessage(errorMessage)

                setAlertstatus('error')
            }).finally(() => {
                setLoading(false)
            })

        } else {
            setAlertmessage('Please verify the reCAPTCHA!');
            setAlertstatus('error');
        }
    }
    const handleSubmitData = async (values, resetForm) => {
        const dob = values.birthDate.toISOString();
        const momentDate = moment(dob);
        const birth = momentDate.format('YYYY-MM-DD');
        const patientdata = {
            "active": true,
            "address": [],
            "communication": [],
            "identifier": [],
            "gender": values.gender.toLowerCase() || '',
            "birthDate": birth || '',
            "name": [
                {
                    "use": "official",
                    "given": [
                        values.firstName || ''
                    ],
                    "family": values.lastName || '',
                    "text": values.firstName || ''
                }
            ],
            "photo": [],
            "resourceType": "Patient",
            "telecom": [
                {
                    "use": "mobile",
                    "value": `${values.phone || ''}`,
                    "system": "phone"
                }
            ],
            "managingOrganization": {
                "reference": `Organization/${selectedbranch?.id ? selectedbranch?.id : `${process.env.REACT_APP_PARAMITHA_ID}`}`
            }
        };

        if (userRes !== 0) {
            updatePatientDetails(patientdata)
        }
        else if (captchaToken && showOtp && otp) {
            const data = {
                "paramitha": "True",
                "phoneNumber": `+91${phone}`,
                "otp": otp,
                "email": "",
                'realm': 'parimitha'

            }
            setLoading(true)
            await axios.post(`${URL.verifyOtp}?verify=True`, data, {
                // await axios.post(`13.233.31.166/notify/verifyOtpparamitha`, data, {
                headers: {
                    "usertoken": `Bearer ${usertoken}`,
                }
            }).then(res => {
                if (res.status === 200 || res.status === 201) {
                    setAlertmessage('OTP verified successfully')
                    setAlertstatus('success')
                    updatePatientDetails(patientdata)
                }

            }).catch((err) => {
                setAlertmessage(err.message)
                setAlertstatus('error')
            }).finally(() => {
                setLoading(false)
            })
        } else {
            setAlertmessage('Please Verify Mobile Number')
            setAlertstatus('error')
        }
    }

    const updatePatientDetails = async (data) => {
        try {
            const personRes = await axios.post(`${urls.person_tenant_orgId}Patient`, data, {
                headers: Authorization
            });

            setPatientId(personRes.data)
            setShow(2)

            // if (personRes.status === 200 || personRes.status === 201) {
            //     const res = await axios.get(`${urls.person_tenant_orgId}Person`, {
            //         headers: Authorization
            //     });

            //     const newLink = {
            //         target: {
            //             reference: `Patient/${personRes.data}`,
            //         }
            //     };

            //     // Add the new link property
            //     patientDetails = {
            //         ...patientDetails,
            //         link: patientDetails.link ? [...patientDetails.link, newLink] : [newLink]
            //     };

            //     // Update Person details
            //     await axios.put(`${urls.person_tenant_orgId}Person/${ibmId}`, patientDetails, {
            //         headers: Authorization
            //     });
            //     setShow(2)
            //     // // Dispatch action to get switch profiles
            //     // dispatch(getSwitchProfiles(urls, fixedIbmId, usertoken));
            //     // resetForm();
            //     // setProfileOpen(false);
            // }
        } catch (err) {
            setAlertmessage(err.message);
            setAlertstatus('error');
        }
    }
    //     console.log(values, "hello")
    //     const dob = values.birthDate.toISOString();
    //     const momentDate = moment(dob);
    //     const birth = momentDate.format('YYYY-MM-DD');
    //     const relPerson = JSON.parse(values.relatedPerson)
    //     let data = {
    //         firstname: values.firstName,
    //         lastname: values.lastName,
    //         dateofbirth: birth,
    //         relation: values.relatedPerson.display,
    //         gender: values.gender

    //     };
    //     let relateddata = data.lastname
    //         ? {
    //             ...Ibmpatient,
    //             gender: data.gender.toLowerCase(),
    //             birthDate: data.dateofbirth,
    //             resourceType: 'Patient',
    //             name: [
    //                 {
    //                     ...Ibmpatient.name[0],
    //                     given: [data.firstname],
    //                     family: data.lastname,
    //                     text: data.firstname
    //                 },
    //             ],
    //             telecom: [],
    //             photo: image?.length > 0 ? [{ url: image }] : [],
    //         }
    //         : {
    //             ...Ibmpatient,
    //             gender: data.gender.toLowerCase(),
    //             birthDate: data.dateofbirth,
    //             resourceType: 'Patient',
    //             name: [{ ...Ibmpatient.name[0], given: [data.firstname], text: data.firstname }],
    //             telecom: [],
    //             photo: image?.length > 0 ? [{ url: image }] : [],
    //         };

    //     handleRelatedPerson(ibmId, relateddata, relPerson, resetForm);

    // };


    // const handleRelatedPerson = async (ibmId, relateddata, relPerson, resetForm) => {
    //     let d = { ...relateddata };

    //     let data = {
    //         ...d,
    //         resourceType: 'RelatedPerson',
    //         patient: {
    //             reference: `Patient/${ibmId}`,
    //         },
    //         relationship: [
    //             {
    //                 coding: [
    //                     {
    //                         system: relPerson.system,
    //                         code: relPerson.code,
    //                         display: relPerson.display,
    //                     },
    //                 ],
    //             },
    //         ],
    //         telecom: patientDetails.telecom,
    //     };

    //     try {
    //         // Post RelatedPerson data
    //         const res = await axios.post(`${urls.paramitha_v1}RelatedPerson`, data, {
    //             headers: {
    //                 'X-FHIR-TENANT-ID': selectedbranch?.tenant,
    //                 'X-FHIR-DSID': `fhir${selectedbranch?.tenant}`,
    //                 'realm': selectedbranch?.tenant,
    //                 'Authorization': `Bearer ${usertoken}`
    //             },
    //         });

    //         let du = res.data;

    //         // Get Person details
    //         const personRes = await axios.get(`${urls.person_tenant_orgId}Person/${ibmId}`, {
    //             headers: {
    //                 'X-FHIR-TENANT-ID': selectedbranch?.tenant,
    //                 'X-FHIR-DSID': `fhir${selectedbranch?.tenant}`,
    //                 'Authorization': `Bearer ${usertoken}`,
    //                 'realm': selectedbranch?.tenant
    //             }
    //         });

    //         const newLink = {
    //             target: {
    //                 reference: `RelatedPerson/${du}`,
    //             }
    //         };

    //         // Add the new link property
    //         patientDetails = {
    //             ...patientDetails,
    //             link: patientDetails.link ? [...patientDetails.link, newLink] : [newLink]
    //         };

    //         // Update Person details
    //         await axios.put(`${urls.person_tenant_orgId}Person/${ibmId}`, patientDetails, {
    //             headers: {
    //                 'X-FHIR-TENANT-ID': selectedbranch?.tenant,
    //                 'X-FHIR-DSID': `fhir${selectedbranch?.tenant}`,
    //                 'realm': selectedbranch?.tenant,
    //                 'Authorization': `Bearer ${usertoken}`
    //             },
    //         });

    //         // Dispatch action to get switch profiles
    //         dispatch(getSwitchProfiles(urls, fixedIbmId, usertoken));
    //         resetForm();
    //         setProfileOpen(false);

    //     } catch (err) {
    //         if (err) {
    //             setAlertmessage(err.message);
    //             setAlertstatus('error');
    //         }
    //     }



    // const addPatienttoIbm = (du, d, resetForm) => {



    // };


    // const handleRelatedPerson = async (ibmId, relateddata, relPerson, resetForm) => {

    //     let d = { ...relateddata };
    //     // console.log(d, "relateddata")
    //     let data = {
    //         ...d,
    //         resourceType: 'RelatedPerson',
    //         patient: {
    //             reference: `Patient/${ibmId}`,
    //         },
    //         relationship: [
    //             {
    //                 coding: [
    //                     {
    //                         system: relPerson.system,
    //                         code: relPerson.code,
    //                         display: relPerson.display,
    //                     },
    //                 ],
    //             },
    //         ],
    //         telecom: patientDetails.telecom,
    //     };
    //     // console.log(data, "dataa")

    //     axios
    //         .post(`${urls.paramitha_v1}RelatedPerson`, data, {
    //             headers: {
    //                 'X-FHIR-TENANT-ID': selectedbranch?.tenant,
    //                 'X-FHIR-DSID': `fhir${selectedbranch?.tenant}`,
    //                 'realm': selectedbranch?.tenant,
    //                 'Authorization': `Bearer ${usertoken}`
    //             },
    //         })
    //         .then(async res => {
    //             let du = res.data
    //             await addPatienttoIbm(du, d, resetForm)

    //         })
    //         .catch(err => {
    //             if (err) {
    //                 setAlertmessage(err.message);
    //                 setAlertstatus('error');
    //             }
    //         })

    // }


    // const addPatienttoIbm = (du, d, resetForm) => {
    //     let secondarydata = {
    //         ...d,
    //         resourceType: 'Patient',
    //         telecom: patientDetails.telecom,
    //     };
    //     secondarydata = {
    //         ...secondarydata,
    //         link: [
    //             {
    //                 other: {
    //                     reference: `RelatedPerson/${du}`,
    //                 },
    //                 type: 'refer',
    //             },
    //         ],
    //         managingOrganization: {
    //             "reference": `Organization/${selectedbranch?.id ? selectedbranch?.id : `${process.env.REACT_APP_PARAMITHA_ID}`}`
    //         },
    //     }
    //     axios.post(`${urls.person_tenant_orgId}Patient`, secondarydata, {
    //         headers: {
    //             'X-FHIR-TENANT-ID': selectedbranch?.tenant,
    //             'X-FHIR-DSID': `fhir${selectedbranch?.tenant} `,
    //             'realm': selectedbranch?.tenant,
    //             'Authorization': `Bearer ${usertoken} `
    //         },
    //     })
    //         .then(async res => {
    //             dispatch(getSwitchProfiles(urls, fixedIbmId, usertoken));
    //             resetForm()
    //             setProfileOpen(false)
    //         })

    // };



    const handleRecaptchaChange = (value) => {
        setCaptchToken(value)
        setAlertmessage('')
    };

    // console.log(selectedItems, "select")
    const handleCheckboxChange = (index, item) => {
        const isChecked = !checkedItems[index];
        setCheckedItems(prev => ({
            ...prev,
            [index]: isChecked,
        }));

        setSelectedItems(prev => {
            if (isChecked) {
                // Add item to the selectedItems array
                return [...prev, item];
            } else {
                // Remove item from the selectedItems array
                return prev.filter(selectedItem => selectedItem !== item);
            }
        });
    };

    const handleSelectAllChange = () => {
        const newSelectAll = !selectAll;
        setSelectAll(newSelectAll);
        setCheckedItems(
            profileData.reduce((acc, _, index) => {
                acc[index] = newSelectAll;
                return acc;
            }, {})
        );

        setSelectedItems(
            newSelectAll ? profileData : []
        );
    };

    const handleOnChangeDate = (newValue) => {
        setBirthDate(newValue?.format("YYYY-MM-DD"))
    }
    const handleSearchPatients = async () => {
        try {
            const res = await axios.get(`${urls.person_tenant_orgId}Patient?birthdate=${birthDate}&phone=${phone}`, {
                headers: Authorization
            })

            setProfileData(res?.data?.entry || [])
        } catch (error) {
            console.log(error)
        }
    }

    const handleCloseShow1 = () => {
        setProfileOpen(false)
        setShowOtp(false)
        setCaptchToken('')
        setAlertmessage('')
        setOtp('')
        setUserRes(0)
    }
    const handleCloseShow2 = () => {
        handleCloseShow1()
        setBirthDate('')
        setSelectedItems([])
        setProfileData([])
        setCheckedItems({})
        setSelectAll(false)
        setShow(1)

    }

    const handleLinkPatient = async () => {
        try {
            const res = await axios.get(`${urls.person_tenant_orgId}Person/${ibmId}`, {
                headers: Authorization
            });

            // Use let instead of const to allow reassignment
            let patientDetails = res?.data;


            const newLinks = selectedItems.map(person => ({
                target: {
                    reference: `Patient/${person.resource.id}`
                }
            }));

            // Ensure the link field exists and is an array
            if (!patientDetails.link) {
                patientDetails.link = [];
            }

            // Add the new links to the existing links
            // patientDetails.link = [...patientDetails.link, ...newLinks];
            // Update Person details
            const existingPatientIds = new Set(patientDetails.link.map(link => link.target.reference));

            // Filter out new links that are already present in existing links
            const uniqueNewLinks = newLinks.filter(link => !existingPatientIds.has(link.target.reference));

            // Add the unique new links to the existing links
            patientDetails.link = [...patientDetails.link, ...uniqueNewLinks];

            await axios.put(`${urls.person_tenant_orgId}Person/${ibmId}`, patientDetails, {
                headers: Authorization
            }).then(res => {
                dispatch(getPatientdatafromibm(urls, usertoken, ibmId))
                handleCloseShow2()
            })


            // Uncomment and complete as needed
            // dispatch(getSwitchProfiles(urls, fixedIbmId, usertoken));
            // resetForm();
            // setProfileOpen(false);
        } catch (error) {
            console.error("Error handling patient link:", error);
        }
    }


    return (
        < CommonDialog maxWidth={show === 1 ? 'md' : 'md'} onClose={() => setProfileOpen(false)}
            open={open}
        >

            <DialogTitle sx={{ p: { xs: '0px 25px', md: "10px 40px 0px 40px" }, }}>
                <div className='text-center text-xl md:text-2xl font-medium'>Add New Profile</div>
                <div className='text-xs text-center font-normal text-[#E44190]'>@ {selectedbranch?.name} </div>
                <hr style={{ margin: '10px 0px' }} />
            </DialogTitle>
            {
                show === 1 ?
                    <Formik
                        validationSchema={signUpValidationSchema}
                        initialValues={{ firstName: "", lastName: "", birthDate: "", gender: "", phone: '' }}
                        enableReinitialize={true}
                        onSubmit={async (values, { resetForm }) => {
                            handleSubmitData(values, resetForm)
                        }}
                    >
                        {
                            ({
                                handleSubmit,
                                isValid,
                                values,
                                errors,
                                handleChange,
                                handleBlur,
                                touched,
                                setFieldValue,
                                resetForm,
                                setFieldTouched,
                                setFieldError

                            }) => (
                                <>
                                    <DialogContent sx={{ p: '10px 40px 20px' }}>

                                        <Box >

                                            <Grid container columnSpacing={3} rowSpacing={2} pt={'8px'} >
                                                <Grid item xs={12} md={6}>
                                                    <Box >
                                                        <label style={{ fontWeight: 500, fontSize: '14px' }}>First Name</label>
                                                    </Box>
                                                    <CustomTextField
                                                        placeholder="Enter Your Full Name here"
                                                        type={'text'}
                                                        borderColor="#59D8FA"
                                                        value={values.firstName}
                                                        borderRadius="7px"
                                                        // onChange={(event) => { handleChangeReg(event.target.name, event.target.value) }} // Corrected: passing event object
                                                        onChange={handleChange('firstName')}
                                                        handleBlur={handleBlur('firstName')}
                                                        error={touched.firstName && Boolean(errors.firstName)}
                                                    />
                                                    {errors.firstName && touched.firstName && (
                                                        <Typography sx={{ color: '#D32F2F', fontSize: '14px' }}>
                                                            {touched.firstName && errors.firstName}
                                                        </Typography>
                                                    )}
                                                </Grid>



                                                <Grid item xs={12} md={6}>
                                                    <Box >
                                                        <label style={{ fontWeight: 500, fontSize: '14px' }}>Last Name</label>
                                                    </Box>
                                                    <CustomTextField
                                                        placeholder="Enter Your Full Name here"
                                                        type={'text'}
                                                        borderColor="#59D8FA"
                                                        value={values.lastName}
                                                        // onChange={(event) => { handleChangeReg(event.target.name, event.target.value) }} // Corrected: passing event object
                                                        onChange={handleChange('lastName')}
                                                        handleBlur={handleBlur('lastName')}
                                                        borderRadius="7px"
                                                        error={touched.lastName && Boolean(errors.lastName)}
                                                    />
                                                    {errors.lastName && touched.lastName && (
                                                        <Typography sx={{ color: '#D32F2F', fontSize: '14px' }}>
                                                            {touched.lastName && errors.lastName}
                                                        </Typography>
                                                    )}

                                                </Grid>

                                                <Grid item xs={12} md={6}>
                                                    <Box >
                                                        <label style={{ fontWeight: 500, fontSize: '14px' }}>Gender</label>
                                                    </Box>

                                                    <SelectOptions borderRadius="7px" value={values.gender} defaultName="Select your Gender" onChange={(event) => setFieldValue('gender', event.target.value)} error={touched.gender && Boolean(errors.gender)}>
                                                        {genderOptions.map(option => (
                                                            <MenuItem key={option} value={option}>{option}</MenuItem>
                                                        ))}
                                                    </SelectOptions>
                                                    {errors.gender && touched.gender && (
                                                        <Typography sx={{ color: '#D32F2F', fontSize: '14px' }}>
                                                            {touched.gender && errors.gender}
                                                        </Typography>
                                                    )}

                                                </Grid>
                                                <Grid item xs={12} md={6}
                                                    sx={{
                                                        ".MuiStack-root": {
                                                            paddingTop: '0px'
                                                        },
                                                    }}>
                                                    <Box>
                                                        <label style={{ fontWeight: 500, fontSize: '14px' }}>Date of Birth</label>
                                                    </Box>

                                                    <Field
                                                        name="birthDate"
                                                        component={FormikCommoncalender}
                                                        maxDate={dayjs()}
                                                        width="100%"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}
                                                    sx={{
                                                        ".MuiStack-root": {
                                                            paddingTop: '0px'
                                                        },
                                                    }}>
                                                    <Box>
                                                        <label style={{ fontWeight: 500, fontSize: '14px' }}>Phone Number</label>
                                                    </Box>
                                                    <CustomTextField
                                                        placeholder="Phone Number"
                                                        type="text"
                                                        borderColor="#59D8FA"
                                                        value={values.phone}
                                                        // disabled={userRes !== 0}
                                                        padding={"0px"}
                                                        onChange={(e) => handleChangePhone(e, setFieldTouched, setFieldValue, setFieldError)}
                                                        onBlur={handleBlur('phone')}
                                                        borderRadius="7px"
                                                        icon={
                                                            captchaToken && userRes === 0 ? (
                                                                <ArrowForward
                                                                    sx={{
                                                                        backgroundColor: "#1B5775",
                                                                        fontSize: '40px',
                                                                        color: "white",
                                                                        borderRadius: "0px 7px 7px 0px",
                                                                    }}
                                                                    onClick={handleSendOtp}
                                                                />
                                                            ) : (
                                                                // <Verified />
                                                                null
                                                            )
                                                        }
                                                        error={touched.phone && Boolean(errors.phone)}
                                                    />

                                                    {errors.phone && touched.phone && (
                                                        <Typography sx={{ color: '#D32F2F', fontSize: '14px' }}>
                                                            {touched.phone && errors.phone}
                                                        </Typography>
                                                    )}
                                                    {userRes === 0 &&
                                                        <Box sx={{ mt: 4, transform: 'scale(0.8)', transformOrigin: '0 0' }}>

                                                            <ReCAPTCHA
                                                                sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`}
                                                                onChange={handleRecaptchaChange}
                                                                size="normal"
                                                            // style={{ transform: 'scale(0.9)' }}
                                                            />
                                                        </Box>
                                                    }
                                                </Grid>

                                                {showOtp && captchaToken &&

                                                    < Grid item xs={12} md={6}>

                                                        <Box >
                                                            <label style={{ fontWeight: 500, fontSize: '14px' }}>Verify OTP</label>
                                                        </Box>
                                                        <CustomTextField
                                                            placeholder="Enter OTP"
                                                            type={'text'}
                                                            borderColor="#59D8FA"
                                                            value={otp}
                                                            onChange={handleChangeOtp}
                                                            borderRadius="7px"
                                                        />

                                                    </Grid>
                                                }

                                            </Grid>
                                        </Box>

                                    </DialogContent >
                                    <Divider />
                                    <div className='text-center'><ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} /></div>
                                    <DialogActions sx={{ m: "5px 20px" }}>
                                        <Grid container justifyContent={"space-between"} px={3}>
                                            <Button color='error' sx={{ textTransform: 'capitalize' }} onClick={() => {
                                                resetForm();
                                                handleCloseShow1()

                                            }}>Cancel</Button>
                                            <Button type="submit" variant="contained" sx={{ width: "150px", textTransform: 'capitalize', bgcolor: '#1B5775', "&:hover": { bgcolor: '#1B5775' }, height: "33px", borderRadius: '7px' }} onClick={handleSubmit} >
                                                {/* Submit */}{showOtp ? 'Verify OTP ' : 'Add Profile'}
                                            </Button>
                                        </Grid>
                                    </DialogActions>
                                </>

                            )}
                    </Formik> : show === 2 ? <>
                        <DialogContent sx={{ p: '10px 40px 20px' }}>
                            {/* <Container> */}
                            <Grid container spacing={3} alignItems={'center'}>

                                <Grid item xs={12} md={6}>
                                    <Typography variant='h6'>Profiles</Typography>


                                </Grid>
                                <Grid item xs={12} md={6}
                                    sx={{
                                        ".MuiStack-root": {
                                            paddingTop: '0px'
                                        },
                                        display: 'flex', alignItems: 'center', gap: '10px'
                                    }}>

                                    <Commoncalender width="100%" value={dayjs(birthDate)} onChange={handleOnChangeDate} maxDate={dayjs()} />
                                    <ContainedButton text="Search" width="100%" onClick={handleSearchPatients} />
                                </Grid>

                            </Grid>
                            <Box sx={{ mt: 1 }}>

                                <FormControlLabel
                                    control={<Checkbox checked={selectAll}
                                        onChange={handleSelectAllChange}
                                        color="primary" />}
                                    label="Select All"
                                />
                                {profileData.length > 0 ? profileData?.map((person, index) => (
                                    <div className='cursor-pointer' key={index}>
                                        <Grid container mt={1} alignItems="center">
                                            <Grid item md={10.8} className="flex items-center" gap={"20px"}>
                                                <Avatar {...StringAvatar(`${person?.resource?.name?.[0]?.given} ${person?.resource?.name?.[0]?.family}`)} />
                                                <div>
                                                    <Typography fontSize={'12px'} fontWeight={500} textTransform={'capitalize'}>
                                                        {person?.resource?.name?.[0]?.given} {person?.resource?.name?.[0]?.family}
                                                    </Typography>
                                                    <Typography fontSize={'11px'}>Not Verified</Typography>
                                                </div>
                                            </Grid>
                                            <Grid item md={1.2}>
                                                <Checkbox
                                                    checked={checkedItems[index] || false}
                                                    onChange={() => handleCheckboxChange(index, person)}
                                                    color="primary"
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                )) : <div className='text-center mt-8 text-lg h-32'>Please Search Patient Details</div>}
                            </Box>
                            {/* </Container> */}
                        </DialogContent>

                        <Divider />
                        {/* <div className='text-center'><ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} /></div> */}
                        <DialogActions sx={{ m: "5px 20px" }}>
                            <Grid container justifyContent={"space-between"} px={3}>
                                <Button color='error' sx={{ textTransform: 'capitalize' }} onClick={() => {
                                    handleCloseShow2()

                                }}>Cancel</Button>
                                <Button type="submit" variant="contained" sx={{ width: "150px", textTransform: 'capitalize', bgcolor: '#1B5775', "&:hover": { bgcolor: '#1B5775' }, height: "33px", borderRadius: '7px' }} onClick={handleLinkPatient}>Link
                                </Button>
                            </Grid>
                        </DialogActions>
                    </> : null
            }


        </CommonDialog >
    )
}

export default AddProfile