import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { IoSearchOutline } from "react-icons/io5";
import { Card, CardContent, Chip, Typography } from "@mui/material";
import { RxCrossCircled } from "react-icons/rx";
import "./headerstyles.css";
import axios from "axios";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import URL from "../../services/api_url";
import { localstore } from "../localstore/localstore";

const ChipData = ({ label, handleFilter, value, a }) => {
  return value ? (
    <Chip
      label={label}
      sx={{
        marginBottom: "10px",
        boxShadow: 1,
        border: "none",
        fontSize: "1rem",
      }}
      size="large"
      color={"secondary"}
      variant={"filled"}
      onDelete={() => handleFilter("")}
    />
  ) : (
    <Chip
      label={label}
      sx={{
        marginBottom: "10px",
        boxShadow: 1,
        border: "none",
        fontSize: "1rem",
        fontWeight: 600

      }}
      size="large"
      // color={"default"}
      variant={"outlined"}
      onClick={(e) => handleFilter(a)}
    />
  );
};

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: " #e1f3ff",

  "&:hover": {
    backgroundColor: "#e1f3ff",
  },
  marginRight: theme.spacing(2),
  width: "98%",
  [theme.breakpoints.up("lg")]: {
    marginLeft: theme.spacing(3),
  },
  [theme.breakpoints.up("lg")]: {
    width: "60%",
  },
}));

const SearchComp = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#4f5b66;",
  // '&:hover': {
  //     backgroundColor: '#e1f3ff',
  // },
  marginRight: "10px",
  marginLeft: "10px",
  width: "98%",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  // padding: theme.spacing(0, 1.5),
  paddingTop: 0,
  paddingLeft: 8,
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // ml: 4,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "black",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    // backgroundColor: "#fff",
    [theme.breakpoints.up("md")]: {
      width: "inherit",
      color: "black!important",
    },
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      color: "#E44190",
    },
  },
}));

const SearchCard = styled(Card)({
  width: "40dvw",
  boxShadow: "none",
  marginTop: "-5px",
  backgroundColor: "#fff",
});

export default function SearchComponent(props) {
  const [searchOptions, setSearchOptions] = React.useState([]);
  const [name, setName] = React.useState("");
  const [optn, setOptn] = React.useState([]);
  const [key, setKey] = useState("");
  const [load, setLoad] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [openCard, setOpenCard] = useState(false);
  const [searchData, setSearchData] = useState(false);
  const usertoken = localstore.getToken()
  const selectBranchInfo = useSelector((state) => state.SelectedBranch.selectedbranch);
  const navigate = useNavigate()

  const handleMouseEnter = () => {
    setIsExpanded(true);
  };

  const handleMouseLeave = () => {
    setIsExpanded(false);
  };

  const handleSearchValue = (event) => {
    const value = event.target.value;
    setSearchText(value);

    if (value === "") {
      setSearchOptions([]);
      setOptn([])
      setKey('')
    } else {
      handleSearch(value);
    }
  };
  const handleSearch = debounce((name) => {
    if (name) {
      axios.get(`${URL.search}/${selectBranchInfo?.tenant || 'paramitha'}/${name}`).then((res) => {
        console.log(res)
        setSearchOptions(res?.data)
        setOptn(res.data)
      }).catch((err) => {

      })
    }

  }, 2000);

  const handleFilter = (value) => {
    // console.log(value, "123456789")
    setKey(value);
    let options = [...optn];
    if (value !== "") {
      options = options.filter((val) => val.type === value);
    }
    setSearchOptions(options);

    // setKey(value);
    // if (value) {
    //   setLoad(true);
    //   axios.get(`${URL.search}/${selectBranchInfo?.tenant || 'paramitha'}/${searchText}?type=${value}`)
    //     .then((res) => {
    //       setSearchOptions(res?.data);
    //     })
    //     .catch((err) => {
    //       console.error(err);
    //     }).finally(() => {
    //       setLoad(false)
    //     })
    // }
    // setKey(value);
    // let options = [...optn];
    // if (value !== "") {
    //   options = options.filter((val) => val.type === value);
    //   setSearchOptions(options);
    // } else {
    //   searchChange(name);
    // }
  };

  const handleOpenCard = () => {
    setOpenCard(true);
  };

  const handleCloseCard = () => {
    setOpenCard(false);
    setIsExpanded(false);
    setSearchOptions([])
    setOptn([])
    setName('')
    setKey('')
    setSearchText('')
    setLoad(false)
  };

  return (
    <div
      style={{
        flexGrow: 1,
        height: 40,
        opacity: 1,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Search
        sx={{
          width: props.width,
          boxShadow: 5,
          padding: isExpanded | openCard ? "5px" : 0,
        }}
        id="demo-positioned-button"
        // aria-controls={open1 ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        // aria-expanded={open1 ? 'true' : undefined}
        style={{
          width: isExpanded || openCard ? "40dvw" : "40px",
          borderRadius: isExpanded | openCard ? "10px" : "60%",
          backgroundColor: "#fff",
          transition: isExpanded && "width 1s ease",
        }}
      >
        <SearchIconWrapper
          style={{ height: isExpanded | openCard ? "80%" : "100%" }}
        >
          <IoSearchOutline
            style={{
              fontSize: "24px",
              color: openCard ? "#1D5775" : "#E480B0",
            }}
          />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder={
            openCard ? "Type your Search" : "Search Doctors, Diseases, Services"
          }
          inputProps={{ "aria-label": "search" }}
          onFocus={handleOpenCard}
          endAdornment={
            isExpanded && (
              <Box sx={{ marginRight: 1, cursor: 'pointer' }}>
                <RxCrossCircled
                  onClick={handleCloseCard}
                  fontSize={"30px"}
                />
              </Box>
            )
          }
          onChange={handleSearchValue}
          value={searchText}
        // disabled
        />
      </Search>
      {openCard && (
        <SearchCard className="shadow-2xl">
          <CardContent className="shadow-2xl">
            <>
              <Stack
                direction="row"
                spacing={1}
                sx={{ mt: "10px", ml: "10px", flexWrap: "wrap" }}
                alignItems={"center"}
              >
                <Typography sx={{ mr: "10px", fontSize: "16px" }}>
                  Filter
                </Typography>
                <ChipData
                  label="Doctors"
                  handleFilter={(value) => handleFilter(value)}
                  value={key === "people" ? true : false}
                  a={"people"}
                />
                <ChipData
                  label="Services"
                  handleFilter={(value) => handleFilter(value)}
                  value={key === "speciality" ? true : false}
                  a={"speciality"}
                />
                <ChipData
                  label="Health Information"
                  handleFilter={(value) => handleFilter(value)}
                  value={key === "disease" ? true : false}
                  a={"disease"}
                />
                {/* <ChipData label="Posts" handleFilter={handleFilter} value={4}/> */}
                {/* <ChipData label="Kompully" handleFilter={(value) => handleFilter(value)} value={key === "Kompully" ? true : false} a={'Kompully'} /> */}
                {/* <ChipData label="Evidence Review" handleFilter={(value)=>handleFilter(value)} value={false} a={'diseases'}/>
<ChipData label="Drug Information" handleFilter={(value)=>handleFilter(value)} value={false} a={'drugs'}/> */}
              </Stack>
              <div style={{ maxHeight: "400px", overflow: "auto" }}>
                {load ? (
                  <div
                    style={{ height: "100px" }}
                    className="elib-right__content"
                  >
                    <CircularProgress disableShrink size="1.5rem" />
                  </div>
                ) : searchOptions.length > 0 ? (
                  searchOptions.map((option, i) => (
                    <Box
                      // component="li"
                      key={i}
                      sx={{ "& > img": { mr: 2, flexShrink: 0 }, p: "10px", borderBottom: '1px solid #BDBDBD' }}
                      className="e-align flex cursor items-center"
                    >
                      {option.profile_pic || option.type === "people" ? (
                        <>
                          <Avatar
                            style={{
                              height: "40px",
                              width: "40px",
                              borderRadius: "10px",
                              boxShadow: "0px 3px 6px #00000029",
                            }}
                            src={
                              option.profile_pic ? option?.profile_pic?.[0]?.url : option?.profile_pic
                            }
                          />
                          <span className="capitalize cursor-pointer font-medium" style={{ paddingLeft: "10px" }}
                            onClick={() => {
                              const path = selectBranchInfo?.name ? `/${selectBranchInfo?.name}/doctor/${option?.id}` : `/doctor/${option?.id}`;
                              navigate(path);
                              handleCloseCard()
                            }}
                          >
                            {option?.name}</span>
                        </>
                      ) :
                        option.type === "disease" ? (
                          <div className="font-medium">{option?.disease}</div>
                        ) : option.type === "speciality" ? (
                          <div className="font-medium">{option?.speciality}</div>
                        ) : null}


                      {/* <span style={{ paddingLeft: "10px" }}>
                        {option.name ? option.name : option.name}
                      </span> */}
                    </Box>
                  ))
                ) : name !== "" ? (
                  <Box sx={{ p: "10px" }}>No Results</Box>
                ) : (
                  ""
                )}
              </div>
              {searchOptions?.length == 0 &&
                <Typography
                  variant="h6"
                  textAlign={"center"}
                  minHeight={"20vh"}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Search To Show Results
                </Typography>
              }
            </>
          </CardContent>
        </SearchCard>
      )}
    </div>
  );
}
