import React, { useEffect, useState } from "react";
import { Box, Grid, CircularProgress, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import DoctorProfileCard from "./DoctorProfilecard";
import BranchUrl from "../../services/api_branch_url";
import DoctorCard from "../branchhome/DoctorCard";
import InfiniteScroll from 'react-infinite-scroll-component';

const DoctorsList = () => {
    const [practitionerRole, setPractitionerRole] = useState([]);
    const [loading, setLoading] = useState(true);
    const [refreshing, setRefreshing] = useState(false);
    const [page, setPage] = useState(1);
    const [totalNumCard, setTotalNumCard] = useState(0);

    const branchName = useSelector((state) => state.branch.branch);
    const selectBranchInfo = useSelector((state) => state.SelectedBranch.selectedbranch);
    const navigate = useNavigate();
    const baseurl = BranchUrl();
    const limit = 3;
    const isXsScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const fetchData = () => {
        setLoading(true);
        axios.get(`${baseurl.fhir_tenant_orgId}PractitionerRole?_count=${limit}&_page=${page}`, {
            headers: {
                'X-FHIR-TENANT-ID': 'parimitha',
                'Realm': 'parimitha',
                'X-FHIR-DSID': `fhirparimitha`
            }
        })
            .then((res) => {
                setPractitionerRole(prevData => [...prevData, ...(res?.data?.entry || [])]);
                setTotalNumCard(res.data.total);
                setPage(prevPage => prevPage + 1);
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false);
                setRefreshing(false);
            });
    };

    useEffect(() => {
        fetchData();
    }, [selectBranchInfo]);

    return (
        <Box>
            <Grid container spacing={1} className="flex justify-center">
                {practitionerRole.length > 0 ? (
                    practitionerRole.map((val, index) => (
                        isXsScreen ? (
                            <Grid item xs={12} key={index} sx={{ p: { xs: "0px", sm: "10px", md: "10px" } }}>
                                <DoctorCard val={val} />
                            </Grid>
                        ) : (
                            <Grid item md={12} key={index} sx={{ p: { xs: "0px", sm: "10px", md: "10px" } }}>
                                <DoctorProfileCard val={val} />
                            </Grid>
                        )
                    ))
                ) : (
                    <div className='pt-8 text-lg md:text-2xl text-center w-full '>
                        {branchName ? <div>No Data Available</div> : <div>Please Select Branch / Facility</div>}
                    </div>
                )}
            </Grid>

            {loading && page === 1 ? (
                <CircularProgress sx={{ display: 'block', mx: 'auto', mt: 3 }} />
            ) : (
                <InfiniteScroll
                    dataLength={practitionerRole.length}
                    next={fetchData}
                    hasMore={practitionerRole.length < totalNumCard}
                    loader={<h4>Loading...</h4>}
                />
            )}
        </Box>
    );
};

export default DoctorsList;
