import React, { useEffect, useState } from "react";
import { Box, Card, Grid, Stack, TextField, Typography } from "@mui/material";
// import hospitalImg from '../assets/hospitalimage.jpg'
import hospitalImg from "../../assets/hospitalimage.jpg";
import TestimonialPage from "../home/testimonial/testimonial";
import { CustomTextField } from "../atoms/TextField";
import ContainedButton, { OutlinedButton } from "../atoms/commonbutton";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HappyClients from "./HappyClients";
import { Helmet } from "react-helmet-async";
import URL from "../../services/api_url";
import { locationJson } from "../../shared/constants";
import { useSelector } from "react-redux";
import axios from "axios";
import BranchUrl from "../../services/api_branch_url";

const AboutUs = () => {
  const baseurl = BranchUrl()
  const selectBranchInfo = useSelector(
    (state) => state.SelectedBranch.selectedbranch
  );

  function ImageList({ orgId }) {
    const [images, setImages] = useState([]);

    const fetchImages = async () => {
      try {
        const response = await fetch(
          `${URL.docsteth}getImageByOrgIdWithoutToken/${orgId}`
        );
        const data = await response.json();
        setImages(data);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    useEffect(() => {
      fetchImages();
    }, [orgId]);

    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Image ${index}`}
            style={{ Width: "200px", height: "150px", margin: "5px" }}
          />
        ))}
      </div>
    );
  }

  const [expandedAccordions, setExpandedAccordions] = useState([]);
  const [orgInfo, setOrgInfo] = useState([]);

  const handleAccordionChange = (index) => {
    const newExpandedAccordions = [...expandedAccordions];
    newExpandedAccordions[index] = !newExpandedAccordions[index];
    setExpandedAccordions(newExpandedAccordions);
  };

  useEffect(() => {
    if (selectBranchInfo?.id || process.env.REACT_APP_PARAMITHA_ID) {
      axios
        .get(
          `${baseurl.fhir_tenant_orgId}Location`,
          {
            headers: {
              "X-FHIR-TENANT-ID": "parimitha",
              "Realm": "parimitha",
              "X-FHIR-DSID": `fhirparimitha`,
              "Cache-Control": "no-cache",
            },
          }
        )
        .then((res) => {
          setOrgInfo(res?.data);
          // console.log(res?.data)
        })
        .catch((err) => console.log(err));
    }
  }, [process.env.REACT_APP_PARAMITHA_ID, selectBranchInfo?.id]);

  return (
    <>
      <Helmet>
        <title>About us</title>
      </Helmet>
      <Box
        sx={{
          p: {
            xs: "5px 10px",
            sm: "5px 20px",
            md: "5px 120px",
          },
          mt: 1,
        }}
      >
        <Box sx={{ mt: 1 }}>
          <Typography
            sx={{
              fontSize: { xs: 19, sm: 20, md: 24 },
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            About  {selectBranchInfo?.name ? ` ${selectBranchInfo?.name}` : 'Paramitha Hospitals'}
          </Typography>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Grid container>
            <Grid item xs={12} sm={6} md={6}>
              <span
                dangerouslySetInnerHTML={{
                  __html: orgInfo?.entry?.[0]?.resource?.description,
                }}
                style={{ whiteSpace: 'pre-wrap' }}
              />
              {/* <>
                <Typography
                  sx={{ fontSize: { xs: 17, sm: 17, md: 22 }, fontWeight: 600 }}
                >
                  Our Story
                </Typography>
                <Box sx={{ mt: 1 }}>
                  <Typography
                    sx={{
                      fontSize: { xs: 16, sm: 16, md: 16 },
                      color: "#707070",
                    }}
                  >
                    Since 2004, Paramitha Hospitals is transforming healthcare
                    for mother and child. We provide high-quality healthcare for
                    pregnant mothers, newborns and children covering every base
                    from Gynecology and Maternal care to Pediatrics.
                    <Typography
                      sx={{ fontSize: { xs: 16, sm: 16, md: 16 }, mt: 1 }}
                    >
                      Our facilities are equipped with state-of-the-art
                      level-III neonatal and pediatric intensive care units
                      complimented by our expert physicians who come with
                      decades of experience to provide the best care for you and
                      your bundle of joy.
                    </Typography>
                  </Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Typography
                    sx={{
                      fontSize: { xs: 17, sm: 17, md: 22 },
                      fontWeight: 600,
                    }}
                  >
                    Our Vision
                  </Typography>
                  <Box sx={{ mt: 1 }}>
                    <Typography
                      sx={{
                        fontSize: { xs: 16, sm: 16, md: 16 },
                        fontWeight: 600,
                      }}
                    >
                      To Make The World A Better Place For Women And Children
                      <Typography
                        sx={{
                          fontSize: { xs: 16, sm: 16, md: 16 },
                          mt: 1,
                          color: "#707070",
                        }}
                      >
                        We envision a world where women have access to
                        comprehensive healthcare services that address their
                        unique needs. We strive to provide specialized medical
                        care, including maternal health services, gynecological
                        care, and reproductive health support. By focusing on
                        the well-being of women, we aim to empower them to live
                        their lives to the fullest. For children, our vision is
                        to ensure that every child receives the best possible
                        medical treatment and support for their growth and
                        development. We are dedicated to providing a safe and
                        nurturing environment where children can heal, learn,
                        and flourish. Through our comprehensive pediatric care,
                        we aim to contribute to a future where children have
                        equal opportunities and a healthy start in life.
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Typography
                    sx={{
                      fontSize: { xs: 17, sm: 17, md: 22 },
                      fontWeight: 600,
                    }}
                  >
                    Our Mission
                  </Typography>
                  <Box sx={{ mt: 1 }}>
                    <Typography
                      sx={{
                        fontSize: { xs: 16, sm: 16, md: 16 },
                        mt: 1,
                        color: "#707070",
                      }}
                    >
                      Our mission at Paramitha Children Hospital is to provide a
                      wide range of specialized treatments at affordable prices
                      in the mother and child segment. We accomplish this by
                      maintaining low margins and focusing on high volumes
                      through increased outreach. By offering accessible and
                      high-quality healthcare services, we aim to ensure that
                      every woman and child receives the medical care they need
                      to lead healthy and fulfilling lives. Our mission drives
                      us to make a positive impact on the well-being of women
                      and children, creating a brighter future for generations
                      to come.
                    </Typography>
                  </Box>
                </Box>
              </> */}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              sx={{ p: { xs: "10px 0px", sm: "10px 30px", md: "10px 30px" } }}
            >
              <Typography
                sx={{ fontSize: { xs: 17, sm: 17, md: 22 }, fontWeight: 600 }}
              >
                Facility Tour
              </Typography>
              <div className="mt-1">
                {locationJson.map((location, index) => (

                  <Accordion
                    key={index}
                    sx={{ marginBottom: "10px" }}
                    expanded={expandedAccordions[index]}
                    onChange={() => handleAccordionChange(index)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{ stroke: "black" }} />}
                      aria-controls={`panel${index + 1}-content`}
                      id={`panel${index + 1}-header`}
                    >
                      <Typography sx={{ fontWeight: 600, textTransform: 'capitalize' }}>

                        {location.name}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {expandedAccordions[index] ? (
                        <ImageList orgId={location.id} />
                      ) : (
                        ""
                      )}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box sx={{ mt: 4, backgroundColor: "#1D5775", p: "10px 20px" }}>
        <Typography
          sx={{
            fontSize: { xs: 17, sm: 17, md: 22 },
            fontWeight: 600,
            textAlign: "center",
            color: "#FFFFFF",
          }}
        >
          Why Paramitha Hospital
        </Typography>
        <Grid
          container
          spacing={3}
          sx={{
            padding: { xs: "0px 0px", sm: "10px 20px", md: "0px 40px 20px" },
            mt: 0.1,
          }}
        >
          <Grid item xs={12} sm={4} md={4}>
            <Card sx={{ height: { xs: "215px", sm: "315px", md: "215px" } }}>
              <Box sx={{ mt: 1 }}>
                <Typography
                  sx={{
                    fontSize: { xs: 17, sm: 17, md: 17 },
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  Our Ethics
                </Typography>
              </Box>
              <Box sx={{ mt: 1 }}>
                <Typography
                  sx={{
                    fontSize: { xs: 14, sm: 14, md: 14 },
                    textAlign: "center",
                    p: "10px",
                    color: "#707070",
                  }}
                >
                  Ethical practice is at the core of everything we do. We value
                  integrity, honesty, and transparency in all our interactions.
                  Our dedicated team of medical professionals upholds the
                  highest ethical standards to ensure the well-being and trust
                  of our patients and their families.
                </Typography>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Card sx={{ minHeight: { xs: "215px", sm: "315px", md: "215px" } }}>
              <Box sx={{ mt: 1 }}>
                <Typography
                  sx={{
                    fontSize: { xs: 17, sm: 17, md: 17 },
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  Our Values
                </Typography>
              </Box>
              <Box sx={{ mt: 1 }}>
                <Typography
                  sx={{
                    fontSize: { xs: 14, sm: 14, md: 14 },
                    textAlign: "center",
                    p: "10px",
                    color: "#707070",
                  }}
                >
                  We strongly believe in providing quality care at an affordable
                  price. Our mission is to ensure that every child receives the
                  best possible medical treatment without burdening their
                  families financially. We are committed to provide accessible
                  high-quality services that are within reach of all families.{" "}
                </Typography>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Card sx={{ height: { xs: "215px", sm: "315px", md: "215px" } }}>
              <Box sx={{ mt: 1 }}>
                <Typography
                  sx={{
                    fontSize: { xs: 17, sm: 17, md: 17 },
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  Our Standards
                </Typography>
              </Box>
              <Box sx={{ mt: 1 }}>
                <Typography
                  sx={{
                    fontSize: { xs: 14, sm: 14, md: 14 },
                    textAlign: "center",
                    p: "10px",
                    color: "#707070",
                  }}
                >
                  Most of our Facilities are NABH accredited, keeping up with
                  the international Patient safety Standards Our NABL accredited
                  Laboratories, ensure accurate diagnosis and Reporting. With
                  Continues Quality Improvement and Development programs we
                  ensure patient safety{" "}
                </Typography>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          p: {
            xs: "5px 10px",
            sm: "5px 20px",
            md: "5px 80px",
          },
          mt: 2,
        }}
      >
        <Box sx={{ mt: 1 }}>
          <Typography
            sx={{
              fontSize: { xs: 17, sm: 17, md: 22 },
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            Founders Message
          </Typography>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={3} md={3}>
              <Stack>
                <img
                  src={hospitalImg}
                  // width={600} height={10}
                  alt="hospital"
                  className="shadow-2xl"
                  style={{
                    height: "200px",
                    width: "100%",
                    borderRadius: "10px",
                  }}
                />
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: 600 }}>
                    Dr. Dhanraj Ginnavaram
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body1" sx={{}}>
                    {" "}
                    Founder, Paramitha Children's and Women's Hospital
                  </Typography>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={9} md={9}>
              <Box>
                <Typography variant="body1" sx={{ fontSize: "14px" }}>
                  I am delighted to introduce Paramitha Children's and Women's
                  Hospital, a place where your child's health and well-being are
                  our top priorities. As the founder of this esteemed
                  institution, I wanted to create a healthcare center that goes
                  beyond traditional medical care. At Paramitha, we believe in a
                  holistic approach to pediatric care, encompassing not only the
                  physical health of our young patients but also their emotional
                  and developmental well-being.
                </Typography>
              </Box>
              <Box sx={{ mt: "5px" }}>
                <Typography variant="body1" sx={{ fontSize: "14px" }}>
                  With over 40 years of experience as a dedicated pediatrician,
                  I have witnessed the importance of providing comprehensive
                  care for children of all ages, from infants to adolescents.
                  Our team of highly skilled professionals, led by myself, is
                  committed to delivering the highest quality medical attention,
                  with a focus on compassionate care and excellent patient
                  outcomes. In critical situations, we understand the urgency of
                  immediate medical attention. That is why we offer
                  round-the-clock emergency services, ensuring that your child
                  receives prompt care when it matters most. You can rest
                  assured that our experienced team, including myself and my
                  dedicated staff, will be there to support and provide the
                  necessary medical assistance your child needs.
                </Typography>
              </Box>
              <Box sx={{ mt: "5px" }}>
                <Typography variant="body1" sx={{ fontSize: "14px" }}>
                  In critical situations, we understand the urgency of immediate
                  medical attention. That is why we offer round-the-clock
                  emergency services, ensuring that your child receives prompt
                  care when it matters most. You can rest assured that our
                  experienced team, including myself and my dedicated staff,
                  will be there to support and provide the necessary medical
                  assistance your child needs. We look forward to being a part
                  of your child’s healthcare journey, guiding them towards a
                  healthy and vibrant future.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box sx={{ mt: 3 }}>
        <HappyClients />
      </Box>

      <Box
        sx={{ p: { xs: "5px 10px", sm: "5px 20px", md: "5px 80px" }, mt: 3 }}
      >
        <Typography
          sx={{
            fontSize: { xs: 18, sm: 18, md: 22 },
            fontWeight: 600,
            textAlign: "center",
            mb: 3,
          }}
        >
          Career With Us
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={6} md={6}>
            <Typography
              sx={{
                fontSize: { xs: 16, sm: 16, md: 20 },
                fontWeight: 600,
                mb: 3,
              }}
            >
              Education & Traning
            </Typography>
            <Box
              sx={{
                height: "350px",
                width: { xs: "100%", sm: "350px", md: "500px" },
                border: "1px solid #707070",
              }}
            ></Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography
              sx={{
                fontSize: { xs: 17, sm: 17, md: 22 },
                fontWeight: 600,
                mb: 3,
              }}
            >
              Job Opening
            </Typography>
            <Box
              sx={{
                height: "350px",
                width: { xs: "100%", sm: "350px", md: "500px" },
                border: "1px solid #707070",
              }}
            ></Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{ p: { xs: "5px 10px", sm: "5px 20px", md: "5px 80px" }, mt: 3 }}
      >
        <Typography
          sx={{
            fontSize: { xs: 18, sm: 18, md: 22 },
            fontWeight: 600,
            textAlign: "center",
            mb: 3,
          }}
        >
          Connect With Us
        </Typography>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            sx={{ p: { xs: "0px", sm: "0px 20px", md: "0px 40px 0px 20px" } }}
          >
            <Box>
              <Box sx={{ mb: 1 }}>
                <label style={{ fontWeight: 500 }}>Contact Person Name</label>
              </Box>
              <CustomTextField
                placeholder="Select Date"
                type={"text"}
                borderColor="#59D8FA"
                borderRadius="7px"
              // value={values.fullName}
              // onChange={handleChange('fullName')}
              // handleBlur={handleBlur('fullName')}
              // error={touched.fullName && Boolean(errors.fullName)}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Box sx={{ mb: 1 }}>
                <label style={{ fontWeight: 500 }}>Company Name</label>
              </Box>
              <CustomTextField
                placeholder="Name of Entity contacting on Behalf"
                type={"text"}
                borderColor="#59D8FA"
                borderRadius="7px"
              // value={values.fullName}
              // onChange={handleChange('fullName')}
              // handleBlur={handleBlur('fullName')}
              // error={touched.fullName && Boolean(errors.fullName)}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Box sx={{ mb: 1 }}>
                <label style={{ fontWeight: 500 }}>Email Address</label>
              </Box>
              <CustomTextField
                placeholder="Your Email ID "
                type={"text"}
                borderColor="#59D8FA"
                borderRadius="7px"
              // value={values.fullName}
              // onChange={handleChange('fullName')}
              // handleBlur={handleBlur('fullName')}
              // error={touched.fullName && Boolean(errors.fullName)}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Box sx={{ mb: 1 }}>
                <label style={{ fontWeight: 500 }}>Phone Number</label>
              </Box>
              <CustomTextField
                placeholder="10 Digits mobile number"
                type={"text"}
                borderColor="#59D8FA"
                borderRadius="7px"
              // value={values.fullName}
              // onChange={handleChange('fullName')}
              // handleBlur={handleBlur('fullName')}
              // error={touched.fullName && Boolean(errors.fullName)}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            sx={{ p: { xs: "0px", sm: "0px 20px", md: "0px 40px 0px 20px" } }}
          >
            <Box>
              <Box sx={{ mb: 1 }}>
                <label style={{ fontWeight: 500 }}>Purpose</label>
              </Box>
              <CustomTextField
                placeholder="Purpose "
                type={"text"}
                borderColor="#59D8FA"
                borderRadius="7px"
              // value={values.fullName}
              // onChange={handleChange('fullName')}
              // handleBlur={handleBlur('fullName')}
              // error={touched.fullName && Boolean(errors.fullName)}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Box sx={{ mb: 1 }}>
                <label style={{ fontWeight: 500 }}>Subject of Message</label>
              </Box>
              <CustomTextField
                placeholder="Subject & Purpose "
                type={"text"}
                borderColor="#59D8FA"
                borderRadius="7px"
              // value={values.fullName}
              // onChange={handleChange('fullName')}
              // handleBlur={handleBlur('fullName')}
              // error={touched.fullName && Boolean(errors.fullName)}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Box sx={{ mb: 1 }}>
                <label style={{ fontWeight: 500 }}>Personal Message</label>
              </Box>

              {/* <TextField
                id="outlined-multiline-flexible"
                label="Message"
                multiline
                maxRows={4}
              // value={value}
              // onChange={handleChange}
              /> */}
              <TextField
                id="outlined-multiline-static"
                // label="Multiline"
                multiline
                rows={4}
                // defaultValue="Default Value"
                sx={{ width: "100%" }}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div></div>
                <div>
                  <ContainedButton
                    width="150px"
                    height="32px"
                    bgcolor="#1B5775"
                    borderRadius="7px"
                    text="Send Message"
                    // onClick={handleSubmit} // onClick event handler
                    // disabled={!isValid || !values.fullName || !values.username || !values.phonenum || !values.createpassword || !values.confirmPassword || !values.EmailId || !values.selectBranch}
                    startIcon={null}
                    endIcon={null}
                  />
                </div>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AboutUs;
