import React, { useEffect, useState } from 'react';
import { Card, Typography } from '@mui/material';
import { ExpandLess, ExpandMore, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

function DepartmentOverview({ locationInfo }) {
    console.log(locationInfo, "locationInfo")
    const [editAboutText, setEditAboutText] = useState('');
    const [about, setAbout] = useState(false);
    const [hover, setHover] = useState(false);

    useEffect(() => {
        if (locationInfo?.resource?.description) {
            if (!about) {
                const filteredData = locationInfo?.resource?.description?.length > 400
                    ? locationInfo?.resource?.description?.slice(0, 400)
                    : locationInfo?.resource?.description;
                setEditAboutText(filteredData);
            } else {
                setEditAboutText(locationInfo?.resource?.description);
            }
        }
    }, [locationInfo, about]);

    const handleToggleAbout = () => {
        setAbout(prevAbout => !prevAbout);
    };

    return (
        <div>
            <div className='font-semibold text-lg'>About</div>

            <div>
                <Typography my={1}>
                    <div
                        dangerouslySetInnerHTML={{ __html: editAboutText }}
                        style={{ whiteSpace: 'pre-wrap' }}
                    />
                </Typography>

                <Card>
                    {locationInfo?.resource?.description?.length > 400 && (
                        <div
                            style={{ padding: '8px', cursor: 'pointer', fontWeight: 400, background: hover ? "#FDF3F7" : "", color: hover ? "#E44190" : '#E44190' }}
                            onClick={handleToggleAbout}
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                        >
                            {about ? (
                                <div className='flex justify-center items-center'>
                                    <div> Show less </div>
                                    <ExpandLess />
                                </div>
                            ) : (
                                <div className='flex justify-center items-center'>
                                    <div> Show More </div>
                                    <ExpandMore />
                                </div>
                            )}
                        </div>
                    )}
                </Card>
            </div>
        </div>
    );
}

export default DepartmentOverview;
