import React, { useCallback, useEffect, useState } from 'react'
import { Close, EventAvailable, ExpandLess, ExpandMore, LocationOnOutlined, PersonAddAltOutlined, QrCode2, Search } from '@mui/icons-material'
import { Autocomplete, Box, Button, Card, Chip, CircularProgress, DialogActions, DialogContent, Divider, FormControl, FormControlLabel, FormLabel, Grid, IconButton, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material'
import { RiVerifiedBadgeFill } from 'react-icons/ri'
import { CustomTextField } from '../atoms/TextField'
import ContainedButton, { OutlinedButton } from '../atoms/commonbutton'
import { Commoncalender } from '../atoms/commoncalender'
import { localstore } from '../localstore/localstore'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { getPatientdatafromibm } from '../../redux/actions/actions'
import dayjs from 'dayjs'
import URL from '../../services/api_url'
import CustomAutoComplete from '../atoms/CustomAutoComplete'
import SearchBar from '../atoms/searchbar/SearchBar'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import editIcon from '../../assets/edit_24dp_FILL0_wght400_GRAD0_opsz24.svg';
import timeIcon from '../../assets/images/field-time-outlined.svg';
import SelectOptions from '../atoms/selectoptions';
import BranchUrl from '../../services/api_branch_url'
import { Ibmpatient } from '../atoms/patientdata'
import BookingAlert from './bookingAlert'
import { debounce } from 'lodash'
import { calculateAge } from '../atoms/calculateAge'

const appointmentRoles = [
    {
        system: "http://terminology.hl7.org/CodeSystem/v2-0276",
        code: 'ROUTINE',
        display: "Routine appointment - default if not valued"
    },
    {
        system: "http://terminology.hl7.org/CodeSystem/v2-0276",
        code: 'CHECKUP',
        display: " A routine check-up, such as an annual physical"
    },
    {
        system: "http://terminology.hl7.org/CodeSystem/v2-0276",
        code: 'FOLLOWUP',
        display: "A follow up visit from a previous appointment"
    },
    // {
    //     system: "http://terminology.hl7.org/CodeSystem/v2-0276",
    //     code: 'EMERGENCY',
    //     display: "Emergency appointment"
    // },
]

function Confirmbooking(props) {
    const { phoneNumber, selectedSlot, userData, userRole, id, handleCancel, handleClose, setShow, value, handleBackCalender } = props
    const selectedbranch = useSelector((state) => state.SelectedBranch.selectedbranch);
    const patientdata = useSelector((state) => state.paramitha.patientDetails)
    // console.log(patientdata, "hello")
    const ibmId = localstore.getIbmId()
    const usertoken = localstore.getToken()
    const urls = BranchUrl()
    const [selectreason, setSelectreason] = useState(null)
    const [loading, setLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [contains, setContains] = useState([]);
    const [alertmessage, setAlertmessage] = useState('')
    const [alertstatus, setAlertstatus] = useState('')
    const [gender, setGender] = useState('')
    const [birth, setBirth] = useState('');
    const [fullName, setFullName] = useState('')
    const [patientValue, setPatientValue] = useState(null);
    const [patientOpen, setPatientOpen] = useState(false);
    const [patientBlur, setPatientBlur] = useState(false);
    const [expand, setExpand] = useState(false)
    const [errors, setErrors] = useState({});
    const [appointmentType, setAppointmentType] = useState("");
    const [patientId, setPatientId] = useState('')
    const [appointmentId, setAppointmentId] = useState(null)
    const [registerDetails, setRegisterDetails] = useState('')
    const [patientInfo, setPatientInfo] = useState([]);

    const [tokenNumber, setTokenNumber] = useState("");
    const [patientDetails, setPatientDetails] = useState([])
    // console.log(patientDetails, "patientvalue")
    const [searchTerm, setSearchTerm] = useState('')
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [showError, setShowError] = useState({});
    const [alertOpen, setAlertOpen] = useState(false)
    const [age, setAge] = useState('')
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [count, setCount] = useState(10); // Initial count set to 10
    const [scrollNum, setScrollNum] = useState(0)
    // console.log(patientDetails, "patientDetails")
    // console.log(patientValue, "valuepatient")
    // console.log(userData, "userrrrrrr")
    // console.log(appointmentType, "PPOIN")
    const handleAppointmentChange = (event, value) => {
        setAppointmentType(value);
        setShowError({ ...showError, appointmentType: '' });
    };
    const handleOnChangeDate = (newValue) => {
        setBirth(newValue?.format("YYYY-MM-DD"))
        setErrors('')
    }
    const handleChangeToken = (e) => {
        setTokenNumber(e.target.value?.replace(/\D/g, "")?.slice(0, 10))
    }
    const paramswithToken = {
        'X-FHIR-TENANT-ID': 'parimitha',
        'X-FHIR-DSID': `fhirparimitha`,
        'realm': 'parimitha',
        'Authorization': `Bearer ${usertoken}`
    }

    const paramswithoutToken = {
        'X-FHIR-TENANT-ID': 'parimitha',
        'X-FHIR-DSID': `fhirparimitha`,
        'realm': 'parimitha'
    }

    useEffect(() => {
        if (appointmentRoles && appointmentRoles.length > 0) {
            setAppointmentType(appointmentRoles[0]);
        }
    }, [appointmentRoles]);

    const getpatientDetailsPhone = async () => {
        try {
            if (ibmId) {
                const telecomValue = patientdata?.telecom?.[0]?.value;
                if (telecomValue) {
                    const response = await axios.get(`${urls.person_tenant_orgId}Patient?phone=${telecomValue}`, {
                        headers: paramswithToken
                    });
                    setPatientDetails(response?.data?.entry);
                } else {
                    console.log('Telecom value is missing in patient data.');
                }
            } else if (phoneNumber) {
                const response = await axios.get(`${urls.fhir_tenant_orgId}Patient?_count=1000&phone=${phoneNumber}`, {
                    headers: paramswithoutToken
                });
                setPatientDetails(response?.data?.entry);
            } else {
                console.log('Both IBM ID and phone number are missing.');
            }
        } catch (error) {
            console.error('Error fetching patient details:', error);
        }
    };
    const getpatientRegisterDetails = () => {
        if (ibmId) {
            axios.get(`${urls.person_tenant_orgId}Patient/${patientId}`, {
                headers: paramswithToken
            }).then(res => {
                setRegisterDetails(res?.data)
            }).catch(error => {
                // console.log(error)
            })
        } else {
            axios.get(`${urls.fhir_tenant_orgId}Patient/${patientId}`, {
                headers: paramswithoutToken
            }).then(res => {
                setPatientDetails(res?.data?.entry)
            })
        }
    }


    useEffect(() => {
        getpatientDetailsPhone()
        if (patientId) {
            getpatientRegisterDetails()
        }
    }, [patientdata, phoneNumber])

    useEffect(() => {
        if (usertoken && ibmId) {
            dispatch(getPatientdatafromibm(urls, usertoken, ibmId))
        }

    }, [usertoken, ibmId])


    const fetchSearchResults = async (value, currentPage, currentCount) => {
        if (value) {
            try {
                setLoading(true);
                const res = await axios.get(`${URL.relationship}ValueSet/$expand`, {
                    params: {
                        url: 'http://hl7.org/fhir/ValueSet/encounter-reason',
                        filter: value,
                        page: currentPage,
                        count: currentCount,
                    },
                });
                const newResults = res?.data?.expansion?.contains || [];
                setSearchResults(prevResults => {
                    const existingCodes = new Set(prevResults.map(result => result.code));
                    const filteredNewResults = newResults.filter(result => !existingCodes.has(result.code));
                    return [...prevResults, ...filteredNewResults];
                });
                setScrollNum(res?.data?.expansion?.total);
                setHasMore(newResults.length > 0); // If less than requested items, no more data
                setPage(prevPage => prevPage + 1);
                setCount(prevCount => prevCount + 10); // Increase count by 10 for each new page
            } catch (error) {
                console.log(error);
                setSearchResults([]); // Clear search results or handle error state

            } finally {
                setLoading(false);
            }
        }
    };

    const debouncedFetchSearchResults = useCallback(debounce((value, currentPage, currentCount) => {
        fetchSearchResults(value, currentPage, currentCount);
    }, 1000), []);

    const handleInputChange = (event, value) => {
        setSearchTerm(value);
        setPage(1); // Reset page number on new search
        setCount(10);
        debouncedFetchSearchResults(value, 1, 10);
        setSearchResults([]);
    };

    const handleSelectOption = (event, option) => {
        if (option && option.display) {
            setSelectedOptions((prevOptions) => [...prevOptions, option]);
        }
    };
    const handleDeleteChip = (option) => {
        setSelectedOptions(selectedOptions.filter(item => item !== option));
    }
    // const debouncedHandleChangeSearch = debounce((value) => {
    //     if (value.trim() !== '') {
    //         setIsLoading(true)
    //         axios.get(`${URL.relationship}ValueSet/$expand?url=http://hl7.org/fhir/ValueSet/encounter-reason&filter=${value}`)
    //             .then(res => {
    //                 setSearchResults(res?.data?.expansion?.contains || []);
    //             }).catch(error => {

    //                 // alert(error.message)
    //             }).finally(() => {
    //                 setIsLoading(false)
    //             })
    //     } else {
    //         setSearchResults([]);
    //     }
    // }, 500)


    // const handleChangeSearch = (e) => {
    //     const value = e.target.value;
    //     setSearchTerm(value);
    //     debouncedHandleChangeSearch(value); // Call the debounced function
    //     if (value.length < 3) {
    //         setShowError({ ...showError, searchTerm: 'Search Symptoms must be at least 3 characters' });
    //     } else {
    //         setShowError('');
    //     }
    // }

    // const handleSelectOption = (option) => {
    //     setSelectedOptions([...selectedOptions, option]);
    //     setSearchTerm('');
    //     setSearchResults([]);
    // }


    // const handleDeleteChip = (option) => {
    //     setSelectedOptions(selectedOptions.filter(item => item !== option));

    // }
    console.log(appointmentId, "appointmentId")
    const handleSubmitPatient = async (e) => {
        e.preventDefault();

        let newErrors = {};

        if (!fullName.trim()) {
            newErrors.fullName = "Full Name is required";
        }
        if (!gender) {
            newErrors.gender = "Gender is required";
        }
        if (!birth) {
            newErrors.birth = "Date Of Birth is required";
        }

        let patientResource =
        {
            "resourceType": "Patient",
            "active": true,
            "address": [],
            "birthDate": birth || '',
            "communication": [],
            "gender": gender || '',
            "identifier": [],
            "name": fullName ? [
                {
                    "use": "official",
                    "text": fullName,
                    "given": [
                        fullName
                    ],
                    // "family": "k"
                }
            ] : [],
            "photo": [],
            managingOrganization: {
                "reference": `Organization/${selectedbranch?.id ? selectedbranch?.id : `${process.env.REACT_APP_PARAMITHA_ID}`}`
            },
            "telecom": [
                {
                    "use": "mobile",
                    "value": ibmId ? patientdata?.telecom?.[0]?.value : phoneNumber,
                    "system": "phone"
                }
            ]
        };

        if (Object.keys(newErrors).length === 0) {
            console.log('Form submitted successfully:', { fullName, gender, birth });

            const newPatient = {
                text: fullName,
                given: fullName,
                gender: gender,
                birthDate: birth,
            };

            // let data = {
            //     firstname: fullName,
            //     // lastname: '',
            //     dateofbirth: birth,
            //     gender: gender
            // };

            // let relateddata = {
            //     ...Ibmpatient,
            //     gender: data.gender.toLowerCase(),
            //     birthDate: data.dateofbirth,
            //     resourceType: 'Patient',
            //     name: [
            //         {
            //             ...Ibmpatient.name[0],
            //             given: [data.firstname],
            //             family: data.lastname,
            //             text: data.firstname
            //         },
            //     ],
            //     telecom: [],
            //     photo: [],
            // };

            // let d = { ...relateddata };

            // let relPersonData = {
            //     ...d,
            //     resourceType: 'RelatedPerson',
            //     patient: {
            //         reference: `Patient/${ibmId}`,
            //     },
            //     relationship: [],
            //     telecom: patientDetails?.telecom,
            // };

            setLoading(true);
            try {
                if (ibmId) {
                    // const res = await axios.post(`${urls.appointment}RelatedPerson`, relPersonData, {
                    //     headers: {
                    //         'X-FHIR-TENANT-ID': 'parimitha',
                    //         'X-FHIR-DSID': `fhirparimitha`,
                    //         'realm': 'parimitha',
                    //         'Authorization': `Bearer ${usertoken}`
                    //     },
                    // });

                    // let du = res.data;
                    // let secondarydata = {
                    //     ...d,
                    //     resourceType: 'Patient',
                    //     telecom: patientDetails.telecom,
                    // };
                    // secondarydata = {
                    //     ...secondarydata,
                    //     link: [
                    //         {
                    //             other: {
                    //                 reference: `RelatedPerson/${du}`,
                    //             },
                    //             type: 'refer',
                    //         },
                    //     ],
                    //     managingOrganization: {
                    //         "reference": `Organization/${selectedbranch?.id ? selectedbranch?.id : `${process.env.REACT_APP_PARAMITHA_ID}`}`
                    //     },
                    // };

                    const patientRes = await axios.post(`${urls.person_tenant_orgId}Patient`, patientResource, {
                        headers: {
                            'X-FHIR-TENANT-ID': 'parimitha',
                            'X-FHIR-DSID': `fhirparimitha`,
                            'realm': 'parimitha',
                            'Authorization': `Bearer ${usertoken}`
                        },
                    });

                    setPatientId(patientRes.data);
                    setPatientInfo([...patientInfo, patientResource]);
                    setPatientValue(newPatient);
                    setPatientOpen(false);
                    setPatientBlur(true);

                } else {
                    const res = await axios.post(`${urls.fhir_tenant_orgId}Patient`, patientResource, {
                        headers: paramswithoutToken
                    });

                    // Access Location header
                    // const location = res.headers['location'];
                    // const pId = location.split('/').slice(-3, -2)[0];
                    const pId = res.data
                    setPatientId(pId);

                    setPatientInfo([...patientInfo, newPatient]);
                    setPatientValue(newPatient);
                    setPatientOpen(false);
                    setPatientBlur(true);
                }
            } catch (error) {
                console.log(error);
                setAlertmessage(error.message);
                setAlertstatus('error');
            } finally {
                setLoading(false);
            }
        } else {
            setErrors(newErrors);
        }
    };

    const handleRelatedPatient = async (relIbmid) => {
        dispatch(getPatientdatafromibm(urls, usertoken, ibmId))

        let linkRelatedPerson = {
            ...patientdata,
            "link": [
                ...patientdata.link,
                {
                    "other": {
                        "reference": `Patient/${relIbmid}`,
                    },
                    "type": "refer"
                }
            ]
        };

        axios.put(`${urls.appointment}Patient/${ibmId}`, linkRelatedPerson, {
            headers: paramswithToken
        }).then(res => {
            setPatientOpen(false)
        })
    }


    const handleCancelFinal = () => {
        setSelectreason(null);
        setFullName("");
        setGender("");
        setBirth("");
        setPatientValue(null);
        setPatientOpen(false);
        setExpand(false);
        setErrors({});
        setAppointmentType("");
        setPatientId('');
        setRegisterDetails('');
        setPatientInfo([]);
        setTokenNumber("");
        setPatientDetails([]);
        setSearchTerm('');
        setSelectedOptions([]);
        setSearchResults([]);
        handleCancel()
    }


    const appointmentBookingFn = async () => {
        let newErrors = {};

        if (!appointmentType) {
            newErrors.appointmentType = "Please select an appointment type.";
        }

        if (!patientValue) {
            newErrors.patientValue = "Please select a patient.";
        }

        setShowError(newErrors);

        if (Object.keys(newErrors).length === 0) {
            const resourcedata = {
                "resourceType": "Appointment",
                "status": "proposed",
                "appointmentType": {
                    "coding": appointmentType ? [appointmentType] : []
                },
                "reasonCode": selectedOptions.length > 0 ? selectedOptions.map(e => ({
                    "coding": [
                        {
                            "system": e.system || '',
                            "code": e.code || e.coding?.[0]?.code || ''
                        }
                    ],
                    "text": e?.display || ''
                })) : [],
                "specialty": [
                    {
                        "coding": [
                            {
                                "system": "http://snomed.info/sct",
                                "code": userRole?.resource?.specialty?.[0]?.coding?.[0]?.display || ''
                            }
                        ]
                    }
                ],
                "start": selectedSlot?.start || '',
                "end": selectedSlot?.end || '',
                "supportingInformation": [
                    {
                        "reference": userRole?.resource?.id ? `PractitionerRole/${userRole.resource.id}` : ''
                    }
                ],
                "slot": [
                    {
                        "reference": selectedSlot?.id ? `Slot/${selectedSlot.id}` : ''
                    }
                ],
                "participant": [
                    {
                        "actor": {
                            "reference": `Patient/${patientId || patientValue?.resource?.id || ''}`,
                            "display": patientValue?.text || patientValue?.resource?.name?.[0]?.given?.[0] || ''
                        },
                        "required": "required",
                        "status": "accepted"
                    },
                    {
                        "actor": {
                            "reference": `Practitioner/${userData?.id || ''}`,
                            "display": userData?.name?.[0]?.text || ''
                        },
                        "required": "required",
                        "status": "accepted"
                    }
                ]
            };


            try {
                if (ibmId) {
                    // Post new appointment
                    const createResponse = await axios.post(`${urls.appointment}Appointment`, resourcedata, { headers: paramswithToken });

                    if (createResponse.status === 200 || createResponse.status === 201) {
                        // const location = createResponse.headers['location'];
                        // const appId = location.split('/').slice(-3, -2)[0];
                        const appId = createResponse.data;

                        setAppointmentId(appId)
                        // Get appointment data
                        const appointmentResponse = await axios.get(`${urls.appointment}Appointment/${appId}`, { headers: paramswithToken });
                        const appointmentdata = appointmentResponse.data;
                        const patientAppoint = { ...appointmentdata, status: "booked" };

                        // Update appointment status
                        const updateAppointmentResponse = await axios.put(`${urls.appointment}Appointment/${appId}`, patientAppoint, { headers: paramswithToken });

                        if (updateAppointmentResponse.status === 200 || updateAppointmentResponse.status === 201) {
                            // Get slot data
                            const slotResponse = await axios.get(`${urls.appointment}Slot/${selectedSlot?.id}`, { headers: paramswithToken });
                            const slotdata = slotResponse.data;
                            const patientSlot = { ...slotdata, status: "busy-tentative" };

                            // Update slot status
                            const updateSlotResponse = await axios.put(`${urls.appointment}Slot/${selectedSlot?.id}`, patientSlot, { headers: paramswithToken });

                            if (updateSlotResponse.status === 200 || updateSlotResponse.status === 201) {
                                setAlertOpen(true);
                            }
                        }
                    }
                } else {
                    // Post new appointment
                    const createResponse = await axios.post(`${urls.fhir_tenant_orgId}Appointment`, resourcedata, { headers: paramswithoutToken });

                    if (createResponse.status === 200 || createResponse.status === 201) {
                        // const location = createResponse.headers['location'];
                        const appId = createResponse?.data;
                        setAppointmentId(appId);

                        // Get appointment data
                        const appointmentResponse = await axios.get(`${urls.fhir_tenant_orgId}Appointment/${appId}`, { headers: paramswithoutToken });
                        const appointmentdata = appointmentResponse.data;
                        const patientAppoint = { ...appointmentdata, status: "booked" };

                        // Update appointment status
                        const updateAppointmentResponse = await axios.put(`${urls.fhir_tenant_orgId}Appointment/${appId}`, patientAppoint, { headers: paramswithoutToken });

                        if (updateAppointmentResponse.status === 200 || updateAppointmentResponse.status === 201) {
                            // Get slot data
                            const slotResponse = await axios.get(`${urls.fhir_tenant_orgId}Slot/${selectedSlot?.id}`, { headers: paramswithoutToken });
                            const slotdata = slotResponse.data;
                            const patientSlot = { ...slotdata, status: "busy-tentative" };

                            // Update slot status
                            const updateSlotResponse = await axios.put(`${urls.fhir_tenant_orgId}Slot/${selectedSlot?.id}`, patientSlot, { headers: paramswithoutToken });

                            if (updateSlotResponse.status === 200 || updateSlotResponse.status === 201) {
                                setAlertOpen(true);
                            }
                        }
                    }
                }
            } catch (err) {
                console.error("Error booking appointment: ", err);
            }
        }
    };


    // const appointmentBookingFn = () => {
    //     let newErrors = {};

    //     if (!appointmentType) {
    //         newErrors.appointmentType = "Please select an appointment type.";
    //     }

    //     if (!patientValue) {
    //         newErrors.patientValue = "Please select a patient.";
    //     }

    //     setShowError(newErrors);

    //     if (Object.keys(newErrors).length === 0) {
    //         const resourcedata = {
    //             resourceType: "Appointment",
    //             status: "proposed",
    //             appointmentType: {
    //                 coding: [appointmentType]
    //             },
    //             reasonCode: selectedOptions?.map(e => ({
    //                 coding: [
    //                     {
    //                         system: e.system,
    //                         code: e.code || e.coding?.[0]?.code
    //                     }
    //                 ],
    //                 text: e?.display
    //             })),
    //             start: selectedSlot?.start,
    //             end: selectedSlot?.end,
    //             supportingInformation: [
    //                 {
    //                     reference: `PractitionerRole/${userRole?.practitionerRole?.id}`
    //                 }
    //             ],
    //             slot: [
    //                 {
    //                     reference: `Slot/${selectedSlot?.id}`
    //                 }
    //             ],
    //             participant: [
    //                 {
    //                     actor: {
    //                         reference: `Patient/${patientId || patientValue?.resource?.id}`,
    //                         display: patientValue?.text || patientValue?.resource?.name?.[0]?.given?.[0]
    //                     },
    //                     required: "required",
    //                     status: "accepted"
    //                 },
    //                 {
    //                     actor: {
    //                         reference: `Practitioner/${userRole?.practitioner?.id}`,
    //                         display: userRole?.practitioner?.name?.[0]?.text
    //                     },
    //                     required: "required",
    //                     status: "accepted"
    //                 }
    //             ]
    //         };

    //         const postAppointment = (url, headers) => {
    //             axios.post(`${url}Appointment`, resourcedata, { headers })
    //                 .then(res => {
    //                     if (res.status === 200 || res.status === 201) {
    //                         const location = res.headers['location'];
    //                         const appId = location.split('/').slice(-3, -2)[0];
    //                         setAppointmentId(appId);

    //                         axios.get(`${url}Appointment/${appId}`, { headers })
    //                             .then(res => {
    //                                 const appointmentdata = res.data;
    //                                 const patientAppoint = { ...appointmentdata, status: "booked" };

    //                                 if (res.status === 200 || res.status === 201) {
    //                                     axios.put(`${url}Appointment/${appId}`, patientAppoint, { headers })
    //                                         .then(res => {
    //                                             if (res.status === 200 || res.status === 201) {
    //                                                 axios.get(`${url}Slot/${selectedSlot?.id}`, { headers })
    //                                                     .then(res => {
    //                                                         const slotdata = res.data;
    //                                                         const patientSlot = { ...slotdata, status: "busy-tentative" };

    //                                                         if (res.status === 200 || res.status === 201) {
    //                                                             axios.put(`${url}Slot/${selectedSlot?.id}`, patientSlot, { headers })
    //                                                                 .then(() => {
    //                                                                     setAlertOpen(true);
    //                                                                 });
    //                                                         }
    //                                                     });
    //                                             }
    //                                         });
    //                                 }
    //                             });
    //                     }
    //                 })
    //                 .catch(err => console.log(err));
    //         };

    //         if (ibmId) {
    //             postAppointment(urls.appointment, paramswithToken);
    //         } else {
    //             postAppointment(URL.paramitha_v4, paramswithoutToken);
    //         }
    //     }
    // };

    return (
        <div>
            <DialogContent>
                <div >
                    <Grid container >
                        <Grid item xs={12} md={7}>
                            <div className='text-base font-medium text-sm md:text-lg '>Your Details Found at {selectedbranch?.name} </div>
                            <div className='text-sm flex gap-2 items-center fntsz-15 mt-1 font-medium'><RiVerifiedBadgeFill /> Mobile Number Verified</div>
                            <div className='text-sm flex gap-2 items-center fntsz-14 mt-0.5 font-medium ml-6'>{ibmId ? patientdata?.telecom?.[0]?.value : phoneNumber}</div>

                            <div className='text-sm mt-2'>Pediatrics OP Consultation</div>
                            <Typography sx={{ color: "#000000", fontSize: "14px", }} className="text-sm font-medium flex pt-1 items-center" >
                                <LocationOnOutlined sx={{ fontSize: "18px", color: "#0F21B1", mr: "2px" }} />
                                Available at <span className="text-sm pl-2" style={{ color: "#0F4BBE" }} > {selectedbranch?.name} </span>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <div className='font-semibold text-medium pb-2  sm:mt-2'>Booking Consultation for </div>
                            <div className='border border-slate-400 rounded-md '>
                                <div className='flex items-center justify-between p-1.5 px-2 cursor-pointer' onClick={() => {
                                    setExpand(!expand)
                                    setPatientOpen(false)
                                }}>
                                    <div>
                                        <Typography>
                                            {patientValue ? (
                                                <>
                                                    <div className='font-medium'>{patientValue?.text || `${patientValue?.resource?.name?.[0]?.given}${patientValue?.resource?.name?.[0].family || ''}`}</div>
                                                    <div className='fntsz-13'>{patientValue?.birthDate ? `${calculateAge(patientValue?.birthDate)} years` : '' || patientValue?.resource?.birthDate ? `${calculateAge(patientValue?.resource?.birthDate)} years` : ''} | {patientValue?.gender || patientValue?.resource?.gender}</div>
                                                </>

                                            ) : (
                                                "Select Patient"
                                            )}
                                        </Typography>

                                    </div>
                                    {expand ? <ExpandLess /> : <ExpandMore />}

                                </div>
                                {
                                    expand && <>
                                        <Divider sx={{ mt: "5px" }} />
                                        <div className='p-1.5 px-2'
                                            style={{
                                                overflowY: 'scroll', maxHeight: "40vh", '&::-webkit-scrollbar': {
                                                    display: 'none',
                                                }
                                            }}
                                        >
                                            {
                                                patientDetails?.map((patient, index) =>
                                                    <div className='cursor-pointer' onClick={() => {
                                                        setPatientValue(patient)
                                                        setPatientBlur(true)
                                                        setExpand(false)
                                                        setShowError({ ...showError, patientValue: '' })

                                                    }}>
                                                        <div className='fntsz-14'>{patient?.resource?.name[0]?.given} {patient?.resource?.name[0]?.family}</div>
                                                        <div className='fntsz-13'>{patient?.resource?.birthDate ? `${calculateAge(patient?.resource?.birthDate)} years` : ''} | {patient?.resource?.gender}</div>
                                                        <Divider sx={{ m: "8px 0px" }} />
                                                    </div>
                                                )
                                            }

                                            < div className='flex gap-2 cursor-pointer' onClick={() => {
                                                setPatientOpen(true)
                                                setExpand(false)
                                                setPatientValue('')
                                                setFullName('')
                                                setGender('')
                                                setBirth('')
                                                setPatientBlur(false)

                                            }}><PersonAddAltOutlined />
                                                <div>
                                                    <div className='fntsz-14'>Register New Person
                                                    </div>
                                                    <div className='fntsz-12'>add a family member with this Mobile number
                                                    </div>
                                                </div>
                                            </div>
                                        </div >
                                    </>
                                }
                            </div>
                            {showError.patientValue && <Typography sx={{ fontSize: '13px', color: '#D32F2F' }}>{showError.patientValue}</Typography>}

                            {/* <div onClick={() => setPatientOpen(true)} style={{ color: '#1B5775' }}>Add Patient</div> */}
                        </Grid>
                        {
                            patientOpen &&
                            <Grid>
                                <Divider style={{ marginTop: '20px' }} />
                                <div className='font-semibold py-2'>Add Patient Details</div>
                                <Grid container spacing={2}>

                                    <Grid item xs={12} md={6.8}>
                                        <div className='md:pr-10'>
                                            <div className='flex items-center gap-6 font-normal '>
                                                <QrCode2 sx={{ fontSize: 100 }} />
                                                <p className='fntsz-15'>Scan this QR code from any Ayushmann Bharat Health Application , For Quick Registration & Verification</p>
                                            </div>
                                            <div className='font-medium py-2'>
                                                Input Token number to complete Registration
                                            </div>
                                            <CustomTextField placeholder="Token Number" type={'text'} borderColor="#59D8FA" borderRadius="7px" value={tokenNumber} onChange={handleChangeToken} />
                                            <div className='mt-2 flex items-center justify-between'>
                                                <div></div>
                                                <OutlinedButton text="Verify Token" width="150px" height="33px" color="#1B5775" borderRadius="7px" borderColor="#195775" />
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={5.2}>

                                        <div className='font-medium py-1'>Full Name (Display Name)</div>
                                        <CustomTextField placeholder="Patient Name" type={'text'} borderColor="#59D8FA" borderRadius="7px" value={fullName} onChange={(e) => {
                                            setFullName(e.target.value)
                                            setErrors('')
                                        }} error={errors.fullName}
                                        />

                                        {errors.fullName && <div className='fntsz-12 mt-0.5 text-red-500'>{errors.fullName}</div>}

                                        <div className='font-medium mt-1'>Gender</div>
                                        <Select
                                            displayEmpty
                                            fullWidth
                                            sx={{ '.MuiSelect-select': { padding: '10px' }, mt: "5px", borderRadius: '7px' }}
                                            value={gender}
                                            onChange={(e) => {
                                                console.log("Selected value:", e.target.value);
                                                setGender(e.target.value);
                                                setErrors({ ...errors, gender: '' }); // Clear gender error message on change

                                            }}
                                            placeholder='Select your Gender'
                                            renderValue={(selected) => {
                                                if (!selected) {
                                                    return <span>Select your Gender</span>;
                                                }
                                                return selected;
                                            }}
                                            error={errors.gender}
                                        >
                                            <MenuItem value={'male'}>Male</MenuItem>
                                            <MenuItem value={'female'}>Female</MenuItem>
                                            <MenuItem value={'other'}>Other</MenuItem>
                                        </Select>
                                        {errors.gender && <div className='fntsz-12 mt-0.5 text-red-500'>{errors.gender}</div>}

                                        <div className='font-medium mt-1'>Date of Birth</div>
                                        <div>
                                            <Commoncalender value={dayjs(birth)}
                                                onChange={handleOnChangeDate} maxDate={dayjs()} width="100%"
                                            />
                                        </div>
                                        {errors.birth && <div className='fntsz-12 mt-0.5 text-red-500'>{errors.birth}</div>}

                                        <div className='flex justify-between mt-2'  >
                                            <Button color='error' onClick={() => setPatientOpen((prev) => !prev)} sx={{ textTransform: 'capitalize', width: '120px', height: '33px' }}>Cancel</Button>
                                            <ContainedButton color='primary' width="120px" borderRadius="7px" height="33px" variant='contained' bgcolor="#1B5775" text="Submit" endIcon={loading ? <CircularProgress size={20} color='inherit' /> : null} onClick={handleSubmitPatient} /></div>
                                    </Grid>

                                </Grid>

                                <Grid container justifyContent={"space-between"} className='gap-10' mt={2} spacing={5} >

                                </Grid>
                                <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />
                            </Grid>
                        }
                    </Grid>
                </div>
                {/* <Box style={{ pointerEvents: !patientOpen ? 'none' : 'auto', opacity: !patientOpen ? 0.4 : 1 }}> */}
                <Box style={{ pointerEvents: !patientBlur && 'none', opacity: !patientBlur && 0.4 }}>
                    <Grid pt={2} container fullWidth >
                        <FormControl component="fieldset" fullWidth>
                            <div>Appointment Type</div>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={appointmentType}
                            // sx={{ display: 'flex', justifyContent: 'space-around' }} // Apply styles here

                            >
                                {
                                    appointmentRoles?.map((appointment, index) => (
                                        <FormControlLabel
                                            key={index}
                                            value={appointment}
                                            control={<Radio />}
                                            label={appointment.code.toLowerCase()} // Display code in lowercase
                                            sx={{ textTransform: 'capitalize', pr: { xs: 0, sm: 5, md: 7 } }}
                                            // sx={{ '.MuiFormGroup-root': { display: 'flex', justifyContent: 'space-between' } }}

                                            onChange={(event) => handleAppointmentChange(event, appointment)} // Pass the appointment object to the handler function

                                        />
                                    ))
                                }
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    {showError.appointmentType && <Typography sx={{ fontSize: '13px', color: '#D32F2F' }}>{showError.appointmentType}</Typography>}

                    <Grid container >
                        <Grid item xs={12} sm={12} md={6}>
                            <div className='font-medium py-2'>Reason For Appointment</div>
                            <Autocomplete
                                id="state-select"
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#59D8FA',
                                        },
                                    },
                                    ".MuiInputBase-root": {
                                        borderRadius: '7px',
                                    },
                                }}
                                options={searchResults}
                                size='small'
                                autoHighlight
                                // value={searchTerm}
                                onChange={(event, value) => handleSelectOption(event, value)}
                                onInputChange={(event, value) => handleInputChange(event, value)} // Ensure correct parameter passing
                                fullWidth
                                getOptionLabel={(option) => option?.display || ''} // Ensure option.display is accessed correctly
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{ textTransform: 'capitalize' }} {...props}>
                                        {option.display}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder='Appointment for'
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password',
                                        }}
                                    />
                                )}
                            />

                            <div className='mt-1 flex flex-wrap gap-1' >
                                {selectedOptions?.map(option => (
                                    <Chip
                                        key={option?.code}
                                        label={option?.display}
                                        onDelete={() => handleDeleteChip(option)}
                                    />
                                ))}
                            </div>
                            {/* <div>
                                <CustomTextField
                                    placeholder="Search your Symptoms"
                                    type='text'
                                    borderColor="#59D8FA"
                                    borderRadius="7px"
                                    icon={<Search sx={{ color: '#0434BF' }} />}
                                    onChange={handleChangeSearch}
                                    value={searchTerm}
                                />
                                <div className='p-1' >

                                    {isLoading ?
                                        <div className='text-sm'>Loading...</div> :

                                        <>
                                            {searchResults.length > 0 &&
                                                <Card
                                                    sx={{
                                                        p: "5px 15px",
                                                        overflowY: searchResults.length > 0 ? 'scroll' : 'hidden',
                                                        height: searchResults.length > 0 ? { xs: '62vh', sm: '61vh', md: '47vh' } : 'auto',
                                                        '&::-webkit-scrollbar': {
                                                            display: 'none',
                                                        }
                                                    }}
                                                >

                                                    {
                                                        searchResults.map(option => (
                                                            <div className='text-sm mt-1 cursor-pointer' key={option.code} onClick={() => handleSelectOption(option)}>
                                                                {option.display}
                                                            </div>
                                                        ))
                                                    }

                                                </Card>
                                            }
                                        </>
                                    }
                                </div>
                                <div className='mt-1 flex flex-wrap gap-1' >
                                    {selectedOptions.map(option => (
                                        <Chip
                                            key={option.code}
                                            label={option.display}
                                            onDelete={() => handleDeleteChip(option)}
                                        />
                                    ))}
                                </div>
                                {showError.searchTerm && <Typography sx={{ fontSize: '13px', color: '#D32F2F' }}>{showError.searchTerm}</Typography>}
                            </div> */}

                        </Grid>
                        <Grid item md={6}></Grid>

                    </Grid>
                </Box>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Grid container justifyContent={"space-between"} px={3}>
                    <Button
                        color='error' sx={{ textTransform: 'capitalize' }} onClick={handleCancelFinal}>Cancel</Button>
                    <Button color='primary' variant='contained' sx={{ textTransform: 'capitalize', background: '#1B5775' }} onClick={appointmentBookingFn} >Confirm Booking</Button>

                </Grid>
            </DialogActions>

            <BookingAlert open={alertOpen} setAlertOpen={setAlertOpen} userData={userData} userRole={userRole} selectedSlot={selectedSlot} selectedbranch={selectedbranch} handleCancel={handleCancelFinal} />
        </div >
    )
}

export default Confirmbooking