import { Typography, Button } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react'

const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
const daysCompare = { mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6, sun: 7 };


function AvailableDays({ timingInfo, styles, customTimeFont }) {
    const [WorkingDays, setWorkingDays] = useState([]);
    const [timings, setTimings] = useState({});
    const [activeDay, setActiveDay] = useState(null);
    // console.log(timingInfo, "timing")
    // console.log(activeDay, "activeday")

    useEffect(() => {
        if (timingInfo) {
            let data = [];
            const date = new Date();
            const today = days[date.getDay() - 1];
            // console.log(today, "today")
            {
                timingInfo?.map((item, i) => {
                    let openingTime = item.availableStartTime;
                    let closingTime = item.availableEndTime;
                    if (item.allDay == true) {
                        item?.daysOfWeek?.map((child) => {
                            data.push({
                                day: child,
                                allDay: item.allDay,
                                today: child == today ? true : false,
                            });
                        });
                    } else if (item.allDay == false) {
                        item.daysOfWeek?.map((child) => {
                            data.push({
                                day: child,
                                allDay: item.allDay,
                                today: child == today ? true : false,
                            });
                        });
                    } else {
                        item.daysOfWeek.map((child) => {
                            data.push({
                                day: child,
                                workingHours: [
                                    { openingTime: openingTime, closingTime: closingTime },
                                ],
                                today: child == today ? true : false,
                            });
                        });
                    }
                });
            }


            const mergedSchedule = [];

            for (let i = 0; i < data.length; i++) {
                let day = data[i].day;
                let hours = data[i].workingHours;

                let existingDay = mergedSchedule.find((item) => item.day === day);
                // console.log(existingDay, "existingDay")
                if (existingDay) {
                    existingDay.workingHours = existingDay.workingHours.concat(hours);
                    // console.log(existingDay, "existingDay11")
                } else {
                    mergedSchedule.push(data[i]);
                }
            }
            // console.log(mergedSchedule, "mergedSchedule,258");

            const sorted = mergedSchedule.sort((a, b) => {
                return daysCompare[a.day] - daysCompare[b.day];
            });
            // console.log(sorted, "sorted");
            setWorkingDays(sorted);
        }
    }, [timingInfo])
    useEffect(() => {
        const date = new Date();
        const today = days[date.getDay() - 1];
        WorkingDays?.find((item) => {
            if (item.day == today) {
                // console.log(item)
                setTimings(item);
            }
        });
    }, [WorkingDays]);

    return (
        <div>
            <div className="work-hours">
                {/* {
                    timings?.day && timings?.today ? (
                        <p>
                            {" "}
                            {timings?.day == "mon"
                                ? "Monday" + "(Today)"
                                : timings?.day == "tue"
                                    ? "Tuesday" + "(Today)"
                                    : timings?.day == "wed"
                                        ? "Wednesday" + "(Today)"
                                        : timings?.day == "thu"
                                            ? "Thursday" + "(Today)"
                                            : timings?.day == "fri"
                                                ? "Friday" + "(Today)"
                                                : timings?.day == "sat"
                                                    ? "Saturday" + "(Today)"
                                                    : timings?.day == "sun"
                                                        ? "Sunday" + "(Today)"
                                                        : ""}{" "}
                        </p>
                    ) :
                        timings?.day && !timings?.today ? (
                            <p>
                                {" "}
                                {timings?.day == "mon"
                                    ? "Monday"
                                    : timings?.day == "tue"
                                        ? "Tuesday"
                                        : timings?.day == "wed"
                                            ? "Wednesday"
                                            : timings?.day == "thu"
                                                ? "Thursday"
                                                : timings?.day == "fri"
                                                    ? "Friday"
                                                    : timings?.day == "sat"
                                                        ? "Saturday"
                                                        : timings?.day == "sun"
                                                            ? "Sunday"
                                                            : ""}{" "}
                            </p>
                        )
                            : ""
                } */}

            </div>
            <div style={{ display: "flex", gap: "5px", margin: "12px 0px", }}>
                {WorkingDays?.map((item, i) => {
                    return (
                        <Button
                            key={i}
                            variant="outlined"
                            onClick={() => {
                                setTimings(item);
                                setActiveDay(i);
                            }}
                            sx={{
                                minWidth: "25px",
                                border: '1px solid #E54290',
                                background:
                                    activeDay == i && activeDay !== null
                                        ? "#480223"
                                        : item.today && activeDay == null
                                            ? "#480223"
                                            : !item.allDay && !item.hasOwnProperty("workingHours")
                                                ? "#fff"
                                                : "#fff",
                                padding: "0px 7px",
                            }}
                        >
                            <Typography
                                variant="caption"
                                sx={{
                                    color: activeDay == i && activeDay !== null
                                        ? "#fff"
                                        : item.today && activeDay == null
                                            ? "#fff"
                                            : !item.allDay && !item.hasOwnProperty("workingHours")
                                                ? "#E54290"
                                                : "#E54290",
                                    textTransform: "capitalize",
                                }}
                                style={styles}
                            >
                                {item.day}
                            </Typography>
                        </Button>
                    );
                })}
            </div>
            <div>
                {timings && timings.allDay ? (
                    <Typography variant="body2" className="timing" style={styles}>
                        All Day
                    </Typography>
                ) : timings.allDay == false ? (
                    <Typography variant="body2" className="timing" style={styles} >
                        No Slot available
                    </Typography>
                ) :
                    (
                        timings?.workingHours ?
                            timings?.workingHours?.map((item, ind) => {
                                // console.log(item, "");
                                return (
                                    <Typography
                                        key={ind}
                                        variant="body2"
                                        className="timing"
                                        style={styles}

                                    >
                                        {moment(item?.openingTime, "hh:mm").format("HH:mm A")}
                                        &nbsp;&nbsp;-&nbsp;&nbsp;
                                        {moment(item?.closingTime, "hh:mm").format("HH:mm A")}
                                    </Typography>
                                );
                            }) : <Typography variant="body2" className="timing" style={styles}>
                                Today No Slots available
                            </Typography>
                    )
                }
            </div>
        </div>
    )
}

export default AvailableDays