import {
  Box,
  Grid,
  Typography,
  Autocomplete,
  Paper,
  TextField,
  IconButton,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import FilterListIcon from "@mui/icons-material/FilterList";
import { SpecialistTab, locationJson } from "../../../shared/constants";
import URL from "../../../services/api_url";
import axios from "axios";
import healthdeparticon from "../../../assets/healthcareprograms/Health Department 2.svg";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import BranchUrl from "../../../services/api_branch_url";
import { makeStyles } from "@mui/styles";
import { ArrowForward } from "@mui/icons-material";
import healthserviceicon from '../../../assets/healthcareprograms/Healthcare Service 1.svg';


const useStyles = makeStyles((theme) => ({
  img: {
    width: '50px',
    height: 54,
    [theme.breakpoints.down('md')]: {
      width: '35px', // Adjust width for extra small screens (phones)
      height: 35
    },
  }

}))

const SpecialistsTab = ({ branch }) => {
  console.log(branch, 'branh')
  const [serviceValue, setServiceValue] = React.useState(null);
  const [inputServiceValue, setInputServiceValue] = React.useState("");
  const [specialistInfo, setSpecialistInfo] = React.useState([]);
  const navigate = useNavigate()
  const urls = BranchUrl()
  const Classes = useStyles();
  // console.log(branch, "value")
  // useEffect(() => {
  //   axios.get(URL.getServiceDepts, {
  //     headers: {
  //       'X-FHIR-TENANT-ID': 'parimitha',
  //       'X-FHIR-DSID': `fhir${'parimitha'}`,
  //     }
  //   }).then((res) => {
  //     setSpecialistInfo(res?.data?.entry)
  //   }).catch((err) => console.log(err))
  // }, [])


  const branchId = branch?.id ? branch?.id : `${process.env.REACT_APP_PARAMITHA_ID}`
  useEffect(() => {
    if (branch) {
      const tenant = "parimitha";
      // axios.get(`${process.env.REACT_APP_API_URL_v4}resource/${tenant}/HealthcareService?_count=200&organization=${branch?.id} `,

      axios.get(`${process.env.REACT_APP_API_URL_v4}parimitha/${branchId}/HealthcareService?_count=200`,
        {
          headers: {
            "X-FHIR-TENANT-ID": tenant,
            "Realm": tenant,
            "X-FHIR-DSID": `fhir${tenant} `,
          },
        }
      )
        .then((res) => {
          setSpecialistInfo(res?.data?.entry || []);
        })
        .catch((err) => console.log(err));
    }
  }, [branch]);

  return (
    <Box sx={{ p: { xs: "0px", sm: "10px", md: '0px' }, mt: { xs: 1, sm: 1, md: 2 } }}>
      <Box sx={{ minHeight: { lg: 400 } }}>
        <div className="mt-2">
          <Grid container spacing={3} p={{ xs: '10px 0px', sm: '10px 0px', md: "20px 0px" }}>
            {specialistInfo?.length > 0 ?
              specialistInfo?.slice(0, 9).map((val, index) => {
                const imgSrc = val?.resource?.photo?.url
                return (
                  <Grid item xs={12} sm={4} md={4} key={index} >
                    <Paper elevation={2} className='flex items-center cursor-pointer px-2 py-2'
                      sx={{
                        display: "flex",
                        cursor: "pointer",
                        opacity: 1,
                        alignItems: 'center',
                        p: "10px 20px",
                        gap: "10px",
                        backgroundColor: '#FDF3F7',
                        height: '84px',
                        border: '1px solid #E54290',
                        borderRadius: '5px',
                        width: { xs: "100%", md: '100%' },
                        transition: 'box-shadow 0.3s ease, border 0.3s ease',
                        '&:hover': {
                          border: 'none', // Remove the border on hover
                          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)', // Increase the elevation effect
                        },
                      }}
                      onClick={() => navigate(`/healthcareservices/${val?.resource?.name}/${val?.resource?.id}`)
                      }>

                      <img
                        src={imgSrc || healthserviceicon}
                        alt="healthspecialities" className={Classes.img} // Apply the styles defined in useStyles
                        style={{ marginRight: "8px" }}
                      />
                      <Box className="truncate" >
                        <Grid sx={{
                          color: "#525C79",
                          color: "#1D5775",
                          fontSize: "16px",
                          fontWeight: "500",
                          textTransform: 'capitalize',
                          opacity: "100%",
                        }} >
                          {val?.resource?.name}
                        </Grid>
                        <Grid className="truncate text-sm font-light" sx={{ color: "#525C79" }}  >
                          {val?.resource?.comment}
                        </Grid>
                      </Box>
                    </Paper >
                  </Grid >
                );
              })
              :
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <Typography variant="h6" sx={{ color: "#525C79" }}>
                    No Specialities available
                  </Typography>
                </Box>
              </Grid>}
          </Grid >

        </div >
      </Box >
      {
        specialistInfo && specialistInfo?.length >= 9 &&
        <div style={{ display: "flex", justifyContent: "center", marginTop: "10px", }}>

          <Button
            variant="outlined"
            onClick={() => navigate('healthcareservices')}

            sx={{
              borderColor: "#E54290",
              textTransform: "capitalize",
              color: "#E54290",
              borderRadius: "10px",
              width: { xs: '200px', md: '255px' },
              height: { xs: '44px', md: "54px" },
              '&:hover': { backgroundColor: '#E54290', borderColor: '#E54290', color: 'white' },

            }}
            endIcon={<ArrowForward />}
          >
            <Typography sx={{ fontSize: { xs: '14px', md: "18px", fontWeight: 500 } }}>      Find All Specialities</Typography>
          </Button>
        </div>
      }
      {/* <Grid item xs={12} sm={0} md={1.5} sx={{ p: "10px" }}></Grid> */}
    </Box >
  );
};

export default SpecialistsTab;
