import { Box, Button, Card, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { ContactSupportOutlined } from "@mui/icons-material";

const ResearchPublications = () => {
  const publicationdata = [
    {
      docname: "Venkat Reddy k",
      postedtime: "12 March 2023",
      researchtitle:
        "Journal of Advanced Research Volume 37, March 2022, Pages 267-278",
      researchheading:
        "Nicotinamide mononucleotide (NMN) as an anti-aging health product – Promises and safety concerns",
      researchcontent:
        "Elderly population has been progressively rising in the world, thus the demand for anti-aging heath products to assure longevity as well as to ameliorate age-related complications is also on the rise. Among various anti-aging health products, nicotinamide mononucleotide (NMN) has been gaining attentions of the consumers and the scientific community.",
      researchlink: "https://doi.org/10.1016/j.jare.2021.08.003",
    },
    {
      docname: "Venkat Reddy k",
      postedtime: "12 March 2023",
      researchtitle:
        "Journal of Advanced Research Volume 37, March 2022, Pages 267-278",
      researchheading:
        "Nicotinamide mononucleotide (NMN) as an anti-aging health product – Promises and safety concerns",
      researchcontent:
        "Elderly population has been progressively rising in the world, thus the demand for anti-aging heath products to assure longevity as well as to ameliorate age-related complications is also on the rise. Among various anti-aging health products, nicotinamide mononucleotide (NMN) has been gaining attentions of the consumers and the scientific community.",
      researchlink: "https://doi.org/10.1016/j.jare.2021.08.003",
    },
    {
      docname: "Venkat Reddy k",
      postedtime: "12 March 2023",
      researchtitle:
        "Journal of Advanced Research Volume 37, March 2022, Pages 267-278",
      researchheading:
        "Nicotinamide mononucleotide (NMN) as an anti-aging health product – Promises and safety concerns",
      researchcontent:
        "Elderly population has been progressively rising in the world, thus the demand for anti-aging heath products to assure longevity as well as to ameliorate age-related complications is also on the rise. Among various anti-aging health products, nicotinamide mononucleotide (NMN) has been gaining attentions of the consumers and the scientific community.",
      researchlink: "https://doi.org/10.1016/j.jare.2021.08.003",
    },
  ];
  return (
    <Box
      sx={{
        mt: "40px",
      }}
    >
      <div className="flex gap-2">
        <ContactSupportOutlined sx={{ fontSize: '30px' }} />
        <div>
          <div className="text-xl font-semibold">
            Scientific Research Publications
          </div>
          <div className="font-normal">
            Innovations & Research Publications For Our Doctors
          </div >
        </div>
      </div>
      <Grid container columnSpacing={2} mt={2}>
        {publicationdata.map((item, val) => (
          <Grid item xs={12} sm={5.9} md={3.9} key={val}>
            <Paper elevation={3} sx={{ p: "10px 20px" }}>
              <div className="flex gap-3">
                <img
                  src={
                    "https://media.istockphoto.com/id/1396814518/vector/image-coming-soon-no-photo-no-thumbnail-image-available-vector-illustration.jpg?s=612x612&w=0&k=20&c=hnh2OZgQGhf0b46-J2z7aHbIWwq8HNlSDaNp2wn_iko="
                  }
                  alt="cover"
                  style={{
                    height: "25px",
                    //   maxWidth: "100%",
                    //   objectFit: "cover",
                    width: "25px",
                    borderRadius: "50px",
                  }}
                />
                <div>
                  <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                    {item.docname}
                  </Typography>
                  <Typography sx={{ fontSize: "12px", color: "gray" }}>
                    {item.postedtime}
                  </Typography>
                </div>
              </div>

              <div style={{ marginTop: "10px", display: "flex" }}>
                <div style={{ marginRight: "5px" }}>
                  <img
                    src={
                      "https://media.istockphoto.com/id/1396814518/vector/image-coming-soon-no-photo-no-thumbnail-image-available-vector-illustration.jpg?s=612x612&w=0&k=20&c=hnh2OZgQGhf0b46-J2z7aHbIWwq8HNlSDaNp2wn_iko="
                    }
                    alt="cover"
                    style={{
                      height: "50px",
                      //   maxWidth: "100%",
                      //   objectFit: "cover",
                      width: "50px",
                      //   borderRadius: "50px",
                    }}
                  />
                </div>
                <Typography sx={{ fontSize: "14px", mt: "10px", fontWeight: 500 }}>
                  {item.researchtitle}
                </Typography>
              </div>

              <div style={{ marginTop: "10px", display: "flex" }}>
                <Typography sx={{ fontWeight: "600" }}>
                  {item.researchheading}
                </Typography>
              </div>
              <div style={{ marginTop: "10px", display: "flex" }}>
                <Typography sx={{ fontSize: "14px", color: "#707070" }}>
                  {item.researchcontent}
                </Typography>
              </div>
              <div style={{ marginTop: "10px", display: "flex" }}>
                <Typography sx={{ fontSize: "14px", color: "#7974FF" }}>
                  {item.researchlink}
                </Typography>
              </div>
              <div style={{ marginTop: "20px", display: "flex" }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "transparent",
                    color: "#000000",
                    boxShadow: "none",
                    textTransform: "capitalize",
                    marginX: "auto",
                    border: "1px solid #BEBEBE",

                    alignItems: "center",
                    justifyContent: "space-between",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                    borderRadius: "50px",
                  }}
                >
                  <Typography> Read article</Typography>
                </Button>
              </div>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <div style={{ marginTop: "20px", marginBottom: "20px", display: "flex" }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "transparent",
            color: "#000000",
            boxShadow: "none",
            textTransform: "capitalize",
            marginX: "auto",
            border: "1px solid #BEBEBE",

            alignItems: "center",
            justifyContent: "space-between",
            "&:hover": {
              backgroundColor: "transparent",
            },
            borderRadius: "50px",
          }}
        >
          <Typography> Read article</Typography>
          <ArrowRightAltIcon sx={{ ml: "10px" }} />
        </Button>
      </div>
    </Box>
  );
};

export default ResearchPublications;
