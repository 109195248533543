import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { Add, Chat, Close, WhatsApp } from '@mui/icons-material';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import ChatMessage from './chat';
import "./chat.css";



export default function FloatingMenu() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [showWhatsApp, setShowWhatsApp] = useState(false);
    const [showChat, setShowChat] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleWhatsAppClick = () => {
        setShowWhatsApp(true);
        setShowChat(false);
        setMenuOpen(false); // Optionally close menu after selecting
    };

    const handleChatClick = () => {
        setShowChat(true);
        setShowWhatsApp(false);
        setMenuOpen(false); // Optionally close menu after selecting
    };



    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 25,
                right: 30,
                zIndex: 1000,
            }}
        >
            {/* Main Add Button */}
            <IconButton
                sx={{
                    backgroundColor: '#E44190',
                    color: '#FFFFFF',
                    '&:hover': {
                        backgroundColor: '#E44190',
                    },
                    width: 56,
                    height: 56,
                    zIndex: 1001, // Ensure it stays above the menu
                }}
                onClick={toggleMenu}
            >
                {menuOpen ? <Close /> : <Add />}
            </IconButton>

            {/* Circular Menu */}
            {menuOpen && (
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 70, // Adjust based on your main button position
                        right: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 1, // Spacing between buttons
                        }}
                    >
                        {/* WhatsApp Button */}
                        <IconButton
                            sx={{
                                backgroundColor: '#00E676',
                                color: 'black',

                                width: 56,
                                height: 56,
                                '&:hover': {
                                    backgroundColor: '#00E676',
                                },
                            }}
                        // onClick={handleWhatsAppClick}
                        >
                            <FloatingWhatsApp
                                phoneNumber='+918792078429'
                                accountName="Paramitha Hospitals"
                                className='whatsappButton'
                            />
                        </IconButton>

                        {/* Chat Button */}
                        {/* <IconButton
                            sx={{
                                backgroundColor: '#E44190',
                                // color: '#FFFFFF',
                                width: 56,
                                height: 56,
                                '&:hover': {
                                    backgroundColor: '#E44190',
                                },
                                // mt: 1
                            }}
                        // onClick={handleChatClick}
                        >
                        </IconButton> */}
                        <div>
                            <ChatMessage />
                        </div>

                    </Box>

                </Box>
            )}


        </Box>
    );
}
