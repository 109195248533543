import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Button, Card, Grid, IconButton, Rating, TextField, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ShareIcon from "@mui/icons-material/Share";
import axios from "axios";
import URL from "../../../services/api_url";
import { useParams } from "react-router-dom";
import FmdGoodIcon from "@mui/icons-material/FmdGood";

const serviceOptions = ['Kompally','Kothapet','Chintal','Chandanagar','Medipally','Bachupally'];

const TestimonalPage = () => {
  const [reviewData, setReviewData] = useState([]);
  const { branch } = useParams();
  const [value, setValue] = useState(branch);
  const [inputServiceValue, setInputServiceValue] = useState("");

  useEffect(() => {
    let branchName = branch || "Kompally";
    console.log(branchName)
    setValue(branchName)
  },[])
  useEffect(() => {
    // console.log(value, 84)
   
    if (value) {
      axios
        .get(`${URL.getReviews}/paramitha/${value}`)
        .then((res) => {
          const reviewInfo = res?.data?.filter((item) => item?.rating === 5)
          setReviewData(reviewInfo);
          // setReviewData(res?.data);
        })
        .catch((err) => console.log(err));
    }
  }, [value]);

  console.log(value)
  return (
    <div>
      <div className="text-xl md:text-4xl lg:text-[32px] text-center font-medium pt-5" style={{  color:'#E54290'}}>
        Our Patients Stories, are Testimonials for our Quality of Care
      </div>
      <Box className="px-10 flex justify-end">
            <Autocomplete
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              inputValue={inputServiceValue}
              onInputChange={(event, newInputValue) => {
                setInputServiceValue(newInputValue);
              }}
              id="controllable-states-demo"
              options={serviceOptions}
              sx={{
                width: 300,
                "& .MuiAutocomplete-inputRoot": {
                  height: 33,
                  backgroundColor: "#fff",
                },
                ".MuiOutlinedInput-root": { padding: "0px" },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="All Facilities"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <IconButton>
                        <FmdGoodIcon sx={{ fontSize: "15px" }} />
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
          </Box>
      <Grid>
        {reviewData?.map((item, index) => (
          <div key={index} className="pt-5">
            <Card
              style={{
                padding: "20px",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              <Grid
                container
                gap={1}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Grid item className="flex" alignItems={"center"} gap={1}>
                  {item?.profile_photo_url ? (
                    <Grid>
                      <img
                        src={item?.profile_photo_url}
                        width={30}
                        height={30}
                        style={{ objectFit: "scale-down" }}
                      />
                    </Grid>
                  ) : (
                    <Grid className="rounded-full w-8 h-8 text-white font-bold flex items-center justify-center bg-orange-400 capitalize">
                      {item?.author_name?.slice(0, 1)}
                    </Grid>
                  )}
                  <Grid>
                    <Grid className="text-sm font-bold capitalize">
                      <a href={item?.author_url} target="_blank">
                        {item?.author_name}
                      </a>
                    </Grid>
                    <Grid className="text-xs">{item?.relative_time_description}</Grid>
                  </Grid>
                </Grid>
                <Grid item className="text-right">
                  <MoreVertIcon />
                </Grid>
              </Grid>
              <Grid container pt={1}>
                <Grid className="text-xs flex items-center">
                  {" "}
                  <Rating
                    name="read-only"
                    size="small"
                    value={item?.rating}
                    readOnly
                  />{" "}
                  &nbsp; 
                </Grid>
              </Grid>
              <Grid paddingTop={1}>
                {/* <Grid className="text-sm">{item?.text}</Grid> */}
                {/* Review Text */}
                <div
                // className={showFullText[item.id] ? "text-sm" : "text-sm line-clamp-3"  }
                // ref={(el) => (textRefs.current[index] = el)}
                >
                  {item?.text}
                </div>
              </Grid>
              <Grid paddingTop={2} container gap={4} alignItems={"center"}>
                <Grid>
                  <ThumbUpOffAltIcon fontSize="16px" />
                </Grid>
                <ShareIcon fontSize="16px" />
              </Grid>
            </Card>
          </div>
        ))}
      </Grid>
    </div>
  );
};

export default TestimonalPage;
