import React from "react";
// import { styles } from "../styles.module.css";

const AutoPlayVideo = ({ src }) => {
  const handleVideoLoaded = (event) => {
    event.target.play(); // Autoplay the video when it's loaded
  };
  return (
    <video
      // ref={videoRef}
      src={src}
      autoPlay
      muted // Mute the video to prevent audio playback by default
      loop // Enable looping so the video repeats
      playsInline // Required for iOS to autoplay videos
      style={{ width: "100%", height: "auto" }} // Adjust size as needed
      onLoad={handleVideoLoaded} // Trigger play() when the video is loaded
    // className={styles?.carouselHead}
    />
  );
};

export default AutoPlayVideo;
