import React, { useEffect, useState } from 'react'
import { Grid, Paper, Box, CircularProgress, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import BranchComponent from '../BranchComponent';
import { ServicesTab } from '../../shared/constants';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import URL from '../../services/api_url';
import healthserviceicon from '../../assets/healthcareprograms/Healthcare Service 1.svg'
import BranchUrl from '../../services/api_branch_url';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({

    img: {
        width: '54px',
        height: 54,
        [theme.breakpoints.down('md')]: {
            width: '35px', // Adjust width for extra small screens (phones)
            height: 35
        },
    },
}))

const HealthcareServicePage = () => {
    const Classes = useStyles()

    const [specialistInfo, setSpecialistInfo] = React.useState([]);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const branchName = useSelector((state) => state.branch.branch);
    const selectBranchInfo = useSelector((state) => state.SelectedBranch.selectedbranch);
    const baseurl = BranchUrl()
    useEffect(() => {
        if (branchName) {
            setLoading(true)
            // axios.get(`${baseurl.fhir_auth}HealthcareService?_count=200${selectBranchInfo?.id ? `&organization=${selectBranchInfo?.id}` : ''}`, {
            axios.get(`${baseurl.fhir_tenant_orgId}HealthcareService?_count=200 `, {
                headers: {
                    'X-FHIR-TENANT-ID': 'parimitha',
                    'Realm': 'parimitha',
                    'X-FHIR-DSID': `fhirparimitha`,
                }
            }).then((res) => {
                setSpecialistInfo(res?.data?.entry || [])
            }).catch((err) => console.log(err))
                .finally(() => {
                    setLoading(false)
                })
        }

    }, [selectBranchInfo])

    const handleSelectService = (title, id) => {
        let name = title.replace(/\//g, '')
        navigate(selectBranchInfo?.name ? `/${selectBranchInfo?.name}/healthcareservices/${name}/${id}` : `/healthcareservices/${name}/${id}`)

    }
    return (
        <Box sx={{ p: { xs: '0px 10px', lg: '0px 90px' } }} >
            <div className='px-8' style={{ minHeight: '500px' }}>
                <h1 className='text-xl sm:text-2xl lg:text-3xl font-semibold pt-4 text-center' style={{ color: '#E54290' }}>
                    Healthcare Specialities @ {branchName ? `${branchName}` : ''}
                </h1>
                <div className='mt-1'>

                    <Grid container spacing={2} pt={{ xs: 0, md: 2 }}>
                        {!branchName &&
                            <Grid item xs={12} sm={12} md={4} lg={3}>
                                <BranchComponent specialistInfo={specialistInfo} />
                            </Grid>
                        }

                        <Grid item container xs={branchName ? 12 : 12} sm={branchName ? 12 : 12} md={branchName ? 12 : 8} lg={branchName ? 12 : 9} columnSpacing={8} rowSpacing={3} mt={{ xs: 0, md: 1 }} >
                            {loading ? (
                                <Grid item xs={12} className='text-center'>
                                    <CircularProgress />
                                    <Typography variant="h6" color="textSecondary">
                                        Loading...
                                    </Typography>
                                </Grid>
                            ) : specialistInfo?.length > 0 ? (
                                specialistInfo?.map((val, index) => {
                                    const imgSrc = val?.resource?.photo?.url;
                                    return (
                                        <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                                            <Paper elevation={2} className='flex items-center cursor-pointer px-2 py-2'
                                                sx={{
                                                    display: "flex",
                                                    cursor: "pointer",
                                                    opacity: 1,
                                                    alignItems: 'center',
                                                    p: "10px 20px",
                                                    gap: "10px",
                                                    backgroundColor: '#FDF3F7',
                                                    height: '84px',
                                                    border: '1px solid #E54290',
                                                    borderRadius: '5px',
                                                    width: { xs: "100%" },
                                                    transition: 'box-shadow 0.3s ease, border 0.3s ease',
                                                    '&:hover': {
                                                        border: 'none', // Remove the border on hover
                                                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)', // Increase the elevation effect
                                                    },
                                                }}
                                                onClick={() => handleSelectService(val?.resource?.name, val?.resource?.id)}>
                                                <img
                                                    src={imgSrc || healthserviceicon}
                                                    alt="healthservices" className={Classes.img} style={{ marginRight: "8px" }} />
                                                <Box className="truncate">
                                                    <Grid sx={{
                                                        color: "#1D5775",
                                                        fontSize: "16px",
                                                        fontWeight: "500",
                                                        textTransform: 'capitalize',
                                                        opacity: "100%",
                                                    }}>
                                                        {val?.resource?.name}
                                                    </Grid>
                                                    <Grid className="truncate text-sm font-light" sx={{ color: "#525C79" }}>
                                                        {val?.resource?.comment}
                                                    </Grid>
                                                </Box>
                                            </Paper>
                                        </Grid>
                                    );
                                })
                            ) : (
                                <Grid item xs={12} sm={12} md={9} lg={9} className='text-lg md:text-2xl text-center w-full'>
                                    {branchName ? <div>No Data Available</div> : <div>Please Select Branch / Facility</div>}
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </div>
            </div >
        </Box >
    )
}

export default HealthcareServicePage