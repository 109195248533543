import React from 'react'
import { Grid, Paper, Stack } from '@mui/material'
import StethIcon from '../../assets/images/stethoscope.svg';
import teleIcon from '../../assets/images/Telemedicine.svg';
import YouTubeIcon from '../../assets/images/Icon fa-brands-youtube.svg'
import InstagramIcon from '../../assets/images/Icon corebrands-instagram.svg'
import TwiterIcon from '../../assets/images/Icon fa-brands-x-twitter.svg'
import LinkedInIcon from '../../assets/images/Icon corebrands-linkedin.svg'
import RequestIcon from '../../assets/images/referral.svg'
import { BiSolidPhone } from "react-icons/bi";
import { CiMail } from "react-icons/ci";
import CommunicationImg from '../../assets/images/Communication-language.svg';
import ContainedButton from '../atoms/commonbutton';
import { FaXTwitter } from 'react-icons/fa6';
import { FaInstagram, FaLinkedin } from 'react-icons/fa';
import { IoLogoYoutube } from 'react-icons/io';

function HealthServiceContacts({ ContactInfo, deptData }) {
    return (
        <div>
            <div className='font-semibold text-lg '>Contact</div>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <Paper elevation={3} className='p-2 mt-1'>
                        <div className='text-[20px] font-medium'>Healthcare Service</div>
                        <Stack spacing={0.5}>
                            <Grid container>
                                <Grid item md={3}>
                                    <span className='text-[14px] font-medium'>Category</span>
                                </Grid>
                                <Grid item md={7}>
                                    <span>
                                        {Array.isArray(deptData?.category) && deptData?.category.map((item, index) => (
                                            <div key={index} className='text-[14px]'>: {item?.coding?.[0]?.display}</div>
                                        ))}
                                    </span>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item md={3}>
                                    <span className='text-[14px] font-medium'>Service Type</span>
                                </Grid>
                                <Grid item md={7}>
                                    <span>
                                        {Array.isArray(deptData?.type) && deptData?.type.map((item, index) => (
                                            <div key={index} className='text-[14px]'>: {item?.coding?.[0]?.display}</div>
                                        ))}
                                    </span>
                                </Grid>
                            </Grid>
                            <div className='text-[14px] flex font-medium'>
                                <span className='pr-3'>Service Type:</span>
                                <span>
                                    {Array.isArray(deptData?.type) && deptData?.type.map((item, index) => (
                                        <div key={index} className='text-[14px]'>{item?.coding?.[0]?.display}</div>
                                    ))}
                                </span>
                            </div>
                            <div className='flex gap-1'>
                                <img src={StethIcon} width={28} alt="Stethoscope Icon" />
                                <div className='flex gap-1 flex-wrap items-center'>
                                    {Array.isArray(deptData?.specialty) && deptData?.specialty.map((item, index) => (
                                        <div key={index} className='text-sm rounded-full px-2' style={{ background: '#EBEBEB' }}>
                                            {item?.coding?.[0]?.display}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='text-[14px] flex flex-wrap gap-2 mt-1'>
                                <img src={CommunicationImg} width={14} alt="Communication Icon" />
                                <div className='flex flex-wrap gap-1'>
                                    <span className='font-medium text-[14px]'>Communicate in</span>
                                    <span style={{ color: '#092184B1' }} className='flex flex-wrap gap-1'>
                                        {Array.isArray(deptData?.communication) && deptData?.communication.length > 0 ?
                                            deptData?.communication.map((item, index) => (
                                                <div key={index}>{item?.coding?.[0]?.display}</div>
                                            )) : '-------'
                                        }
                                    </span>
                                </div>
                            </div>
                            <div className='text-[14px] font-medium mt-1'>
                                Referral {Array.isArray(deptData?.referralMethod) && deptData?.referralMethod.length > 0 ? '' : 'not'} Required
                            </div>
                        </Stack>
                        <div className='text-[14px] pt-2 font-medium'>Register & Book Appointment to start your Wellness journey</div>
                        <Grid container spacing={2} justifyContent={"center"} pt={1}>
                            <Grid item>
                                <ContainedButton
                                    variant="outlined"
                                    onClick={() => { }}
                                    startIcon={<img src={RequestIcon} width={'18px'} alt="Request Icon" />}
                                    text="Request Appointment"
                                    fontSize={{ xs: "10px", sm: "10px", md: "12px", lg: "14px" }}
                                    borderRadius="10px"
                                    bgcolor="#E44190"
                                    color="#205072"
                                    height="32px"
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper elevation={3} sx={{ px: 2, py: 1, mt: 2, mb: { xs: 3, sm: 3, md: 3, lg: 3 } }}>
                        <Grid container>
                            <Grid item xs={4} sm={2} md={5} mt={'10px'}>
                                <div className='flex gap-3 items-center'>
                                    <BiSolidPhone style={{ border: '1px solid #205072', padding: '1px', fontSize: '16px', borderRadius: '50%' }} />
                                    <span>Phone</span>
                                </div>
                            </Grid>
                            <Grid item xs={8} sm={10} md={7} mt={'10px'}>
                                <ContactInfo contactDetails={deptData?.telecom} system={'phone'} />
                            </Grid>
                            <Grid item xs={4} sm={2} md={5} mt={'10px'}>
                                <div className='flex gap-3 items-center'>
                                    <CiMail style={{ border: '1px solid #205072', padding: '1px', fontSize: '16px', borderRadius: '50%' }} />
                                    <span>Email &nbsp;</span>
                                </div>
                            </Grid>
                            <Grid item xs={8} sm={10} md={7} mt={'10px'}>
                                <ContactInfo contactDetails={deptData?.telecom} system={'email'} />
                            </Grid>
                            <Grid container alignItems={"center"} pt={1}>
                                <Grid item xs={4} sm={2} md={5} alignItems={"center"}>
                                    <span className='flex gap-3 items-center ml-4'>
                                        <span>Follow &nbsp;</span>
                                    </span>
                                </Grid>
                                <Grid item xs={8} sm={10} md={7}>
                                    <Stack direction="row" spacing={2}>
                                        <FaXTwitter style={{ height: 24, width: 24, color: '#E44190' }} />
                                        <FaInstagram style={{ height: 24, width: 24, color: '#E44190' }} />
                                        <IoLogoYoutube style={{ height: 24, width: 24, color: '#E44190' }} />
                                        <FaLinkedin style={{ height: 24, width: 24, color: '#E44190' }} />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}

export default HealthServiceContacts;