const initialState = {
    accesstoken: "",
    branchLists: [],
};

const loginReducer = (state = initialState, action) => {
    // console.log(action,">>>>>>>>>>>>>");
    switch (action.type) {
        case "SET_TOKEN":
            return {
                ...state,
                accesstoken: action.payload
            };
        case "BRANCHLISTS":
            return {
                ...state,
                branchLists: action.payload
            };
        default:
            return state;
    }
};

export default loginReducer;
