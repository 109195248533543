import axios from "axios";
import URL from "../../services/api_url";
import BranchUrl from "../../services/api_branch_url";

export const getPatientdatafromibm = (urls, usertoken, ibmId) => {
    return (dispatch) => {
        axios.get(`${urls.person_tenant_orgId}Person/${ibmId}`, {
            headers: {
                'X-FHIR-TENANT-ID': 'parimitha',
                'X-FHIR-DSID': `fhirparimitha`,
                'Authorization': `Bearer ${usertoken}`,
                'realm': 'parimitha'
            }
        }).then(res => {
            dispatch({
                type: 'PATIENTDETAILS',
                payload: res.data,
            });
        })
            .catch(err => {
                dispatch({
                    type: 'PATIENTDETAILS',
                    payload: {},
                });
            });
    };
};
export const getPatientIdDetails = (urls, usertoken, ibmId) => {
    return (dispatch) => {
        axios.get(`${urls.person_tenant_orgId}Patient/${ibmId}`, {
            headers: {
                'X-FHIR-TENANT-ID': 'parimitha',
                'X-FHIR-DSID': `fhirparimitha`,
                'Authorization': `Bearer ${usertoken}`,
                'realm': 'parimitha'
            }
        }).then(res => {
            dispatch({
                type: 'PATIENTDATA',
                payload: res.data,
            });
        })
            .catch(err => {
                dispatch({
                    type: 'PATIENTDATA',
                    payload: {},
                });
            });
    };
};

export const getFixedPatient = (urls, usertoken, ibmId) => {
    return (dispatch) => {
        axios.get(`${urls.person_tenant_orgId}Person/${ibmId}`, {
            headers: {
                'X-FHIR-TENANT-ID': 'parimitha',
                'X-FHIR-DSID': `fhirparimitha`,
                'Authorization': `Bearer ${usertoken}`,
                'realm': 'parimitha'
            }
        }).then(res => {
            dispatch({
                type: 'FIXED_PATIENT',
                payload: res.data,
            });
        })
            .catch(err => {
                dispatch({
                    type: 'FIXED_PATIENT',
                    payload: {},
                });
            });
    };
}



export const getAppointments = (urls, usertoken, ibmId) => {
    return (dispatch) => {
        axios.get(`${urls.paramitha_v1}Appointment/patient/${ibmId}`, {
            headers: {
                'X-FHIR-TENANT-ID': 'parimitha',
                'X-FHIR-DSID': `fhirparimitha`,
                'Authorization': `Bearer ${usertoken}`,
                'realm': 'parimitha'
            }
        })
            .then(res => {
                dispatch({
                    type: 'PATIENT_APPOINTMENTS',
                    payload: res.data,
                });
            })
            .catch(err => {
                dispatch({
                    type: 'PATIENT_APPOINTMENTS',
                    payload: [],
                });
            });
    }
}

// export const getStates = (usertoken) => {
//     return (dispatch) => {
//         axios.get(`${URL.elib}country_wise_states/IN`, {
//             headers: {
//                 'Authorization': `Bearer ${usertoken}`,
//             }
//         })
//             .then(res => {
//                 dispatch({
//                     type: 'GET_STATES',
//                     payload: res.data.data,
//                 });
//             })
//             .catch(err => {
//                 dispatch({
//                     type: 'GET_STATES',
//                     payload: [],
//                 });
//             });
//     }
// }
export const getStates = (usertoken) => {
    return (dispatch) => {
        axios.get(`${URL.elib}get-allstatenames`, {
            headers: {
                'Authorization': `Bearer ${usertoken}`,
            }
        })
            .then(res => {
                dispatch({
                    type: 'GET_STATES',
                    payload: res.data.data,
                });
            })
            .catch(err => {
                dispatch({
                    type: 'GET_STATES',
                    payload: [],
                });
            });
    }
}
export const getDistricts = (usertoken, state) => {
    return (dispatch) => {
        axios.get(`${URL.elib}get-alldistricts-by-statename/${state}`, {
            headers: {
                'Authorization': `Bearer ${usertoken}`,
            }
        })
            .then(res => {
                dispatch({
                    type: 'GET_DISTRICTS',
                    payload: res.data.data,
                });
            })
            .catch(err => {
                dispatch({
                    type: 'GET_DISTRICTS',
                    payload: [],
                });
            });
    }
}


export const getSubDistricts = (usertoken, district) => {
    return (dispatch) => {
        axios.get(`${URL.elib}get-allsubdistricts-by-districtname/${district}`, {
            headers: {
                'Authorization': `Bearer ${usertoken}`,
            }
        })
            .then(res => {
                dispatch({
                    type: 'GET_SUB_DISTRICTS',
                    payload: res.data.data,
                });
            })
            .catch(err => {
                dispatch({
                    type: 'GET_SUB_DISTRICTS',
                    payload: [],
                });
            });
    }
}
export const getLanguages = () => {
    return (dispatch) => {
        axios.get(`${URL.language}ValueSet/languages`)
            .then(res => {
                console.log(res.data, "resssssssss")
                dispatch({
                    type: 'GET_LANGUAGES',
                    payload: res?.data?.compose?.include[0]
                });
            })
            .catch(err => {
                dispatch({
                    type: 'GET_LANGUAGES',
                    payload: {},
                });
            });
    }
}

export const getpatientDetailsMobile = (urls, number, token, ibmId) => {
    return (dispatch) => {
        if (!ibmId && !token) {
            axios.get(`${urls.fhir_tenant_orgId}Patient?phone=${number}&_count=100`, {
                headers: {
                    'X-FHIR-TENANT-ID': 'parimitha',
                    'X-FHIR-DSID': `fhirparimitha`,
                    'realm': 'parimitha'
                }
            })
                .then(res => {
                    console.log(res.data, "resssssssss")
                    dispatch({
                        type: 'GET_PATIENT_DETAILS',
                        payload: res.data
                    });
                })
                .catch(err => {
                    dispatch({
                        type: 'GET_PATIENT_DETAILS',
                        payload: [],
                    });
                });
        } else {
            axios.get(`${urls.person_tenant_orgId}Patient?phone=${number}`, {
                headers: {
                    'X-FHIR-TENANT-ID': 'parimitha',
                    'X-FHIR-DSID': `fhirparimitha`,
                    'realm': 'parimitha',
                    'Authorization': `Bearer ${token}`
                }
            }).then(res => {
                console.log(res.data, "resssssssss")
                dispatch({
                    type: 'GET_PATIENT_DETAILS',
                    payload: res.data
                });
            })
                .catch(err => {
                    dispatch({
                        type: 'GET_PATIENT_DETAILS',
                        payload: [],
                    });
                });
        }

    }
}
export const getPatientInfo = (urls, id, token, ibmId) => {
    return (dispatch) => {
        if (!ibmId && !token) {
            axios.get(`${urls.fhir_tenant_orgId}Patient/${id}`, {
                headers: {
                    'X-FHIR-TENANT-ID': 'parimitha',
                    'X-FHIR-DSID': `fhirparimitha`,
                    'realm': 'parimitha'
                }
            })
                .then(res => {
                    console.log(res.data, "resssssssss")
                    dispatch({
                        type: 'GET_PATIENT_INFO',
                        payload: res.data
                    });
                })
                .catch(err => {
                    dispatch({
                        type: 'GET_PATIENT_INFO',
                        payload: '',
                    });
                });
        } else {
            axios.get(`${urls.person_tenant_orgId}Patient/${id}`, {
                headers: {
                    'X-FHIR-TENANT-ID': 'parimitha',
                    'X-FHIR-DSID': `fhirparimitha`,
                    'realm': 'parimitha',
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(res => {
                    console.log(res.data, "resssssssss")
                    dispatch({
                        type: 'GET_PATIENT_INFO',
                        payload: res.data
                    });
                })
                .catch(err => {
                    dispatch({
                        type: 'GET_PATIENT_INFO',
                        payload: '',
                    });
                });
        }

    }
}
export const getRelationship = () => {
    return (dispatch) => {
        axios.get(`${URL.relationship}ValueSet/$expand?url=http://hl7.org/fhir/ValueSet/relatedperson-relationshiptype`)
            .then(res => {
                console.log(res.data, "resssssssss")
                dispatch({
                    type: 'GET_RELATION_INFO',
                    payload: res.data?.expansion?.contains
                });
            })
            .catch(err => {
                dispatch({
                    type: 'GET_RELATION_INFO',
                    payload: '',
                });
            });
    }
}

export const getAppointmentSlots = async (urls, roleId, practitionerId, selectdate, params) => {
    const { data } = await axios.get(`${urls.appointment}schedule/Slots/${roleId}/${practitionerId}/${selectdate}`, {
        headers: params
    })
    return data
}
export const getDoctorSpectilities = async (urls) => {
    // const { data } = await axios.get(`${urls.fhir_auth}HealthcareService?organization=${orgId}&_count=200`, {
    const { data } = await axios.get(`${urls.fhir_tenant_orgId}HealthcareService?_count=200`, {
        headers: {
            'X-FHIR-TENANT-ID': 'parimitha',
            'X-FHIR-DSID': `fhirparimitha`,
            'realm': 'parimitha'
        }
    })
    return data
}
export const getSwitchProfiles = (urls, ibmId, usertoken) => {
    return (dispatch) => {
        axios.get(`${urls.person_tenant_orgId}switch/profile/${ibmId}`, {
            headers: {
                'X-FHIR-TENANT-ID': 'parimitha',
                'X-FHIR-DSID': 'fhirparimitha',
                'realm': 'parimitha',
                'Authorization': `Bearer ${usertoken}`
            }
        }).then(res => {
            console.log(res.data, "resssssssss")
            dispatch({
                type: 'SWITCH_PROFILES',
                payload: res.data.entry
            });
        })
            .catch(err => {
                dispatch({
                    type: 'GET_PATIENT_INFO',
                    payload: [],
                });
            });
    }

}


export const setOpenBranch = (isOpen) => ({
    type: 'OPEN_BRANCH',
    payload: isOpen,
});

export const getMediaGallery = async (pageId) => {
    if (pageId) {
        const { data } = await axios.get(`${URL.feed}feed/getGallery?location=${pageId}`)
        return data
    }
}

export const getAllProfiles = (urls, patientIbmId, usertoken, tenant) => {
    return (dispatch) => {
        axios.get(`${urls.person_tenant_orgId}${patientIbmId}`, {
            headers: {
                'X-FHIR-TENANT-ID': 'parimitha',
                'X-FHIR-DSID': 'fhirparimitha',
                'realm': 'parimitha',
                'Authorization': `Bearer ${usertoken}`
            }
        }).then(res => {
            // console.log(res.data, "resssssssss")
            dispatch({
                type: 'ALL_PROFILES',
                payload: res.data.entry
            });
        })
            .catch(err => {
                dispatch({
                    type: 'ALL_PROFILES',
                    payload: [],
                });
            });
    }

}
