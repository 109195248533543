import Keycloak from "keycloak-js";

// const keycloakRealm = localStorage.getItem('brName');

const keycloak = new Keycloak({

  realm: 'parimitha',
  clientId: "fhirFE",

  //* development*
  // url: "https://keycloak.docsteth.com",

  //*Production*
  url: "https://secure.docsteth.com",


});

export default keycloak;