import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Autocomplete, Paper, TextField, IconButton, Checkbox, Button, CardMedia } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { EventAvailable, EventAvailableOutlined, GTranslateOutlined, LocationOnOutlined } from "@mui/icons-material";
import MaleDoctorImg from '../../assets/images/MaleDoctor-no-photo.svg';
import FemaleDoctorImg from '../../assets/images/FemaleDoctor-No Photo.svg';
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import StethIcon from '../../assets/images/stethoscope.svg';
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import teleIcon from '../../assets/images/Telemedicine.svg';
import URL from "../../services/api_url";
import { useSelector } from 'react-redux';
import BranchUrl from '../../services/api_branch_url';
import AvailableDays from '../doctors/availableDays';
import AppoinmentPopup from '../doctors/appoinmentPopup';
import moment from 'moment';

const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
const daysCompare = { mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6, sun: 7 };

export const PractitionerCard = ({ val, practionerInfo }) => {
    const [practitionerData, setPractitionerData] = useState({})
    const [userData, setUserData] = useState({})
    // console.log(userData, "userData")
    const selectBranchInfo = useSelector((state) => state.SelectedBranch.selectedbranch);
    const [open, setOpen] = useState(false)
    const urls = BranchUrl()
    const navigate = useNavigate()
    useEffect(() => {
        const practId = val
        if (practId) {
            axios.get(`${urls.fhir_tenant_orgId}${val?.resource?.practitioner?.reference}`, {
                headers: {
                    'X-FHIR-TENANT-ID': 'parimitha',
                    'Realm': 'parimitha',
                    'X-FHIR-DSID': `fhirparimitha`
                }
            }).then((res) => {
                // console.log(res, "hllo12345678")
                // setUserData(res?.data)
                // setPractitionerRoleInfo(res?.data)
                setPractitionerData(res?.data)

            }).catch((err) => console.log(err))
        }

        // if (practionerInfo) {
        //     console.log(practionerInfo, 41)
        //     setPractitionerRoleInfo(practionerInfo?.practitionerRole?.resource)
        //     setPractitionerData(practionerInfo?.practitioner?.resource)
        // }
    }, [])
    // console.log(practitionerRoleInfo, 35, practitionerData)

    const [WorkingDays, setWorkingDays] = useState([]);
    const [timings, setTimings] = useState({});
    const [activeDay, setActiveDay] = useState(null);
    // console.log(timingInfo, "timing")
    // console.log(activeDay, "activeday")
    const timingInfo = val?.resource?.availableTime
    useEffect(() => {
        if (timingInfo) {
            let data = [];
            const date = new Date();
            const today = days[date.getDay() - 1];
            // console.log(today, "today")
            {
                timingInfo?.map((item, i) => {
                    let openingTime = item.availableStartTime;
                    let closingTime = item.availableEndTime;
                    if (item.allDay == true) {
                        item?.daysOfWeek?.map((child) => {
                            data.push({
                                day: child,
                                allDay: item.allDay,
                                today: child == today ? true : false,
                            });
                        });
                    } else if (item.allDay == false) {
                        item.daysOfWeek?.map((child) => {
                            data.push({
                                day: child,
                                allDay: item.allDay,
                                today: child == today ? true : false,
                            });
                        });
                    } else {
                        item.daysOfWeek.map((child) => {
                            data.push({
                                day: child,
                                workingHours: [
                                    { openingTime: openingTime, closingTime: closingTime },
                                ],
                                today: child == today ? true : false,
                            });
                        });
                    }
                });
            }


            const mergedSchedule = [];

            for (let i = 0; i < data.length; i++) {
                let day = data[i].day;
                let hours = data[i].workingHours;

                let existingDay = mergedSchedule.find((item) => item.day === day);
                // console.log(existingDay, "existingDay")
                if (existingDay) {
                    existingDay.workingHours = existingDay.workingHours.concat(hours);
                    // console.log(existingDay, "existingDay11")
                } else {
                    mergedSchedule.push(data[i]);
                }
            }
            // console.log(mergedSchedule, "mergedSchedule,258");

            const sorted = mergedSchedule.sort((a, b) => {
                return daysCompare[a.day] - daysCompare[b.day];
            });
            // console.log(sorted, "sorted");
            setWorkingDays(sorted);
        }
    }, [timingInfo])
    useEffect(() => {
        const date = new Date();
        const today = days[date.getDay() - 1];
        WorkingDays?.find((item) => {
            if (item.day == today) {
                // console.log(item)
                setTimings(item);
            }
        });
    }, [WorkingDays]);
    const customStyles = {
        fontSize: '10px'
    };
    const customTime = {
        fontSize: '9px'
    };

    return (
        // <Box>
        //     <Paper
        //         elevation={3}
        //         sx={{
        //             display: "flex",
        //             alignItems: "center",

        //             cursor: "pointer",

        //             mt: 1,
        //             p: "15px 10px",
        //         }}
        //     >
        //         <Grid container>
        //             <Grid item xs={12} sm={3} md={2.6}>
        //                 <CardMedia
        //                     sx={{ height: 80, width: 80, border: '3px solid #fff', padding: "3px 3px 0px 3px", borderRadius: '12px', background: '#C6C6C6' }}
        //                     className="shadow-md"
        //                     component="img"
        //                     alt="gdoctor img"
        //                     width={100}
        //                     height="100"
        //                     image={practitionerData?.gender === 'female' ? FemaleDoctorImg : MaleDoctorImg} />


        //                 <Box sx={{ display: "flex", alignItems: "center", gap: '2px' }} onClick={() => navigate(selectBranchInfo?.name ? `/${selectBranchInfo?.name}/doctor/${practitionerData?.id}` : `/doctor/${practitionerData?.id}`)}>
        //                     <Typography sx={{ color: "#525C79", fontSize: { xs: "12px", sm: "10px", md: "10px" }, textAlign: "start", }}>
        //                         View Profile
        //                     </Typography>
        //                     <span> <ArrowOutwardIcon style={{ fontSize: "15px" }} /> </span>
        //                 </Box>
        //             </Grid>
        //             <Grid item sm={1} md={0.6}></Grid>
        //             <Grid item xs={12} sm={8} md={8}>
        //                 <Box>
        //                     <Typography sx={{ color: "#000000", fontSize: "18px", fontWeight: "500", cursor: 'pointer' }} onClick={() => navigate(selectBranchInfo?.name ? `/${selectBranchInfo?.name}/doctor/${practitionerData?.id}` : `/doctor/${practitionerData?.id}`)}>
        //                         {practitionerData?.name?.[0]?.text}
        //                     </Typography>
        //                     <Typography sx={{ color: "#707070", fontSize: "10px", mt: '2px' }} >
        //                         {practitionerData?.name?.[0]?.suffix?.length > 0 ?
        //                             practitionerData?.name?.[0]?.suffix?.map((item) =>
        //                                 <>
        //                                     {item}
        //                                 </>
        //                             )
        //                             :
        //                             // 'MBBS, MD, DCH'
        //                             ''
        //                         }
        //                     </Typography>

        //                     <Typography sx={{ color: "#000000", fontSize: "11px", mt: '2px', display: 'flex', alignItems: 'center', }}>
        //                         {practitionerData?.communication ? <GTranslateOutlined sx={{ fontSize: "12px", mr: "5px", color: '#AC0C0C' }} /> : null}
        //                         {practitionerData?.communication?.map((lang) =>
        //                             <span key={lang?.coding?.[0]?.display} style={{ color: "#051984", paddingLeft: "5px", fontWeight: 500 }}>
        //                                 {lang?.coding?.[0]?.display}
        //                             </span>
        //                         )}
        //                     </Typography>

        //                     <Typography sx={{ color: "#816565", fontSize: "11px", mt: "2px", display: "flex", alignItems: "center", gap: "2px", }} >
        //                         <span>
        //                             {practitionerRoleInfo?.specialty?.[0]?.coding?.[0]?.display ?
        //                                 <img
        //                                     src={StethIcon}
        //                                     width={20}
        //                                     height={20}
        //                                     alt="stethoscope"
        //                                     sx={{ fontSize: "12px", mr: "5px" }}
        //                                 /> : null}
        //                         </span>
        //                         {practitionerRoleInfo?.specialty?.[0]?.coding?.[0]?.display
        //                             //  || 'General pediatrices ,Neonatology'
        //                         }
        //                         {/* {val.study} */}
        //                     </Typography>
        //                 </Box>
        //             </Grid>

        //             <Grid item xs={12} sm={12} md={12}>
        //                 <Typography sx={{ color: "#000000", height: 25, fontSize: '14px' }} className="text-sm" >

        //                     {practitionerRoleInfo?.identifier?.map((option) =>
        //                         <div>{option.value}</div>
        //                     )}
        //                 </Typography>

        //                 <Typography
        //                     sx={{
        //                         color: "#000000",
        //                         fontSize: "14px",
        //                         fontWeight: "500",
        //                         mt: '5px',
        //                         display: 'flex', alignItems: 'center', gap: "5px"
        //                     }}
        //                 >
        //                     <LocationOnOutlined sx={{ fontSize: "14px", color: '#0F21B1', mr: "5px" }} />
        //                     Available at
        //                     <span
        //                         style={{
        //                             color: "#0F4BBE",
        //                             fontSize: "14px"
        //                         }}
        //                     >
        //                         Paramitha Hospitals, {selectBranchInfo?.name}
        //                     </span>
        //                 </Typography>
        //                 {practitionerRoleInfo?.availableTime ?
        //                     <Typography
        //                         sx={{
        //                             color: "#000000",
        //                             fontSize: "14px",
        //                             fontWeight: "500",
        //                             mt: '2px',
        //                             display: 'flex', gap: '5px'
        //                         }}
        //                     >
        //                         <div className='flex pt-2 gap-1'>
        //                             <EventAvailable
        //                                 sx={{ fontSize: "16px", color: '#4B4C52', mr: "5px", mt: '3px' }}
        //                             />
        //                             <div className='text-[14px]'>Available</div>
        //                         </div>
        //                         <div> <AvailableDays timingInfo={practitionerRoleInfo?.availableTime} styles={customStyles} customTimeFont={customTime} />
        //                         </div>
        //                     </Typography> :
        //                     <Typography
        //                         sx={{
        //                             color: "#000000",
        //                             fontSize: "12px",
        //                             fontWeight: "500",
        //                             mt: '13px',
        //                             display: 'flex', gap: '10px'

        //                         }}
        //                     >
        //                         <EventAvailable sx={{ fontSize: "16px", color: '#4B4C52', mt: '2px' }} />
        //                         <div className='text-[14px]'>Available</div>
        //                         <Typography sx={{ height: 52, fontSize: "14px", color: "#0F4BBE" }}>No Schedule Slots Available</Typography>
        //                     </Typography>
        //                 }
        //                 <Box sx={{ display: "flex", justifyContent: "space-between", mt: "12px", }} >
        //                     <Button
        //                         variant="outlined"
        //                         startIcon={<img src={teleIcon} width={20} height={20} alt="tele" />}
        //                         sx={{
        //                             width: { xs: "130px", sm: "130px", md: "140px", lg: "150px" },
        //                             height: "30px",
        //                             fontSize: { xs: "10px", sm: "10px", md: "10px", lg: "12px" },
        //                             textTransform: "capitalize",
        //                             borderRadius: "10px",
        //                             borderColor: '#205072',
        //                             color: '#205072',
        //                             "&:hover": { borderColor: "#205072" }
        //                         }}
        //                     >
        //                         Consult Online
        //                     </Button>
        //                     <Button
        //                         variant="outlined"
        //                         startIcon={<EventAvailableIcon />}
        //                         style={{ backgroundColor: "#205072", color: "#FFFFFF", textTransform: "capitalize", borderRadius: "10px" }}
        //                         sx={{
        //                             width: { xs: "130px", sm: "130px", md: "140px", lg: "150px" },
        //                             height: "30px",
        //                             fontSize: { xs: "10px", sm: "10px", md: "10px", lg: "12px", },
        //                         }}
        //                         // onClick={() => navigate(selectBranchInfo?.name ? `/${selectBranchInfo?.name}/doctor/${val?.resource?.id}` : `/doctor/${val?.resource?.id}`, { state: { schedule: 'open' } })}                            >
        //                         onClick={() => setOpen(true)}                            >
        //                         Schedule Visit
        //                     </Button>
        //                 </Box>
        //             </Grid>
        //         </Grid>
        //     </Paper>
        //     <AppoinmentPopup open={open} setOpen={setOpen} selectedbranch={selectBranchInfo} userRole={userData} />

        // </Box >
        <Box>
            <Paper
                elevation={3}
                sx={{
                    cursor: "pointer",
                    minHeight: 380,
                    mt: 1,
                    p: "20px 10px",
                    position: 'relative'
                }}
            >
                <div className='flex gap-3'>
                    <CardMedia
                        sx={{ height: 80, width: 80, border: '3px solid #fff', padding: "3px 3px 0px 3px", borderRadius: '12px', background: '#C6C6C6' }}
                        className="shadow-md"
                        component="img"
                        alt="gdoctor img"
                        width="100px"
                        height="100px"
                        image={practitionerData?.gender === 'female' ? FemaleDoctorImg : MaleDoctorImg} />
                    <Box>
                        <Typography sx={{ color: "#000000", fontSize: { xs: 20, md: "23px" }, fontWeight: "500", cursor: 'pointer', textTransform: 'capitalize' }} onClick={() => navigate(selectBranchInfo?.name ? `/${selectBranchInfo?.name}/doctor/${val?.resource?.id}` : `/doctor/${val?.resource?.id}`)}>
                            {practitionerData?.name?.[0]?.text}
                        </Typography>
                        <Typography sx={{ color: "#707070", fontSize: "13px", height: "19px" }} >
                            {practitionerData?.name?.[0]?.suffix?.length > 0 ?
                                practitionerData?.name?.[0]?.suffix?.map((item) =>
                                    <>
                                        {item}
                                    </>
                                )
                                :
                                // 'MBBS, MD, DCH'
                                ''
                            }
                        </Typography>

                        <Typography sx={{ color: "#525C79", fontSize: "16px", display: "flex", height: '29px', alignItems: "center", gap: "2px", fontWeight: 'normal' }} >
                            <span>
                                {val?.resource?.specialty?.[0]?.coding?.[0]?.display ?
                                    <img
                                        src={StethIcon}
                                        width={20}
                                        height={20}
                                        alt="stethoscope"
                                        sx={{ fontSize: "12px", mr: "5px" }}
                                    /> : <> No Speciality</>}
                            </span>
                            {val?.resource?.specialty?.[0]?.coding?.[0]?.display
                                //  || 'General pediatrices ,Neonatology'
                            }
                            {/* {val.study} */}
                        </Typography>
                    </Box>
                </div>
                <div>
                    <Typography sx={{ mt: "5px" }}>

                        {val?.resource?.identifier?.length > 0 ? val?.resource?.identifier?.map((option) =>
                            <div style={{ color: "#000000", minHeight: 25, }} className='md:text-lg'>{option.value}</div>
                        ) : <div style={{ color: "#000000", minHeight: 25, fontSize: '18px' }}></div>}

                    </Typography>

                    <Typography
                        sx={{
                            color: "#000000",
                            fontSize: "16px",
                            fontWeight: "500",
                            mt: '5px',
                            display: 'flex', alignItems: 'center', gap: "5px"
                        }}
                    >
                        <LocationOnOutlined sx={{ fontSize: "16px", color: '#E54290', mr: "3px" }} />
                        <span
                            style={{
                                color: "#0F4BBE",
                                fontSize: "16px"
                            }}
                        >
                            {selectBranchInfo?.name || 'Paramitha Hospitals'}
                        </span>
                    </Typography>
                    <Typography sx={{ color: "#000000", fontSize: "16px", mt: '4px', display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <GTranslateOutlined sx={{ fontSize: "16px", mr: "5px", color: '#E54290' }} />
                        {practitionerData?.communication?.length > 0 ? practitionerData?.communication?.map((lang) =>
                            <span key={lang?.coding?.[0]?.display} style={{ color: "#040321", fontWeight: 500 }}>
                                {lang?.coding?.[0]?.display ? `${lang?.coding?.[0]?.display}, ` : ''}
                            </span>
                        )
                            :
                            <span style={{ color: "#040321", fontWeight: 500 }}>
                                English
                            </span>
                        }
                    </Typography>
                    <div style={{ display: "flex", gap: "5px", margin: "12px 0px", flexWrap: 'wrap' }}>
                        {WorkingDays?.map((item, i) => {
                            return (
                                <Button
                                    key={i}
                                    variant="outlined"
                                    onClick={() => {
                                        setTimings(item);
                                        setActiveDay(i);
                                    }}
                                    sx={{
                                        minWidth: "50px",
                                        minHeight: '30px',
                                        border: '1px solid #E54290',
                                        background:
                                            activeDay == i && activeDay !== null
                                                ? "#480223"
                                                : item.today && activeDay == null
                                                    ? "#480223"
                                                    : !item.allDay && !item.hasOwnProperty("workingHours")
                                                        ? "#fff"
                                                        : "#fff",
                                        padding: "0px 7px",
                                    }}
                                >
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            color: activeDay == i && activeDay !== null
                                                ? "#fff"
                                                : item.today && activeDay == null
                                                    ? "#fff"
                                                    : !item.allDay && !item.hasOwnProperty("workingHours")
                                                        ? "#E54290"
                                                        : "#E54290",
                                            textTransform: "capitalize",
                                        }}
                                        style={customStyles}
                                    >
                                        {item.day}
                                    </Typography>
                                </Button>
                            );
                        })}
                    </div>
                    {val?.resource?.availableTime ?
                        <Box
                            sx={{
                                color: "#000000",
                                fontSize: "16px",
                                fontWeight: "500",
                                mt: '-5px',
                                display: 'flex', gap: '15px'
                            }}
                        >
                            <div className='flex pt-3 gap-1'>
                                <EventAvailable
                                    sx={{ fontSize: "18px", color: '#E54290', mr: "5px", mt: '3px' }}
                                />
                                <div className='text-[16px]'>Available -</div>
                            </div>
                            <div className='pt-2'>
                                {timings && timings.allDay ? (
                                    <Typography variant="body2" className="timing" style={customStyles}>
                                        All Day
                                    </Typography>
                                ) : timings.allDay == false ? (
                                    <Typography variant="body2" className="timing" style={customStyles} >
                                        No Slot available
                                    </Typography>
                                ) :
                                    (
                                        timings?.workingHours ?
                                            timings?.workingHours?.map((item, ind) => {
                                                // console.log(item, "");
                                                return (
                                                    <Typography
                                                        key={ind}
                                                        variant="body2"
                                                        className="timing"
                                                        style={customStyles}

                                                    >
                                                        {moment(item?.openingTime, "hh:mm").format("HH:mm A")}
                                                        &nbsp;&nbsp;-&nbsp;&nbsp;
                                                        {moment(item?.closingTime, "hh:mm").format("HH:mm A")}
                                                    </Typography>
                                                );
                                            }) : <Typography variant="body2" className="timing" style={customStyles}>
                                                Today No Slots available
                                            </Typography>
                                    )
                                }
                            </div>
                        </Box> :
                        <Typography
                            sx={{
                                color: "#000000",
                                fontSize: "12px",
                                fontWeight: "500",
                                mt: '10px',
                                display: 'flex', gap: '10px'
                            }}
                        >
                            <EventAvailable sx={{ fontSize: "18px", color: '#E54290', mt: '2px' }} />
                            <div className='text-[16px]'>Available</div>
                            <Typography sx={{ height: 52, fontSize: "16px", color: "#0F4BBE" }}>No Schedule Slots Available</Typography>
                        </Typography>
                    }

                    <Box sx={{ display: "flex", justifyContent: "space-around", mt: "12px", p: "0px 0px 10px 0px", position: { md: 'absolute' }, bottom: 0, left: 0, right: 0 }} >
                        {/* <Button
                                variant="outlined"
                                startIcon={<img src={teleIcon} width={20} height={20} alt="tele" />}
                                sx={{
                                    width: { xs: "130px", sm: "130px", md: "140px", lg: "150px" },
                                    height: "30px",
                                    fontSize: { xs: "10px", sm: "10px", md: "10px", lg: "12px" },
                                    textTransform: "capitalize",
                                    borderRadius: "10px",
                                    borderColor: '#205072',
                                    color: '#205072',
                                    "&:hover": { borderColor: "#205072" }
                                }}
                            >
                                Consult Online
                            </Button> */}
                        <Box sx={{ display: "flex", alignItems: "center", gap: '2px' }} onClick={() => navigate(selectBranchInfo?.name ? `/${selectBranchInfo?.name}/doctor/${val?.resource?.id}` : `/doctor/${val?.resource?.id}`)}>
                            <Typography sx={{ color: "#E54290", fontSize: { xs: "12px", sm: "14px", md: "16px", lg: "18px", }, }}>
                                Visit Profile
                            </Typography>

                        </Box>
                        <Button
                            variant="outlined"
                            startIcon={<EventAvailableIcon />}
                            style={{ backgroundColor: "#E54290", color: "#FFFFFF", textTransform: "capitalize", borderRadius: "10px" }}
                            sx={{
                                // width: { xs: "130px", sm: "130px", md: "140px", lg: "150px" },
                                height: "45px",
                                fontSize: { xs: "12px", sm: "14px", md: "16px", lg: "18px", },
                            }}
                            // onClick={() => navigate(selectBranchInfo?.name ? `/${selectBranchInfo?.name}/doctor/${val?.resource?.id}` : `/doctor/${val?.resource?.id}`, { state: { schedule: 'open' } })}                            >
                            onClick={() => setOpen(true)}                            >
                            Book Appointment
                        </Button>
                    </Box>
                </div>
            </Paper>
            <AppoinmentPopup open={open} setOpen={setOpen} selectedbranch={selectBranchInfo} userRole={val} userData={practitionerData} />

        </Box >
    )
}
