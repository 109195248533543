import React from 'react'
import { Box, Checkbox, Grid, Paper, Typography, useMediaQuery } from '@mui/material'
import { Construction } from '@mui/icons-material';
import PrescriptionIcon from '../../assets/records-module/prescription.svg';
import DiagnosticIcon from '../../assets/records-module/diagnostic.svg';
import ConsultIcon from '../../assets/records-module/consultation.svg';
import DischargeIcon from '../../assets/records-module/discharge.svg';
import MedicalRecordIcon from '../../assets/healthportal-icons/journal-medical.svg'


const HiTypes = (props) => (

    <Paper elevation={3} sx={{
        width: { xs: '100%', sm: "100%", md: "100%" }, mt: 3, cursor: 'pointer',
    }} >

        <Box sx={{ p: '7px 20px' }}>
            <Grid container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography sx={{ fontSize: { xs: '10px', sm: '14px', md: '16px' } }} color="text.secondary" gutterBottom>
                    {props.title}
                </Typography>
                <Typography sx={{ fontSize: { xs: '10px', sm: '13px', md: '13px' } }} >
                    {/* {DateFormat(item?.resource?.date)} | {Time(item?.resource?.date)} */}
                    {props.date}
                </Typography>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <img src={props.icon} alt="icons" width={25} height={25} />
                    <Box>
                        <Typography sx={{ fontSize: { xs: '13px', sm: '14px', md: '14px' }, fontWeight: 500 }}>
                            {props.identifier}
                        </Typography>
                        <Typography sx={{ mt: '5px', fontSize: { xs: '13px', sm: '14px', md: '14px' } }}>{props.display}</Typography>
                    </Box>
                </Box>
                <Typography sx={{ fontSize: { xs: '10px', md: '14px', sm: '14px' }, mt: 3 }} >
                    {props.hitypetext}
                </Typography>
            </Box>

        </Box>
    </Paper >
)

function MedicalRecords() {
    const isXsScreen = useMediaQuery("(max-width:900px)");
    const medicalrecords = ['Prescription', 'Lab Reports', 'Clinical Notes']
    return (
        <Box>
            {!isXsScreen &&
                <Typography color={'#155775'} className='font-medium text-sm fntwt-600 flex align-item' gap={"10px"}><img src={MedicalRecordIcon} alt="medical" /> Medical Records</Typography>
            }
            <Box sx={{ display: 'flex', gap: 1, mt: '10px' }}>
                {medicalrecords.map((record, index) => (
                    <Box key={index} display="flex" alignItems="center">
                        <Checkbox color="primary" />
                        <Typography>{record}</Typography>
                    </Box>
                ))}
            </Box>
            <Box sx={{ p: "0px 10px" }}>
                <HiTypes title="Out-Patient Consultation" icon={PrescriptionIcon} date="12-Mar-2022 | 3:00 PM" identifier="Paramitha Super Speciality Childre.." display="Dr Venkat Reddy K - Neonatologist" hitypetext="Prescription" />
                <HiTypes title="In-patient Admission" icon={DischargeIcon} date="12-Mar-2022 | 3:00 PM" identifier="Paramitha Super Speciality Childre.." display="Dr Venkat Reddy K - Neonatologist" hitypetext="Discharge Note" />
                <HiTypes title="Diagnostic Report Lab" icon={DiagnosticIcon} date="12-Mar-2022 | 3:00 PM" identifier="Paramitha Super Speciality Childre.." display="Dr Venkat Reddy K - Neonatologist" hitypetext="Lab Report" />
                <HiTypes title="Out-Patient Consultation" icon={ConsultIcon} date="12-Mar-2022 | 3:00 PM" identifier="Paramitha Super Speciality Childre.." display="Dr Venkat Reddy K - Neonatologist" hitypetext="OP Consult" />
                <HiTypes title="Diagnostic Report Imaging" icon={PrescriptionIcon} date="12-Mar-2022 | 3:00 PM" identifier="Paramitha Super Speciality Childre.." display="Dr Venkat Reddy K - Neonatologist" hitypetext="Imaging Report" />
            </Box>
        </Box>
    )
}

export default MedicalRecords
