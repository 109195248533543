"use client";
import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button, Card, Grid, Rating } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ShareIcon from "@mui/icons-material/Share";
import axios from "axios";
import URL from "../../services/api_url";
// import URL from "../../../services/api_url";
const settings = {
  className: "center",
  dots: true,
  // fade: true,
  arrows: true,
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 2,
  autoplay: true,
  speed: 6000,
  autoplaySpeed: 4000,
  pauseOnHover: true,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const CustomSlick = ({ value }) => {
  console.log('testing', value);
  const [reviewData, setReviewData] = useState([]);
  const [showFullText, setShowFullText] = useState({});
  const textRefs = useRef([]);
  const capitalizedValue = capitalizeFirstLetter(value);

  useEffect(() => {
    console.log(value, 84)
    // let branchName = "Kompally";
    if (value) {
      axios
        .get(`${URL.getReviews}/paramitha/${capitalizedValue}`)
        .then((res) => {
          const reviewInfo = res?.data?.filter((item) => item?.rating === 5)
          setReviewData(reviewInfo);
          // Initialize showFullText state for each item
          const initialState = {};
          res?.data.forEach((item) => {
            initialState[item.id] = false;
          });
          setShowFullText(initialState);
          console.log(res);
        })
        .catch((err) => console.log(err));
    }
  }, [value]);

  useEffect(() => {
    // Check if text content exceeds three lines for each item
    reviewData.forEach((item, index) => {
      if (textRefs.current[index]) {
        if (
          textRefs.current[index].scrollHeight >
          textRefs.current[index].clientHeight
        ) {
          setShowFullText((prev) => ({ ...prev, [item.id]: false }));
        } else {
          setShowFullText((prev) => ({ ...prev, [item.id]: true }));
        }
      }
    });
  }, [reviewData]);

  return (
    <div>
      <div className="slider-container">
        <Slider {...settings}>
          {reviewData?.map((item, index) => (
            <div key={index}>
              <Card
                style={{
                  padding: "20px",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                <Grid
                  container
                  gap={1}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Grid item className="flex" alignItems={"center"} gap={1}>
                    {item?.profile_photo_url ? (
                      <Grid>
                        <img
                          src={item?.profile_photo_url}
                          width={30}
                          height={30}
                          style={{ objectFit: "scale-down" }}
                        />
                      </Grid>
                    ) : (
                      <Grid className="rounded-full w-8 h-8 text-white font-bold flex items-center justify-center bg-orange-400 capitalize">
                        {item?.author_name?.slice(0, 1)}
                      </Grid>
                    )}
                    <Grid>
                      <Grid className="text-sm font-bold capitalize" >
                        <a href={item?.author_url} target="_blank" >
                          {item?.author_name}
                        </a>
                      </Grid>
                      {/* <Grid className="text-xs">2 reviews</Grid> */}
                    </Grid>
                  </Grid>
                  <Grid item className="text-right">
                    <MoreVertIcon />
                  </Grid>
                </Grid>
                <Grid container pt={1}>
                  <Grid className="text-xs flex items-center">
                    {" "}
                    <Rating
                      name="read-only"
                      size="small"
                      value={item?.rating}
                      readOnly
                    />{" "}
                    &nbsp; {item?.relative_time_description}
                  </Grid>
                </Grid>
                <Grid paddingTop={1}>
                  {/* <Grid className="text-sm">{item?.text}</Grid> */}
                  {/* Review Text */}
                  <div
                    className={
                      showFullText[item.id] ? "text-sm" : "text-sm line-clamp-3"
                    }
                    ref={(el) => (textRefs.current[index] = el)}
                  >
                    {item?.text}
                  </div>
                  {!showFullText[item.id] && (
                    <Button
                      variant="text"
                      onClick={() =>
                        setShowFullText((prev) => ({
                          ...prev,
                          [item.id]: true,
                        }))
                      }
                    >
                      More
                    </Button>
                  )}
                  {showFullText[item.id] && (
                    <Button
                      onClick={() =>
                        setShowFullText((prev) => ({
                          ...prev,
                          [item.id]: false,
                        }))
                      }
                    >
                      Show Less
                    </Button>
                  )}
                </Grid>
                <Grid paddingTop={2} container gap={4} alignItems={"center"}>
                  <Grid>
                    <ThumbUpOffAltIcon fontSize="16px" />
                  </Grid>
                  <ShareIcon fontSize="16px" />
                </Grid>
              </Card>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default CustomSlick;
